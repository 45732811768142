import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext, useRef } from "react";
import { AppExperimentContext, emitRecommenderRequest } from "@catchoftheday/analytics";
import { usePersonalisedProductRecommendationsQuery } from "@personalisation/generated/graphql";
import { RecommenderCarousel } from "../RecommenderCarousel";
import { getProductTiles, getRecommenderTrackingData, handleRecommenderQueryUpdate, useVisibleRecommenderTiles } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PersonalisedProductRecommender = function PersonalisedProductRecommender(_ref) {
  var adult = _ref.adult,
      fromProduct = _ref.fromProduct,
      sourceId = _ref.sourceId,
      sourceType = _ref.sourceType,
      newProductCard = _ref.newProductCard;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["personalised_recommender_title_july_2022"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      experiment = _getExperiments$exper[0],
      experimentLoading = _getExperiments.loading;

  var visibleTiles = useVisibleRecommenderTiles();
  var trackingVariables = useRef({
    offset: 0,
    limit: visibleTiles
  });

  var _usePersonalisedProdu = usePersonalisedProductRecommendationsQuery({
    variables: {
      limit: visibleTiles,
      offset: 0,
      adult: adult
    },
    skip: !visibleTiles,
    ssr: false,
    notifyOnNetworkStatusChange: true,
    onCompleted: function onCompleted(_ref2) {
      var _ref2$personalisedRec = _ref2.personalisedRecommendedProducts.metaData,
          preHydrationCount = _ref2$personalisedRec.preHydrationCount,
          postHydrationCount = _ref2$personalisedRec.postHydrationCount,
          hydrationErrorCount = _ref2$personalisedRec.hydrationErrorCount;
      emitRecommenderRequest(_objectSpread({
        source_type: sourceType,
        pre_hydration_product_count: preHydrationCount,
        post_hydration_product_count: postHydrationCount,
        hydration_error_count: hydrationErrorCount,
        page_offset: trackingVariables.current.offset,
        page_limit: trackingVariables.current.limit
      }, fromProduct));
    }
  }),
      data = _usePersonalisedProdu.data,
      error = _usePersonalisedProdu.error,
      loading = _usePersonalisedProdu.loading,
      fetchMore = _usePersonalisedProdu.fetchMore;

  var heading = (experiment === null || experiment === void 0 ? void 0 : experiment.bucket) === 1 ? "Suggested For You" : (experiment === null || experiment === void 0 ? void 0 : experiment.bucket) === 2 ? "Just For You" : "Recommended For You";

  if (!(data !== null && data !== void 0 && data.personalisedRecommendedProducts.products.length) || error || experimentLoading) {
    return null;
  }

  var products = data.personalisedRecommendedProducts.products;
  var _data$personalisedRec = data.personalisedRecommendedProducts.pagination,
      totalCount = _data$personalisedRec.totalCount,
      nextItemIndex = _data$personalisedRec.nextItemIndex;
  var tiles = getProductTiles({
    products: products,
    sourceType: sourceType,
    sourceId: sourceId,
    fromProduct: fromProduct,
    newProductCard: newProductCard
  });
  var trackingData = getRecommenderTrackingData(products, "product");
  return ___EmotionJSX(RecommenderCarousel, {
    tiles: tiles,
    heading: heading,
    sourceType: sourceType,
    sourceId: sourceId,
    trackingData: trackingData,
    fromProduct: fromProduct,
    paginate: true,
    maxTiles: totalCount,
    carouselProps: {
      onSlideChange: function onSlideChange(_ref3) {
        var currentIndex = _ref3.currentIndex,
            slidesPerView = _ref3.slidesPerView,
            direction = _ref3.direction;
        var lastVisibleTile = currentIndex + slidesPerView - 1;

        if (direction === "forward" && !products[lastVisibleTile]) {
          trackingVariables.current = {
            offset: nextItemIndex,
            limit: slidesPerView
          };
          fetchMore({
            variables: {
              offset: nextItemIndex
            },
            updateQuery: function updateQuery(prevResult, _ref4) {
              var fetchMoreResult = _ref4.fetchMoreResult;
              return handleRecommenderQueryUpdate({
                prevResult: prevResult,
                fetchMoreResult: fetchMoreResult,
                recommenderKey: "personalisedRecommendedProducts"
              });
            }
          });
        }
      },
      disableNavButtons: {
        next: loading
      },
      touchEnabled: !loading
    }
  });
};