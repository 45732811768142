import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["isActive", "url", "onClick", "children"];

var _templateObject, _templateObject2;

import React from "react";
import { Button, Link } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var NavLinkWrapper = styled(Link)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex: 1;\n  height: 100%;\n  flex-direction: column;\n  position: relative;\n  justify-content: center;\n  align-items: center;\n  color: ", ";\n  &:hover,\n  &:focus,\n  &:active {\n    outline: none;\n    text-decoration: none;\n    & > p, & > svg {\n      color: ", "}\n    }\n  }\n  & > p,\n  & > svg {\n    color: ", ";\n  }\n"])), function (props) {
  return themeGet(props.isActive ? "colors.secondary" : "colors.typography.inactive")(props);
}, function (props) {
  return themeGet("colors.typography.inactive")(props);
}, function (props) {
  return themeGet(props.isActive ? "colors.secondary" : "colors.typography.inactive")(props);
});
var NavButtonWrapper = styled(Button)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex: 1;\n  height: 100%;\n  padding: 0;\n  font-size: 12px;\n  background: transparent;\n  min-width: 0 !important;\n  border: none;\n  color: ", ";\n  text-transform: none;\n  &:hover:not(:disabled):not([disabled]) {\n    background-color: transparent;\n  }\n  &:active {\n    box-shadow: none;\n  }\n  p,\n  svg {\n    color: ", ";\n  }\n"])), function (props) {
  return themeGet(props.isActive ? "colors.secondary" : "colors.typography.inactive")(props);
}, function (props) {
  return themeGet(props.isActive ? "colors.secondary" : "colors.typography.inactive")(props);
});
export var MobileNavItem = function MobileNavItem(_ref) {
  var isActive = _ref.isActive,
      url = _ref.url,
      onClick = _ref.onClick,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  var NavItemWrapper = url ? NavLinkWrapper : NavButtonWrapper;
  return ___EmotionJSX(NavItemWrapper, _extends({
    isActive: isActive,
    href: url,
    onClick: onClick,
    "aria-current": isActive && "page"
  }, props), children);
};