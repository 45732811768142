import _toConsumableArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { ONEPASS_SHOP_FULL_WIDTH_TILES, ONEPASS_SHOP_TILES_PER_ROW } from "../consts";
export var constructScheduleEntryInserts = function constructScheduleEntryInserts(ordinals) {
  var inserts = Object.entries(ordinals).reduce(function (res, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return [].concat(_toConsumableArray(res), [{
      key: key,
      ordinal: value,
      colspan: ONEPASS_SHOP_FULL_WIDTH_TILES.indexOf(key) >= 0 ? ONEPASS_SHOP_TILES_PER_ROW : 1
    }]);
  }, []);
  return inserts;
};