import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["items", "onItemRemove"];
import React from "react";
import { Box, Button, Flex, IconTrash, Image, Link, List, ListItem, Span, Truncate } from "@catchoftheday/cg-components";
import { formatPrice } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var WishListItems = function WishListItems(_ref) {
  var _ref$items = _ref.items,
      items = _ref$items === void 0 ? [] : _ref$items,
      onItemRemove = _ref.onItemRemove,
      otherProps = _objectWithoutProperties(_ref, _excluded);

  return items && items.length > 0 ? ___EmotionJSX(List, _extends({
    m: 0,
    itemSeparator: "1px solid",
    type: "none"
  }, otherProps), (items === null || items === void 0 ? void 0 : items.length) && items.map(function (item, idx) {
    return ___EmotionJSX(ListItem, {
      key: "".concat(item.productTitle, "-").concat(idx),
      py: "13px",
      mb: "0",
      borderBottom: idx != items.length - 1 ? "1px solid" : undefined,
      borderBottomColor: "ui.borderColor"
    }, ___EmotionJSX(Flex, {
      justifyContent: "space-between"
    }, ___EmotionJSX(Flex, null, ___EmotionJSX(Box, {
      border: 1,
      borderColor: "ui.borderColor",
      mr: "8px",
      p: 1
    }, ___EmotionJSX(Link, {
      href: item.link,
      tabIndex: -1,
      "data-tracking": "header-app-wishlist-item-image",
      className: "js-product-link",
      "data-product-id": item.productId
    }, ___EmotionJSX(Image, {
      alt: item.productTitle,
      src: item.imageSrc,
      width: "64px",
      height: "52px",
      overflow: "hidden"
    }))), ___EmotionJSX(Box, {
      fontSize: "xs",
      maxWidth: "185px"
    }, ___EmotionJSX(Truncate, null, ___EmotionJSX(Link, {
      href: item.link,
      color: "typography.text",
      fontWeight: 100,
      "data-tracking": "header-app-wishlist-item-name",
      className: "js-product-link",
      "data-product-id": item.productId
    }, item.productTitle)), ___EmotionJSX(Box, {
      color: "typography.text",
      mt: "5px"
    }, item.attributeValue && item.attributeName && ___EmotionJSX(Span, {
      fontSize: "inherit"
    }, item.attributeName, ":\xA0", item.attributeValue)))), ___EmotionJSX(Box, null, ___EmotionJSX(Box, {
      position: "relative"
    }, ___EmotionJSX(Span, {
      pr: "8px",
      fontWeight: "semibold",
      verticalAlign: "middle",
      mr: "16px"
    }, formatPrice(item.sellPrice.amount)), ___EmotionJSX(Box, {
      position: "absolute",
      top: -4,
      right: 0
    }, ___EmotionJSX(Button, {
      "aria-label": "Remove ".concat(item.productTitle),
      sizeVariant: "xs",
      variant: "plain",
      p: "1px",
      pb: "4px",
      display: "inline",
      "data-tracking": "header-app-wish-list-remove-item",
      onClick: function onClick() {
        if (typeof onItemRemove == "function") {
          onItemRemove(item);
        }
      }
    }, ___EmotionJSX(IconTrash, {
      cursor: "pointer",
      hoverColor: "brand.secondary",
      width: "14px",
      height: "15px",
      color: "typography.subText"
    })))), item.quantity <= 0 && ___EmotionJSX(Box, {
      color: "typography.subText",
      mt: "5px",
      textAlign: "right"
    }, ___EmotionJSX(Span, null, "Out of Stock")))));
  })) : null;
};
export default WishListItems;