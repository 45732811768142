import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["children"];

var _templateObject;

import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { HEADER_XMAS_TILE } from "../../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
var Tile = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return props.eventTheme && "background-image: url(".concat(props.getTile(), ")");
});
export var BackgroundTile = function BackgroundTile(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  var getTile = function getTile() {
    switch (props.eventTheme) {
      case "christmas":
        return HEADER_XMAS_TILE;

      default:
        return "";
    }
  };

  return ___EmotionJSX(Tile, _extends({}, props, {
    getTile: getTile
  }), children);
};