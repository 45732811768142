import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["onItemClick"];

var _templateObject;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext, useMemo } from "react";
import { Box, IconOnePass, List } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { ONEPASS_ITEM_SLUG } from "../../../consts";
import { MenuItem } from "../../../MenuItem";
import { MobileMenuContext } from "../../../MobileMenuContext";
import { CatchEssentialsMenu } from "./CatchEssentialsMenu";
import { jsx as ___EmotionJSX } from "@emotion/react";
var OnePassItem = styled(MenuItem)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: 0px;\n  a,\n  a:hover,\n  a:focus {\n    color: white;\n\n    svg {\n      color: white;\n    }\n  }\n\n  li {\n    display: flex;\n    align-items: center;\n    height: 48px;\n    background-color: ", ";\n    padding-left: 15px;\n    margin-bottom: 0px;\n  }\n"])), function (props) {
  return themeGet("colors.onePass.primary")(props);
});
export var getCategoriesMenu = function getCategoriesMenu(menu) {
  var seasonalMenu = _toConsumableArray(menu).filter(function (menuItem) {
    return menuItem.pinToTop;
  }).sort(function (prev, next) {
    return prev.url === "/" ? -1 : next.url === "/" ? 1 : 0;
  }).sort(function (prev, next) {
    return prev.slug === ONEPASS_ITEM_SLUG ? -1 : next.slug === ONEPASS_ITEM_SLUG ? 1 : 0;
  });

  var categoryMenu = menu.filter(function (menuItem) {
    return !menuItem.pinToTop;
  });
  return [].concat(_toConsumableArray(seasonalMenu), _toConsumableArray(categoryMenu)).map(function (menuItem, index) {
    return _objectSpread(_objectSpread({}, menuItem), {}, {
      position: index + 1
    });
  });
};
export var CategoriesMenu = function CategoriesMenu(_ref) {
  var onItemClick = _ref.onItemClick,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useContext = useContext(MobileMenuContext),
      _useContext$shops = _useContext.shops,
      shops = _useContext$shops === void 0 ? [] : _useContext$shops;

  var categoriesMenu = useMemo(function () {
    return getCategoriesMenu(shops);
  }, [shops]);
  return ___EmotionJSX(Box, _extends({
    as: "nav",
    overflowY: "scroll"
  }, props), ___EmotionJSX(List, {
    my: "0px"
  }, categoriesMenu.map(function (menuItem) {
    var isOnePass = menuItem.slug === ONEPASS_ITEM_SLUG;
    return isOnePass ? ___EmotionJSX(OnePassItem, {
      "aria-label": "OnePass",
      key: "".concat(menuItem.name, "-").concat(menuItem.position),
      url: menuItem.url,
      itemPath: [{
        name: menuItem.name,
        id: menuItem.id,
        position: menuItem.position
      }]
    }, ___EmotionJSX(IconOnePass, {
      width: "72px",
      height: "15px"
    })) : ___EmotionJSX(MenuItem, {
      key: "".concat(menuItem.name, "-").concat(menuItem.position),
      url: menuItem.url,
      hasChildren: menuItem.shopNavigationGroups.length > 0,
      itemPath: [{
        name: menuItem.name,
        id: menuItem.id,
        position: menuItem.position
      }],
      onClick: function onClick() {
        return onItemClick([menuItem.id]);
      }
    }, menuItem.name);
  })), ___EmotionJSX(CatchEssentialsMenu, {
    startIndex: categoriesMenu.length
  }));
};