import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["idPath"];
import React, { useContext, useMemo } from "react";
import { Box, List } from "@catchoftheday/cg-components";
import { SHOP_BY_BRAND_ID } from "../../../consts";
import { MenuItem } from "../../../MenuItem";
import { MobileMenuContext } from "../../../MobileMenuContext";
import { getCategoriesMenu } from "../CategoriesMenu";
import { getSubCategoriesMenu } from "../SubCategoriesMenu";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var LinksMenu = function LinksMenu(_ref) {
  var idPath = _ref.idPath,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useContext = useContext(MobileMenuContext),
      _useContext$shops = _useContext.shops,
      shops = _useContext$shops === void 0 ? [] : _useContext$shops;

  var activeCategory = useMemo(function () {
    return getCategoriesMenu(shops).find(function (_ref2) {
      var id = _ref2.id;
      return id === idPath[0];
    });
  }, [shops, idPath]);
  var activeSubCategory = useMemo(function () {
    return (getSubCategoriesMenu(activeCategory) || []).find(function (_ref3) {
      var id = _ref3.id;
      return id === idPath[1];
    });
  }, [idPath, activeCategory]);
  var linksMenu = useMemo(function () {
    if (!activeSubCategory || activeSubCategory.shopNavigationItems.length === 0) {
      return undefined;
    }

    return activeSubCategory.shopNavigationItems;
  }, [activeSubCategory]);

  if (!linksMenu || !activeCategory || !activeSubCategory) {
    return null;
  }

  var isShopByBrand = activeSubCategory.id === SHOP_BY_BRAND_ID;
  return ___EmotionJSX(Box, _extends({
    as: "nav",
    overflowY: "scroll"
  }, props), ___EmotionJSX(List, {
    my: "0px"
  }, !isShopByBrand && ___EmotionJSX(MenuItem, {
    key: "Shop All ".concat(activeSubCategory.name, "-0"),
    url: activeSubCategory.url,
    hasChildren: false,
    itemPath: [{
      name: activeCategory.name,
      id: activeCategory.id,
      position: activeCategory.position
    }, {
      name: activeSubCategory.name,
      id: activeSubCategory.id,
      position: activeSubCategory.position
    }, {
      name: "Shop All ".concat(activeSubCategory.name),
      id: activeSubCategory.id,
      position: 1
    }],
    shopAll: true
  }, "Shop All ".concat(activeSubCategory.name)), linksMenu.map(function (menuItem, index) {
    return ___EmotionJSX(MenuItem, {
      key: "".concat(menuItem.name, "-").concat(index + 1),
      url: menuItem.url,
      hasChildren: false,
      itemPath: [{
        name: activeCategory.name,
        id: activeCategory.id,
        position: activeCategory.position
      }, {
        name: activeSubCategory.name,
        id: isShopByBrand ? activeCategory.id : activeSubCategory.id,
        position: activeSubCategory.position
      }, {
        name: menuItem.name,
        id: menuItem.id,
        // Start from 1 and account for the Shop All link
        // If level 2 is Shop by Brand, Shop All doesn't need to be accounted for
        position: index + (isShopByBrand ? 1 : 2)
      }]
    }, menuItem.name);
  })));
};