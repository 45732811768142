import _toConsumableArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
export var ONEPASS_SHOP_APOLLO_CACHE_CONFIG = {
  typePolicies: {
    Query: {
      fields: {
        onePassScheduleEntries: {
          keyArgs: ["input", ["categoryId"]]
        }
      }
    },
    ScheduleEntriesResponse: {
      keyFields: function keyFields(object) {
        if (object.scheduleEntries) {
          return "ScheduleEntriesResponse:EntriesStream";
        } else if (object.heroScheduleEntries) {
          return "ScheduleEntriesResponse:HeroEntries";
        }

        return undefined;
      },
      fields: {
        entries: {
          keyArgs: function keyArgs(_, context) {
            var field = context.field;
            var variables = context.variables;

            var _ref = field !== null && field !== void 0 ? field : {},
                alias = _ref.alias;

            if (alias && alias.value === "scheduleEntries") {
              var input = variables.input;

              var _ref2 = input !== null && input !== void 0 ? input : {},
                  categoryId = _ref2.categoryId;

              return "ScheduleEntries".concat(JSON.stringify({
                input: {
                  categoryId: categoryId
                }
              }));
            }

            return undefined;
          },
          merge: function merge(existing, incoming, options) {
            if (!existing && incoming) {
              return _toConsumableArray(incoming);
            }

            if (!incoming) {
              return _toConsumableArray(existing);
            }

            var variables = options.variables,
                isReference = options.isReference,
                readField = options.readField;
            var input = variables.input;

            if (!input) {
              return _toConsumableArray(incoming);
            }

            var existingNonEventCount = existing.reduce(function (count, ent) {
              if (isReference(ent) && !readField("event", ent)) {
                return count + 1;
              }

              return count;
            }, 0);

            var _ref3 = input !== null && input !== void 0 ? input : {},
                page = _ref3.page,
                limit = _ref3.limit;

            var offset = (limit !== null && limit !== void 0 ? limit : 9) * ((page !== null && page !== void 0 ? page : 1) - 1) + existingNonEventCount;

            if (existing.length > offset) {
              var incomingNonEventCount = incoming.reduce(function (count, ent) {
                if (isReference(ent) && !readField("event", ent)) {
                  return count + 1;
                }

                return count;
              }, 0);
              offset -= incomingNonEventCount;
            } // Slicing is necessary because the existing data is
            // immutable, and frozen in development.


            var merged = existing !== null && existing !== void 0 && existing.length ? _toConsumableArray(existing) : [];

            for (var i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i];
            }

            return merged;
          }
        }
      }
    },
    Product: {
      keyFields: function keyFields(object, context) {
        var fragmentMap = context.fragmentMap;

        if (fragmentMap !== null && fragmentMap !== void 0 && fragmentMap.OnePassProductTileData) {
          return "OnePassProductTileData:".concat(object.id);
        }

        return "Product:".concat(object.id);
      },
      merge: true
    },
    Offer: {
      fields: {
        sellPrice: {
          merge: function merge(existing, incoming, _ref4) {
            var mergeObjects = _ref4.mergeObjects;
            return mergeObjects(existing, incoming);
          }
        }
      }
    },
    ProductAssets: {
      merge: function merge(existing, incoming, _ref5) {
        var mergeObjects = _ref5.mergeObjects;
        return mergeObjects(existing, incoming);
      }
    }
  }
};