import React from "react";
import { emitOnePassAccountManagementAction } from "@catchoftheday/analytics";
import { triggerOnePassUpsellModal } from "../../../utilities";
import { SideImageZone, UpsellBoxContentZone, UpsellBoxZone } from "../../layouts/UpsellBoxLayout/UpsellBoxLayout";
import { UpsellBoxSideImage } from "../../pure/image-assets/UpsellBoxSideImage";
import { UpsellBoxContent } from "../../pure/UpsellBoxContent";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var UpsellBoxContainer = function UpsellBoxContainer(_ref) {
  var isFreeTrialEligible = _ref.isFreeTrialEligible;

  var handleCTAClick = function handleCTAClick() {
    triggerOnePassUpsellModal();
    emitOnePassAccountManagementAction(isFreeTrialEligible ? "freeTrial" : "join");
  };

  var handleLearnMoreClick = function handleLearnMoreClick() {
    emitOnePassAccountManagementAction("learnMore");
  };

  return ___EmotionJSX(UpsellBoxZone, null, ___EmotionJSX(UpsellBoxContentZone, null, ___EmotionJSX(UpsellBoxContent, {
    onLearnMoreClick: handleLearnMoreClick,
    onCTAClick: handleCTAClick,
    isFreeTrialEligible: isFreeTrialEligible
  })), ___EmotionJSX(SideImageZone, null, ___EmotionJSX(UpsellBoxSideImage, null)));
};