import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
import React from "react";
import { Flex } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPriceItemChildren = function ProductPriceItemChildren(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Flex, _extends({
    flex: "1 0 auto",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "nowrap",
    fontWeight: "semibold",
    lineHeight: "1",
    verticalAlign: "middle",
    alignItems: "initial"
  }, props), children);
};