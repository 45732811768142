import React, { useCallback, useContext, useEffect, useState } from "react";
import { emitUpsellModalAction, emitUpsellModalImpression } from "@catchoftheday/analytics";
import { CustomerContext } from "@catchoftheday/customer";
import { legacyEventBus, useEventBus } from "@catchoftheday/state";
import { generateOnePassUpsellUrl, isMembershipFreeTrialEligible, TRIGGER_ONEPASS_UPSELL_MODAL_EVENT, triggerOnePassLinkingModal } from "../../../utilities";
import { UpsellModal } from "../../pure/UpsellModal";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var UpsellModalContainer = function UpsellModalContainer() {
  var _useState = useState(false),
      isOpen = _useState[0],
      setIsOpen = _useState[1];

  var _useContext = useContext(CustomerContext),
      customer = _useContext.data;

  var _ref = customer || {},
      membership = _ref.membership;

  var _ref2 = membership || {},
      linked = _ref2.linked;

  var isLoggedIn = !!customer;
  var isFreeTrialEligible = isMembershipFreeTrialEligible(customer);
  var showSecondaryCTA = !isLoggedIn || !linked;

  var eventHandler = function eventHandler() {
    emitUpsellModalImpression();
    setIsOpen(true);
  };

  useEventBus(TRIGGER_ONEPASS_UPSELL_MODAL_EVENT, function () {
    eventHandler();
  });
  useEffect(function () {
    legacyEventBus === null || legacyEventBus === void 0 ? void 0 : legacyEventBus.on(TRIGGER_ONEPASS_UPSELL_MODAL_EVENT.type, eventHandler);
    return function () {
      legacyEventBus === null || legacyEventBus === void 0 ? void 0 : legacyEventBus.detach(TRIGGER_ONEPASS_UPSELL_MODAL_EVENT.type, eventHandler);
    };
  }, []);
  var onMainCTAClick = useCallback(function () {
    emitUpsellModalAction({
      label: isFreeTrialEligible ? "freeTrial" : "join"
    });
  }, [isFreeTrialEligible]);
  var onClose = useCallback(function () {
    emitUpsellModalAction({
      label: "close"
    });
    setIsOpen(false);
  }, []);
  var onLoginClick = useCallback(function () {
    emitUpsellModalAction({
      label: "login"
    });
  }, []);
  var onLinkClick = useCallback(function () {
    emitUpsellModalAction({
      label: "link"
    });

    if (true) {
      onClose();
      triggerOnePassLinkingModal({
        originalUrl: window.location.pathname
      });
    }
  }, [onClose]);
  var onLearnMoreClick = useCallback(function () {
    emitUpsellModalAction({
      label: "learnMore"
    });
  }, []);
  return ___EmotionJSX(UpsellModal, {
    isOpen: isOpen,
    isLoggedIn: isLoggedIn,
    isFreeTrialEligible: isFreeTrialEligible,
    mainCTAHref: generateOnePassUpsellUrl({
      url: true ? window.location.pathname : "/",
      cta: isFreeTrialEligible ? "start-free-trial" : "join"
    }),
    showSecondaryCTA: showSecondaryCTA,
    onClose: onClose,
    onLoginClick: onLoginClick,
    onMainCTAClick: onMainCTAClick,
    onLinkClick: onLinkClick,
    onLearnMoreClick: onLearnMoreClick
  });
};