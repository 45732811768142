import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/** A local storage interface to fallback to inmemory storage in case of unavailable localStorage */
var isLocalStorageAvailable = function isLocalStorageAvailable() {
  var isLSAvailable;

  if (typeof isLSAvailable == "undefined") {
    try {
      var test = "test";
      window.localStorage.setItem(test, test);
      window.localStorage.removeItem(test);
      isLSAvailable = true;
    } catch (_unused) {
      isLSAvailable = false;
    }

    return isLSAvailable;
  } else {
    return isLSAvailable;
  }
};

export var localStorage = {
  data: {},
  setItem: function setItem(key, value) {
    if (isLocalStorageAvailable()) {
      return window.localStorage.setItem(key, value);
    } else {
      return this.data = _objectSpread(_objectSpread({}, this.data), {}, _defineProperty({}, key, value));
    }
  },
  getItem: function getItem(key) {
    if (isLocalStorageAvailable()) {
      return window.localStorage.getItem(key);
    } else {
      return this.data[key];
    }
  },
  removeItem: function removeItem(key) {
    if (isLocalStorageAvailable()) {
      return window.localStorage.removeItem(key);
    } else {
      delete this.data[key];
    }
  },
  clear: function clear() {
    if (isLocalStorageAvailable()) {
      return window.localStorage.clear();
    } else {
      this.data = {};
    }
  }
};