import React from "react";
import { ContentPlaceHolderPresetLineBlock } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SubscriptionDetailsPlaceholder = function SubscriptionDetailsPlaceholder() {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ContentPlaceHolderPresetLineBlock, {
    width: "100%",
    mb: 6,
    height: 400,
    borderRadius: "corner"
  }), ___EmotionJSX(ContentPlaceHolderPresetLineBlock, {
    width: "100%",
    height: 150,
    borderRadius: "corner"
  }));
};