import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box, Flex, IconFlybuysCardSingle, IconOnePassDeliveryTruck, IconOnePassReturns, Text } from "@catchoftheday/cg-components";
import { rem } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var commonIconProps = {
  height: rem("24px"),
  width: rem("24px"),
  color: "onePass.primary",
  mr: 4
};
export var BENEFIT_ITEMS = [{
  icon: ___EmotionJSX(IconOnePassDeliveryTruck, _extends({
    "aria-label": "OnePass delivery truck icon"
  }, commonIconProps)),
  title: "Free delivery",
  subtitle: "on eligible items and orders*"
}, {
  icon: ___EmotionJSX(IconOnePassReturns, _extends({
    "aria-label": "OnePass returns icon"
  }, commonIconProps)),
  title: "365 day returns",
  subtitle: "if you change your mind*"
}, {
  icon: ___EmotionJSX(IconFlybuysCardSingle, _extends({
    "aria-label": "Flybuys card icon"
  }, commonIconProps)),
  title: "Collect 5x Flybuys points*",
  subtitle: "for every dollar spent in-store"
}];
export var BenefitItem = function BenefitItem(_ref) {
  var icon = _ref.icon,
      title = _ref.title,
      subtitle = _ref.subtitle,
      isReponsiveFontSize = _ref.isReponsiveFontSize;
  return ___EmotionJSX(Flex, {
    my: 2
  }, icon, ___EmotionJSX(Box, null, ___EmotionJSX(Text, {
    m: 0,
    fontSize: isReponsiveFontSize ? {
      xs: "sm",
      md: "base"
    } : "sm",
    color: "textSecondary",
    fontWeight: "semibold"
  }, title), ___EmotionJSX(Text, {
    m: 0,
    fontSize: isReponsiveFontSize ? {
      xs: "xs",
      md: "sm"
    } : "xs",
    color: "onePass.textGrey",
    fontWeight: "normal"
  }, subtitle)));
};
export var Benefits = function Benefits(_ref2) {
  var isReponsiveFontSize = _ref2.isReponsiveFontSize;
  return ___EmotionJSX(Box, {
    mb: 2
  }, BENEFIT_ITEMS.map(function (item, index) {
    return ___EmotionJSX(BenefitItem, _extends({
      key: "upsell-modal-benefit-".concat(index),
      isReponsiveFontSize: isReponsiveFontSize
    }, item));
  }));
};