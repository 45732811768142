import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { styled } from "@catchoftheday/theme";
import { Box } from "../Box";
import { jsx as ___EmotionJSX } from "@emotion/react";
var highlightedBgColor = "#e2eef9";
var highlightedColor = "#0074d9";
var ListItemContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  padding: 10px;\n  &:hover {\n    color: ", ";\n    background-color: ", ";\n  }\n"])), function (props) {
  return "\n      background-color: ".concat(props.isHighlighted ? highlightedBgColor : "white", ";\n      color: ").concat(props.isHighlighted ? highlightedColor : "#333", ";\n    ");
}, highlightedColor, highlightedBgColor);
export var defaultRenderItem = function defaultRenderItem(_ref) {
  var itemData = _ref.itemData,
      isHighlighted = _ref.isHighlighted;
  return ___EmotionJSX(ListItemContainer, {
    isHighlighted: isHighlighted,
    fontWeight: "light"
  }, itemData.title);
};
export function ensureHighlightedItemIsVisible(_ref2) {
  var _itemRef$current, _itemContainerRef$cur;

  var itemRef = _ref2.itemRef,
      itemContainerRef = _ref2.itemContainerRef;

  if (((_itemRef$current = itemRef.current) === null || _itemRef$current === void 0 ? void 0 : _itemRef$current.offsetParent) != null && ((_itemContainerRef$cur = itemContainerRef.current) === null || _itemContainerRef$cur === void 0 ? void 0 : _itemContainerRef$cur.offsetTop) != null) {
    var itemOffsetRelativeToContainer = itemRef.current.offsetParent === itemContainerRef.current ? itemRef.current.offsetTop : itemRef.current.offsetTop - itemContainerRef.current.offsetTop;
    var scrollTop = itemContainerRef.current.scrollTop; // Top of the visible area

    if (itemOffsetRelativeToContainer < scrollTop) {
      // Item is off the top of the visible area
      scrollTop = itemOffsetRelativeToContainer;
    } else if (itemOffsetRelativeToContainer + itemRef.current.offsetHeight > scrollTop + itemContainerRef.current.offsetHeight) {
      // Item is off the bottom of the visible area
      scrollTop = itemOffsetRelativeToContainer + itemRef.current.offsetHeight - itemContainerRef.current.offsetHeight;
    }

    if (scrollTop !== itemContainerRef.current.scrollTop) {
      itemContainerRef.current.scrollTop = scrollTop;
    }
  }
}