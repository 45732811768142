import React from "react";
import { ApolloProvider } from "@apollo/client";
import { Box, Flex, Heading, Image, LinkButton, Text } from "@catchoftheday/cg-components";
import { getEnvConfig } from "@catchoftheday/env-config";
import { initClient } from "@catchoftheday/graphql-utils";
import { EnhancedHeader, Footer } from "@catchoftheday/header";
import { ThemeProvider } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ErrorPage = function ErrorPage(_ref) {
  var _ref$host = _ref.host,
      host = _ref$host === void 0 ? "" : _ref$host,
      _ref$errorCode = _ref.errorCode,
      errorCode = _ref$errorCode === void 0 ? 404 : _ref$errorCode,
      _ref$includeHeaderAnd = _ref.includeHeaderAndFooter,
      includeHeaderAndFooter = _ref$includeHeaderAnd === void 0 ? true : _ref$includeHeaderAnd;
  return ___EmotionJSX(ApolloProvider, {
    client: initClient({
      initialState: {},
      uri: getEnvConfig().GRAPHQL_ENDPOINT
    })
  }, ___EmotionJSX(ThemeProvider, null, ___EmotionJSX(Box, null, includeHeaderAndFooter && ___EmotionJSX(EnhancedHeader, {
    host: host
  }), ___EmotionJSX(Box, {
    as: "main"
  }, ___EmotionJSX(Flex, {
    py: "4.375rem",
    flexDirection: "column",
    alignItems: "center",
    bg: "brand.primary"
  }, ___EmotionJSX(Box, null, ___EmotionJSX(Image, {
    src: "https://s.catch.com.au/static/catch/images/logo-83d9b21199.svg",
    width: "100%",
    height: "100%",
    alt: "Catch"
  })), ___EmotionJSX(Heading.h1, {
    fontWeight: "700",
    fontSize: "8.75rem",
    color: "white",
    marginTop: 0,
    marginBottom: "0.875rem",
    style: {
      textShadow: "0 4px 4px rgb(0 0 0 / 25%)"
    }
  }, errorCode), ___EmotionJSX(Text, {
    color: "white",
    mb: 3,
    mt: 0,
    fontWeight: "600"
  }, "Looks like the page you're after doesn't exist."), ___EmotionJSX(Text, {
    color: "white",
    mb: 3,
    mt: 0,
    fontWeight: "600"
  }, "Search for millions of other reasons to Checkout Catch!"), ___EmotionJSX(LinkButton, {
    "aria-label": "Go to home",
    mt: "28px",
    href: "/",
    maxWidth: "150px"
  }, "Let's Go"))), includeHeaderAndFooter && ___EmotionJSX(Footer, null))));
};