import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useCallback, useContext } from "react";
import { AppExperimentContext } from "@catchoftheday/analytics";
import { Box } from "@catchoftheday/cg-components";
import { ShopProductsCarouselZone } from "../../layouts/ShopProductsCarouselLayout/ShopProductsCarouselLayout";
import { ShopRecommendedProductsContainer } from "../ShopRecommendedProductsContainer";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopRecommendersContainer = function ShopRecommendersContainer(_ref) {
  var bestSellerProductsNode = _ref.bestSellerProductsNode,
      trendingProductsNode = _ref.trendingProductsNode,
      ProductsCarouselComponent = _ref.ProductsCarouselComponent;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["club_shop_recommender_components_jun21"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      experiment = _getExperiments$exper[0],
      loading = _getExperiments.loading;

  var renderRecommenders = useCallback(function () {
    if ((experiment === null || experiment === void 0 ? void 0 : experiment.bucket) === 1) {
      return ___EmotionJSX(ShopRecommendedProductsContainer, {
        ProductsCarouselComponent: ProductsCarouselComponent
      });
    } else if ((experiment === null || experiment === void 0 ? void 0 : experiment.bucket) === 2) {
      return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ShopRecommendedProductsContainer, {
        ProductsCarouselComponent: ProductsCarouselComponent
      }), ___EmotionJSX(ShopProductsCarouselZone, {
        title: "Trending Products"
      }, trendingProductsNode));
    }

    return ___EmotionJSX(ShopProductsCarouselZone, {
      title: "Best Sellers"
    }, bestSellerProductsNode);
  }, [ProductsCarouselComponent, bestSellerProductsNode, experiment === null || experiment === void 0 ? void 0 : experiment.bucket, trendingProductsNode]);
  if (loading) return null;
  return ___EmotionJSX(Box, {
    pt: 4
  }, renderRecommenders());
};