// Query params
export var ONEPASS_MIGRATION_STATUS_QUERY_PARAM_KEY = "onepass-migration-status";
export var ONEPASS_DOUBLE_SUBSCRIPTION_QUERY_PARAM_KEY = "double-sub";
export var ONEPASS_LINK_QUERY_PARAM_KEY = "link-onepass";
export var ONEPASS_SUCCESS_NOTIFICATION_TOAST_PARAM_KEY = "showmessage";
export var ONEPASS_ELIGIBLE_FOR_MIGRATION_PARAM_KEY = "eligible-for-migration"; // Others

export var HOTLINE_ONEPASS = "1300 222 824";
export var DEFAULT_FREE_TRIAL_DAYS = 30;
export var ONEPASS_MONTHLY_PRICE = 400;
export var ONEPASS_YEARLY_PRICE = 4000;
export var ONEPASS_DISNEY_PLUS_BUNDLE_MONTHLY_PRICE = 1499;
export var ONEPASS_DISNEY_PLUS_BUNDLE_MONTHLY_SAVING = 300;
export var COOKIE_ONEPASS_MIGRATION_MODAL_KEY = "OPMigrationModal";
export * from "./analytics";
export * from "./assets";
export * from "./copy";
export * from "./urls";
export * from "./shop";