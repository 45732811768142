import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["product"];

var _templateObject;

import React from "react";
import { Box, Flex, ImageResponsive, Link, Text } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { addTrailingSlash, resizeImage } from "@catchoftheday/utilities";
import { ProductCardTitle } from "../ProductCard/ProductCardTitle";
import { ProductPrice } from "../ProductPrice";
import { jsx as ___EmotionJSX } from "@emotion/react";
var PRODUCT_IMAGE_ASPECT_RATIO = 81.818;
var ProductCardLink = styled(Link)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &:hover h2 {\n    text-decoration: underline;\n  }\n"])));
export var MiniProductCard = function MiniProductCard(_ref) {
  var product = _ref.product,
      props = _objectWithoutProperties(_ref, _excluded);

  var id = product.id,
      title = product.title,
      brand = product.brand,
      url = product.url,
      priceTag = product.priceTag,
      _product$assets$hero = product.assets.hero,
      assetUrl = _product$assets$hero.url,
      altText = _product$assets$hero.altText;
  return ___EmotionJSX(Flex, _extends({}, props, {
    pr: "20px"
  }), ___EmotionJSX(ProductCardLink, {
    href: addTrailingSlash(url),
    display: "flex",
    color: "text",
    hoverColor: "currentColor",
    textDecoration: "none !important",
    width: "100%"
  }, ___EmotionJSX(Box, {
    width: "50%",
    pr: "10px"
  }, ___EmotionJSX(ImageResponsive, {
    alt: altText,
    lazyLoad: false,
    sourceWidths: [100, 200, 300, 400, 500],
    src: resizeImage(assetUrl),
    ratio: PRODUCT_IMAGE_ASPECT_RATIO
  })), ___EmotionJSX(Flex, {
    flexDirection: "column",
    justifyContent: "center",
    flex: 1
  }, ___EmotionJSX(ProductCardTitle, {
    title: title,
    productId: id,
    fontSize: "sm",
    px: 0,
    containerProps: {
      justifyContent: "flex-start"
    }
  }), ___EmotionJSX(Text, {
    fontSize: "xs",
    m: "0px",
    mt: "4px",
    color: "textTertiary"
  }, (brand === null || brand === void 0 ? void 0 : brand.name) || ""), priceTag && ___EmotionJSX(Box, {
    maxWidth: 90
  }, ___EmotionJSX(ProductPrice, {
    priceTag: priceTag
  })))));
};