import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3;

import React, { useEffect, useMemo, useState } from "react";
import { Box, Flex, Span } from "@catchoftheday/cg-components";
import { css, keyframes, styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var animationTime = 3000;
var animation = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n    transform: translateY(-15px);\n  }\n  8%{\n    opacity: 1;\n    transform: translateY(0px);\n  }\n  92%{\n    opacity: 1;\n    transform: translateY(0px);\n  }\n  100%{\n    opacity: 0;\n    transform: translateY(15px);\n  }\n"])));
var NoPointer = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  pointer-events: none;\n  white-space: nowrap;\n"])));

var updateTicker = function updateTicker(term) {
  return ___EmotionJSX(Span, {
    key: term,
    fontSize: "base",
    fontWeight: "bold",
    css: css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      animation: ", " ", "ms ease-in-out;\n    "])), animation, animationTime),
    display: "inline-block",
    "data-testid": "searchticker"
  }, term);
};

export var SearchTicker = function SearchTicker(_ref) {
  var trendingSearchTerms = _ref.trendingSearchTerms,
      onClick = _ref.onClick;
  var shuffledTerms = useMemo(function () {
    return trendingSearchTerms.map(function (value) {
      return {
        value: value,
        sort: Math.random()
      };
    }).sort(function (a, b) {
      return a.sort - b.sort;
    }).map(function (_ref2) {
      var value = _ref2.value;
      return value;
    });
  }, [trendingSearchTerms]);

  var _useState = useState(updateTicker(shuffledTerms[0])),
      visibleTerm = _useState[0],
      setVisibleTerm = _useState[1];

  useEffect(function () {
    var count = 1;
    var interval = setInterval(function () {
      setVisibleTerm(updateTicker(shuffledTerms[count++ % shuffledTerms.length]));
    }, animationTime);
    return function () {
      return clearInterval(interval);
    };
  }, []);
  return ___EmotionJSX(NoPointer, {
    position: "absolute",
    top: "1px",
    bottom: "1px",
    alignItems: "center",
    left: {
      xs: "11px",
      md: "21px"
    },
    right: "1px",
    onClick: onClick,
    zIndex: 200,
    background: "white",
    overflow: "hidden"
  }, ___EmotionJSX(Box, {
    color: "typography.inactive",
    position: "relative",
    fontWeight: "400"
  }, "Search for ", visibleTerm));
};