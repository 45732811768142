import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["productId", "rating", "ratingCount", "url"];
import React from "react";
import { Flex, Link, Span, StarRating } from "@catchoftheday/cg-components";
import { minusOnePx, theme, useMedia } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductCardRating = function ProductCardRating(_ref) {
  var productId = _ref.productId,
      rating = _ref.rating,
      ratingCount = _ref.ratingCount,
      url = _ref.url,
      props = _objectWithoutProperties(_ref, _excluded);

  var isMobile = useMedia(["(max-width: ".concat(minusOnePx(theme.breakpoints.md, true), ")")], [true]);

  if (rating) {
    return ___EmotionJSX(Flex, props, ___EmotionJSX(Link, {
      display: {
        md: "inherit"
      },
      m: {
        sm: "8px 0 6px",
        md: "3px 0 6px"
      },
      lineHeight: 1,
      className: "js-product-link js-dnt",
      href: "".concat(url, "#review-section")
    }, ___EmotionJSX(StarRating, {
      width: isMobile ? 70 : 90,
      backgroundColour: "#fff",
      rating: rating
    }), ___EmotionJSX(Span, {
      fontSize: "12px",
      ml: "6px",
      lineHeight: {
        xs: 2,
        md: 3
      },
      textAlign: "center",
      color: "typography.subText"
    }, "(".concat(ratingCount, ")"))));
  }

  return null;
};