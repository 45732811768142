import React from "react";
import { Image } from "@catchoftheday/cg-components";
import { ASSET_DISNEY_PLUS_BUNDLE_SIDE_IMAGE_DESKTOP, ASSET_DISNEY_PLUS_BUNDLE_SIDE_IMAGE_MOBILE } from "../../../consts";
import { useIsMobile } from "../../../hooks";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var DisneyPlusBundleBoxSideImage = function DisneyPlusBundleBoxSideImage() {
  var isMobile = useIsMobile("lg");
  return ___EmotionJSX(Image, {
    position: {
      xs: "static",
      lg: "absolute"
    },
    right: 0,
    top: 0,
    src: isMobile ? ASSET_DISNEY_PLUS_BUNDLE_SIDE_IMAGE_MOBILE : ASSET_DISNEY_PLUS_BUNDLE_SIDE_IMAGE_DESKTOP,
    alt: "Disney+ bundle side image",
    width: {
      xs: "100%",
      lg: "auto"
    },
    height: {
      xs: "auto",
      lg: "100%"
    }
  });
};