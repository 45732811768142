import React from "react";
import { MarketplaceFreeShippingTag } from "./MarketplaceFreeShippingTag";
import { OnePassFreeShippingTag } from "./OnePassFreeShippingTag";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductCardTags = function ProductCardTags(props) {
  var onePassEligible = props.onePassEligible,
      freeShippingAvailable = props.freeShippingAvailable;

  if (freeShippingAvailable) {
    return ___EmotionJSX(MarketplaceFreeShippingTag, null);
  }

  if (onePassEligible) {
    return ___EmotionJSX(OnePassFreeShippingTag, null);
  }

  return null;
};