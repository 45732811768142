import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box, ContentPlaceHolder } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { Card } from "./Card";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EventCardPlaceholder = function EventCardPlaceholder(props) {
  var theme = useTheme();
  return ___EmotionJSX(Card, _extends({
    role: "listitem",
    "aria-label": "Card loading",
    title: "Card loading",
    border: 1,
    borderColor: "grey",
    borderRadius: "default"
  }, props), ___EmotionJSX(ContentPlaceHolder, {
    speed: 2,
    display: "block",
    width: "100%",
    viewBox: "0 0 90 60",
    preserveAspectRatio: "xMinYMin meet",
    backgroundColor: theme.colors.grey,
    uniqueKey: "event-card-loader-img"
  }, ___EmotionJSX("rect", {
    width: "90",
    height: "60",
    fill: theme.colors.darkGrey
  })), ___EmotionJSX(Box, {
    mt: 1
  }, ___EmotionJSX(ContentPlaceHolder, {
    speed: 2,
    display: "block",
    width: "100%",
    viewBox: "0 0 400 120",
    preserveAspectRatio: "xMinYMin meet",
    backgroundColor: theme.colors.textWhite,
    uniqueKey: "event-card-loader-info"
  }, ___EmotionJSX("rect", {
    width: "400",
    height: "120",
    fill: theme.colors.darkGrey
  }))));
};