import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { Box, Hide } from "@catchoftheday/cg-components";
import { CancellationModalContainer, LinkingModalContainer, MigrationModalContainer, UpsellModalContainer } from "@catchoftheday/onepass";
import { ThemeProvider } from "@catchoftheday/theme";
import { CouponNotification } from "@header/components/CouponNotification";
import { FlashMessageBanner } from "@header/components/FlashMessageBanner";
import { MegaMenu } from "@header/components/MegaMenu";
import { OnePassNotification } from "@header/components/NotificationToast";
import { PrimaryHeader } from "@header/components/PrimaryHeader";
import theme from "@header/components/theme";
import { TopHeader } from "@header/components/TopHeader";
import { HeaderToastContainer } from "../HeaderToast";
import { useHeaderMetaData } from "./metadata";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Header = function Header(_ref) {
  var _metaData$flybuysNoti, _metaData$unreadMarke;

  var headerConfiguration = _ref.headerConfiguration;

  var _useHeaderMetaData = useHeaderMetaData(headerConfiguration),
      metaData = _useHeaderMetaData.metaData,
      initialHeaderMetaData = _useHeaderMetaData.initialHeaderMetaData,
      isLoading = _useHeaderMetaData.isLoading;

  return ___EmotionJSX(ThemeProvider, {
    theme: theme,
    enableGlobalFontConfig: false,
    enableGlobalBoxSizingConfig: true,
    appName: "header"
  }, ___EmotionJSX(Box, {
    position: "relative",
    zIndex: 2,
    maxWidth: "100%"
  }, ___EmotionJSX(Box, {
    id: "top-header"
  }, !isLoading && (metaData === null || metaData === void 0 ? void 0 : metaData.couponNotificationEnabled) && ___EmotionJSX(CouponNotification, {
    displayName: metaData === null || metaData === void 0 ? void 0 : metaData.displayName
  }), ___EmotionJSX(Hide, {
    xs: true,
    sm: true
  }, ___EmotionJSX(TopHeader, {
    isLoggedIn: metaData === null || metaData === void 0 ? void 0 : metaData.loggedIn,
    headerConfiguration: headerConfiguration
  }))), ___EmotionJSX(PrimaryHeader, {
    id: "primary-header",
    isLoading: isLoading,
    isLoggedIn: metaData === null || metaData === void 0 ? void 0 : metaData.loggedIn,
    isCCMember: metaData === null || metaData === void 0 ? void 0 : metaData.clubActive,
    isFlybuysLinked: metaData === null || metaData === void 0 ? void 0 : metaData.flybuysLinked,
    userName: metaData === null || metaData === void 0 ? void 0 : metaData.displayName,
    cartItemsAmount: metaData === null || metaData === void 0 ? void 0 : metaData.cartItemCount,
    wishItemsAmount: metaData === null || metaData === void 0 ? void 0 : metaData.wishlistItemCount,
    myAccountMessageCount: metaData === null || metaData === void 0 ? void 0 : metaData.messageCount,
    headerConfiguration: headerConfiguration
  }), (initialHeaderMetaData === null || initialHeaderMetaData === void 0 ? void 0 : initialHeaderMetaData.megaMenuEnabled) && (initialHeaderMetaData === null || initialHeaderMetaData === void 0 ? void 0 : initialHeaderMetaData.navData) && ___EmotionJSX(MegaMenu, {
    id: "mega-menu",
    shops: initialHeaderMetaData.navData,
    essentialsMenu: initialHeaderMetaData.essentialsMenu,
    accountMenu: _objectSpread(_objectSpread({}, initialHeaderMetaData.accountMenu), {}, {
      flybuysNotificationCount: (_metaData$flybuysNoti = metaData === null || metaData === void 0 ? void 0 : metaData.flybuysNotificationCount) !== null && _metaData$flybuysNoti !== void 0 ? _metaData$flybuysNoti : 0,
      unreadMarketplaceMessageCount: (_metaData$unreadMarke = metaData === null || metaData === void 0 ? void 0 : metaData.unreadMarketplaceMessageCount) !== null && _metaData$unreadMarke !== void 0 ? _metaData$unreadMarke : 0
    }),
    cartItemsCount: metaData === null || metaData === void 0 ? void 0 : metaData.cartItemCount,
    wishlistItemsCount: metaData === null || metaData === void 0 ? void 0 : metaData.wishlistItemCount,
    isLoggedIn: metaData === null || metaData === void 0 ? void 0 : metaData.loggedIn
  }), ___EmotionJSX(FlashMessageBanner, null), ___EmotionJSX(HeaderToastContainer, null), ___EmotionJSX(LinkingModalContainer, null), ___EmotionJSX(MigrationModalContainer, null), ___EmotionJSX(CancellationModalContainer, null), ___EmotionJSX(UpsellModalContainer, null), ___EmotionJSX(OnePassNotification, null)));
};