import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["onClick"];
import React from "react";
import { IconToolTip, Span } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SponsoredLabel = function SponsoredLabel(_ref) {
  var _onClick = _ref.onClick,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Span, _extends({
    color: "typography.subText",
    display: "inline-block"
  }, props), "Sponsored", ___EmotionJSX(IconToolTip, {
    "aria-label": "Price disclaimer",
    tabIndex: 0,
    width: "12px",
    height: "12px",
    ml: 1,
    mb: "2px",
    color: "textTertiary",
    cursor: "pointer",
    onClick: function onClick(event) {
      event.preventDefault();

      _onClick();
    }
  }));
};