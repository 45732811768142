import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SideImageZone = function SideImageZone(props) {
  return ___EmotionJSX(Box, _extends({
    width: {
      xs: "100%",
      md: "50%"
    },
    overflow: "hidden"
  }, props));
};
export var ContentZone = function ContentZone(props) {
  return ___EmotionJSX(Box, _extends({
    width: {
      xs: "100%",
      md: "50%"
    },
    py: {
      xs: 6,
      md: 9
    },
    px: {
      xs: 4,
      md: 9
    },
    color: "onePass.textPrimary",
    fontFamily: "onePass"
  }, props));
};