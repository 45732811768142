export var isActiveOnePassMember = function isActiveOnePassMember(customer) {
  var _membership$subscript;

  var _ref = customer || {},
      club = _ref.club,
      membership = _ref.membership;

  return Boolean((club === null || club === void 0 ? void 0 : club.active) || (membership === null || membership === void 0 ? void 0 : (_membership$subscript = membership.subscription) === null || _membership$subscript === void 0 ? void 0 : _membership$subscript.active));
};
export var isEligibleForOnePassMigration = function isEligibleForOnePassMigration(customer) {
  var _membership$eligibleF;

  var _ref2 = customer || {},
      membership = _ref2.membership;

  return (_membership$eligibleF = membership === null || membership === void 0 ? void 0 : membership.eligibleForMigration) !== null && _membership$eligibleF !== void 0 ? _membership$eligibleF : false;
};
export var isCancellingMembership = function isCancellingMembership(subscription) {
  var _ref3 = subscription || {},
      active = _ref3.active,
      autoRenewing = _ref3.autoRenewing;

  return Boolean(active && !autoRenewing);
};
export var isMembershipFreeTrialEligible = function isMembershipFreeTrialEligible(customer) {
  var _ref4 = customer || {},
      membership = _ref4.membership;

  var _ref5 = membership || {},
      subscription = _ref5.subscription;

  var _ref6 = subscription || {},
      freeTrialEligible = _ref6.freeTrialEligible;

  var isLoggedIn = !!customer;
  var isEligible = freeTrialEligible || !membership || !isLoggedIn;
  return isEligible;
};