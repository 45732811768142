import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Flex, Hide, IconDownArrow } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { localStorage } from "@header/utilities";
import { scrollTo } from "../utilities/scrollTo";
import { SCROLL_BUFFER, SCROLL_INCREMENT } from "./consts";
import { MenuBar } from "./MenuBar";
import { jsx as ___EmotionJSX } from "@emotion/react";
var ScrollIndicator = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 80px;\n  background: linear-gradient(\n    ", ",\n    rgba(250, 250, 250, 0) 0%,\n    rgba(250, 250, 250, 1) 50%\n  );\n  position: absolute;\n  right: ", ";\n  left: ", ";\n  top: 0;\n  bottom: 0;\n  align-items: center;\n  justify-content: ", ";\n  svg {\n    transform: rotate(\n      ", "\n    );\n  }\n  transition: left 0.3s ease-out, right 0.3s ease-out;\n"])), function (props) {
  return props.placement === "right" ? "90deg" : "-90deg";
}, function (props) {
  return props.placement === "right" ? props.visible ? 0 : "-50px" : "auto";
}, function (props) {
  return props.placement === "left" ? props.visible ? 0 : "-50px" : "auto";
}, function (props) {
  return props.placement === "right" ? "flex-end" : "flex-start";
}, function (props) {
  return props.placement === "right" ? "-90deg" : "90deg";
});
var NavArrowButton = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  justify-content: center;\n  align-items: center;\n  width: 32px;\n  height: 36px;\n  border: 1px solid ", ";\n  border-radius: 4px;\n  background-color: white;\n  box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.15);\n"])), function (props) {
  return themeGet("colors.ui.borderColor")(props);
});
export var DesktopNav = function DesktopNav(_ref) {
  var shops = _ref.shops;

  var _useState = useState(false),
      leftArrowVisible = _useState[0],
      setLeftArrowVisible = _useState[1];

  var _useState2 = useState(false),
      rightArrowVisible = _useState2[0],
      setRightArrowVisible = _useState2[1];

  var scrollerRef = useRef(null);
  var handleArrowClick = useCallback(function (side) {
    if (scrollerRef.current) {
      var scrollDistance = side === "next" ? scrollerRef.current.scrollLeft + scrollerRef.current.clientWidth / SCROLL_INCREMENT : scrollerRef.current.scrollLeft - scrollerRef.current.clientWidth / SCROLL_INCREMENT;
      scrollTo({
        scrollerElement: scrollerRef.current,
        scrollLeftPosition: scrollDistance
      });
    }
  }, []);

  var recordScrollPosition = function recordScrollPosition() {
    if (scrollerRef !== null && scrollerRef !== void 0 && scrollerRef.current) {
      localStorage.setItem("navScrollPosition", "".concat(scrollerRef.current.scrollLeft));
    }
  };

  var checkScrollPosition = function checkScrollPosition(scroller) {
    if (scroller.scrollLeft + scroller.clientWidth >= scroller.scrollWidth - SCROLL_BUFFER) {
      setRightArrowVisible(false);
    } else {
      setRightArrowVisible(true);
    }

    if (scroller.scrollLeft > SCROLL_BUFFER) {
      setLeftArrowVisible(true);
    } else {
      setLeftArrowVisible(false);
    }
  };

  useEffect(function () {
    var previousScrollPosition = localStorage.getItem("navScrollPosition");

    if (previousScrollPosition && scrollerRef !== null && scrollerRef !== void 0 && scrollerRef.current) {
      scrollTo({
        scrollerElement: scrollerRef.current,
        scrollLeftPosition: Number(previousScrollPosition)
      });
      localStorage.removeItem("navScrollPosition");
    }
  }, []);

  var onRefAssign = function onRefAssign(scrollerElement) {
    scrollerRef.current = scrollerElement;

    if (scrollerElement) {
      var showNavArrows = function showNavArrows() {
        return checkScrollPosition(scrollerElement);
      };

      showNavArrows();
      window.addEventListener("resize", showNavArrows);
    }
  };

  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MenuBar, {
    shops: shops,
    ref: onRefAssign,
    checkScrollPosition: checkScrollPosition,
    recordScrollPosition: recordScrollPosition
  }), ___EmotionJSX(Hide, {
    lg: true,
    xl: true
  }, ___EmotionJSX(ScrollIndicator, {
    "aria-label": "Scroll left",
    placement: "left",
    visible: leftArrowVisible
  }, ___EmotionJSX(NavArrowButton, {
    ml: "10px",
    onClick: function onClick() {
      return handleArrowClick("prev");
    }
  }, ___EmotionJSX(IconDownArrow, {
    width: "17px",
    stroke: "white"
  }))), ___EmotionJSX(ScrollIndicator, {
    "aria-label": "Scroll right",
    placement: "right",
    visible: rightArrowVisible
  }, ___EmotionJSX(NavArrowButton, {
    mr: "10px",
    onClick: function onClick() {
      return handleArrowClick("next");
    }
  }, ___EmotionJSX(IconDownArrow, {
    width: "17px",
    stroke: "white"
  })))));
};