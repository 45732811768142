import React from "react";
import { Box, Flex, IconOnePass, Span } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopHeader = function ShopHeader() {
  var theme = useTheme();
  return ___EmotionJSX(Box, {
    mt: {
      xs: 6,
      lg: 10
    },
    mb: {
      xs: 5,
      lg: 4
    },
    px: {
      xs: 4,
      lg: 8
    }
  }, ___EmotionJSX(Flex, {
    alignItems: "center",
    as: "h1",
    maxWidth: theme.maxWidths.cotdWebsite,
    m: "0 auto"
  }, ___EmotionJSX(IconOnePass, {
    color: "onePass.primary",
    width: "130px",
    height: "auto",
    mr: 2,
    title: "OnePass"
  }), ___EmotionJSX(Span, {
    fontSize: "3xl",
    fontWeight: "semibold",
    mt: 2,
    lineHeight: 1
  }, "Shop")));
};