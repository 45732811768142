import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";

var _templateObject;

var _excluded = ["children", "showArrow", "large"];
import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { removeProperty } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPriceAside = function ProductPriceAside(_ref) {
  var children = _ref.children,
      showArrow = _ref.showArrow,
      large = _ref.large,
      flexProps = _objectWithoutProperties(_ref, _excluded);

  var content = children || " ";
  var ArrowPriceBox = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    &::after {\n      content: \"\";\n      display: block;\n      position: absolute;\n      z-index: 1;\n      top: 0;\n      bottom: 0;\n      left: 100%;\n      width: 0.21875em;\n      height: 100%;\n      background-repeat: no-repeat;\n      background-size: 100% 100%;\n      ", "\n    }\n  "])), showArrow && 'background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2050%20100%22%20preserveAspectRatio%3D%22none%22%3E%3Cpolygon%20points%3D%220%2C%200%2050%2C%2050%200%2C%20100%22%20fill%3D%22%23fff%22%20%2F%3E%3C%2Fsvg%3E")');
  return ___EmotionJSX(ArrowPriceBox, _extends({
    display: "flex",
    position: "relative",
    flex: "0 1 auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "1",
    textAlign: "center",
    backgroundColor: "white",
    color: "typography.text",
    paddingLeft: large ? "14px" : "10px",
    paddingRight: large ? "9px" : "6px",
    border: "1px solid",
    borderColor: "ui.borderColor"
  }, removeProperty(flexProps, "ref")), content);
};