import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["isLoggedIn", "isCCMember", "isFlybuysLinked", "wishItemsAmount", "cartItemsAmount", "userName", "isLoading", "myAccountMessageCount", "headerConfiguration"];

var _templateObject;

import React from "react";
import { Box, Flex, Image, Link, NoSSR, Picture } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { Container } from "../Container";
import { SearchBar } from "../SearchBar";
import { FlybuysTile } from "./FlybuysTile";
import { MiniCart } from "./MiniCart";
import { MyAccount } from "./MyAccount";
import { WishList } from "./WishList"; // Overriding for web

var LogoContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  @media (max-width: ", ") and (min-width: ", ") {\n    width: 96px;\n  }\n"])), themeGet("breakpoints.lg"), themeGet("breakpoints.md"));
import { LogoDecoration } from "./LogoDecoration";
import { OnePassFlybuysTile } from "./OnePassFlybuysTile";
import { OnePassTile } from "./OnePassTile";
import { jsx as ___EmotionJSX } from "@emotion/react";

var TileFlex = function TileFlex(props) {
  return ___EmotionJSX(Flex, _extends({
    display: {
      xs: "inherit",
      sm: "none",
      lg: "inherit"
    }
  }, props));
};

export var PrimaryHeader = function PrimaryHeader(_ref) {
  var _primaryHeaderConfig$4, _primaryHeaderConfig$5, _primaryHeaderConfig$6, _primaryHeaderConfig$7, _primaryHeaderConfig$8;

  var isLoggedIn = _ref.isLoggedIn,
      isCCMember = _ref.isCCMember,
      isFlybuysLinked = _ref.isFlybuysLinked,
      wishItemsAmount = _ref.wishItemsAmount,
      cartItemsAmount = _ref.cartItemsAmount,
      userName = _ref.userName,
      isLoading = _ref.isLoading,
      myAccountMessageCount = _ref.myAccountMessageCount,
      headerConfiguration = _ref.headerConfiguration,
      props = _objectWithoutProperties(_ref, _excluded);

  var primaryHeaderConfig = headerConfiguration === null || headerConfiguration === void 0 ? void 0 : headerConfiguration.primaryHeader;
  var eventTheme = headerConfiguration === null || headerConfiguration === void 0 ? void 0 : headerConfiguration.headerTheme;
  var defaultWidths = ["3.06rem", "70px", "10.71875rem", "8.09rem", "10.71875rem"];
  var themedWidths = ["3.0625rem", "70px", "96px", "8.09375rem", "9.625rem"];

  var renderFeatTiles = function renderFeatTiles() {
    var _primaryHeaderConfig$, _primaryHeaderConfig$2, _primaryHeaderConfig$3;

    return ___EmotionJSX(React.Fragment, null, (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$ = primaryHeaderConfig.club) === null || _primaryHeaderConfig$ === void 0 ? void 0 : _primaryHeaderConfig$.clubCatchTileEnabled) != false && ___EmotionJSX(TileFlex, {
      "data-testid": "onepass-header-tile"
    }, ___EmotionJSX(OnePassTile, {
      isLoggedIn: isLoggedIn
    })), !isFlybuysLinked && ___EmotionJSX(React.Fragment, null, isLoggedIn && isCCMember ? ___EmotionJSX(React.Fragment, null, (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$2 = primaryHeaderConfig.clubFlybuys) === null || _primaryHeaderConfig$2 === void 0 ? void 0 : _primaryHeaderConfig$2.enabled) != false && ___EmotionJSX(TileFlex, null, ___EmotionJSX(OnePassFlybuysTile, null))) : ___EmotionJSX(React.Fragment, null, (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$3 = primaryHeaderConfig.flybuys) === null || _primaryHeaderConfig$3 === void 0 ? void 0 : _primaryHeaderConfig$3.enabled) != false && ___EmotionJSX(TileFlex, null, ___EmotionJSX(FlybuysTile, null)))));
  };

  return ___EmotionJSX(Box, {
    as: "nav",
    "aria-label": "Primary Header Navigation"
  }, ___EmotionJSX(Container, _extends({
    backgroundColor: "secondary",
    height: {
      sm: "70px",
      md: "95px"
    },
    eventTheme: eventTheme,
    position: "relative",
    zIndex: 2
  }, props), ___EmotionJSX(Flex, {
    justifyContent: "space-between",
    height: "100%"
  }, ___EmotionJSX(Flex, {
    alignItems: "center",
    flexGrow: 1,
    justifyContent: ["center", null, null, "flex-start"],
    py: 0
  }, ___EmotionJSX(LogoContainer, {
    pr: ["0.4375rem", "0.875rem", "1.1rem"],
    width: eventTheme ? themedWidths : defaultWidths,
    position: "relative"
  }, ___EmotionJSX(Link, {
    href: (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : primaryHeaderConfig.logo.url) || "/",
    className: "js-dnt",
    "data-tracking": "header-app-logo"
  }, ___EmotionJSX(Picture, {
    "data-testid": "catch-logo"
  }, eventTheme && ___EmotionJSX(LogoDecoration, {
    eventTheme: eventTheme
  }), ___EmotionJSX("source", {
    media: "(max-width: 1024px)",
    srcSet: "https://s.catch.com.au/static/catch/images/logo-condensed-b8974103f2.svg",
    type: "image/svg+xml"
  }), ___EmotionJSX(Image, {
    width: "300",
    height: "132",
    alt: (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : primaryHeaderConfig.logo.alt) || "Catch Logo",
    src: (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : primaryHeaderConfig.logo.imageSrc) || "https://s.catch.com.au/static/catch/images/logo-83d9b21199.svg"
  })))), (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$4 = primaryHeaderConfig.searchBar) === null || _primaryHeaderConfig$4 === void 0 ? void 0 : _primaryHeaderConfig$4.enabled) != false && ___EmotionJSX(SearchBar, {
    flexContainerProps: {
      flexGrow: 1,
      pr: ["4px", null, "20px"]
    }
  })), ___EmotionJSX(Flex, {
    color: "white",
    flexBasis: ["auto", null, null, null, "660px"],
    justifyContent: "flex-end",
    display: ["inherit", "none", "inherit", null, null]
  }, !isLoading && ___EmotionJSX(NoSSR, null, (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$5 = primaryHeaderConfig.myAccount) === null || _primaryHeaderConfig$5 === void 0 ? void 0 : _primaryHeaderConfig$5.enabled) != false && ___EmotionJSX(Flex, null, ___EmotionJSX(MyAccount, {
    showFlybuysLink: primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$6 = primaryHeaderConfig.myAccount) === null || _primaryHeaderConfig$6 === void 0 ? void 0 : _primaryHeaderConfig$6.showFlybuysLink,
    isLoggedIn: isLoggedIn,
    isCCMember: isCCMember,
    isFlybuysLinked: isFlybuysLinked,
    userName: userName,
    messageCount: myAccountMessageCount
  })), renderFeatTiles()), ___EmotionJSX(Flex, null, (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$7 = primaryHeaderConfig.wishlist) === null || _primaryHeaderConfig$7 === void 0 ? void 0 : _primaryHeaderConfig$7.enabled) != false && ___EmotionJSX(WishList, {
    wishItemsAmount: wishItemsAmount,
    isLoggedIn: isLoggedIn
  }), (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$8 = primaryHeaderConfig.cart) === null || _primaryHeaderConfig$8 === void 0 ? void 0 : _primaryHeaderConfig$8.enabled) != false && ___EmotionJSX(MiniCart, {
    cartItemsAmount: cartItemsAmount
  }))))));
};
PrimaryHeader.defaultProps = {
  isLoggedIn: false,
  isCCMember: false,
  isFlybuysLinked: false,
  userName: ""
};