import React, { useEffect } from "react";
import { emitLinkingNotification, emitUpsellNotification } from "@catchoftheday/analytics";
import { toast } from "@catchoftheday/cg-components";
import { useCustomerQuery } from "@catchoftheday/customer";
import { getQueryStringValue } from "@header/utilities";
import { NotificationToastContainer, NotificationToastContent } from "../NotificationToast/components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var OnePassNotification = function OnePassNotification() {
  var _membership$subscript6, _membership$subscript7;

  var _useCustomerQuery = useCustomerQuery({
    ssr: false
  }),
      data = _useCustomerQuery.data;

  var _ref = (data === null || data === void 0 ? void 0 : data.customer) || {},
      membership = _ref.membership,
      club = _ref.club;

  var containerId = "notification-toast";
  var showMessageQueryParam = getQueryStringValue("showmessage");

  var showToast = function showToast(message) {
    toast(___EmotionJSX(NotificationToastContent, {
      message: message
    }), {
      containerId: containerId
    });
  };

  useEffect(function () {
    if (data && (showMessageQueryParam === "u" || showMessageQueryParam === "l")) {
      var _membership$subscript, _membership$subscript2, _membership$subscript3, _membership$subscript4, _membership$subscript5;

      var message = "";

      if (!(club !== null && club !== void 0 && club.active) && membership !== null && membership !== void 0 && membership.linked && !(membership !== null && membership !== void 0 && (_membership$subscript = membership.subscription) !== null && _membership$subscript !== void 0 && _membership$subscript.active)) {
        message = "Catch and OnePass accounts linked. No active subscription found.";
      } else if (!(club !== null && club !== void 0 && club.active) && membership !== null && membership !== void 0 && membership.linked && membership !== null && membership !== void 0 && (_membership$subscript2 = membership.subscription) !== null && _membership$subscript2 !== void 0 && _membership$subscript2.active && membership !== null && membership !== void 0 && (_membership$subscript3 = membership.subscription) !== null && _membership$subscript3 !== void 0 && _membership$subscript3.onFreeTrial) {
        message = showMessageQueryParam === "l" ? "Catch and OnePass accounts linked." : "OnePass free trial activated.";
      } else if (!(club !== null && club !== void 0 && club.active) && membership !== null && membership !== void 0 && membership.linked && membership !== null && membership !== void 0 && (_membership$subscript4 = membership.subscription) !== null && _membership$subscript4 !== void 0 && _membership$subscript4.active && !(membership !== null && membership !== void 0 && membership.subscription.onFreeTrial)) {
        message = showMessageQueryParam === "l" ? "Catch and OnePass accounts linked." : "OnePass subscription activated.";
      } else if (club !== null && club !== void 0 && club.active && membership !== null && membership !== void 0 && membership.linked && !(membership !== null && membership !== void 0 && (_membership$subscript5 = membership.subscription) !== null && _membership$subscript5 !== void 0 && _membership$subscript5.active)) {
        message = showMessageQueryParam === "l" ? "Catch and OnePass accounts linked." : "";
      }

      if (message) {
        if (showMessageQueryParam === "u") {
          emitUpsellNotification({
            type: "success",
            message: message
          });
        } else if (showMessageQueryParam === "l") {
          emitLinkingNotification({
            type: "success",
            message: message
          });
        }

        showToast(message);
      }
    }
  }, [club === null || club === void 0 ? void 0 : club.active, data, membership === null || membership === void 0 ? void 0 : membership.linked, membership === null || membership === void 0 ? void 0 : (_membership$subscript6 = membership.subscription) === null || _membership$subscript6 === void 0 ? void 0 : _membership$subscript6.active, membership === null || membership === void 0 ? void 0 : (_membership$subscript7 = membership.subscription) === null || _membership$subscript7 === void 0 ? void 0 : _membership$subscript7.onFreeTrial, showMessageQueryParam]);
  return ___EmotionJSX(NotificationToastContainer, {
    containerId: containerId
  });
};