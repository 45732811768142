import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["highlight", "text", "highlightTextProps", "textProps", "reverse"];
import React from "react";
import { escapeStringRegex } from "@catchoftheday/utilities";
import { Span } from "../Text";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Highlighter = function Highlighter(_ref) {
  var _ref$highlight = _ref.highlight,
      highlight = _ref$highlight === void 0 ? "" : _ref$highlight,
      _ref$text = _ref.text,
      text = _ref$text === void 0 ? "" : _ref$text,
      highlightTextProps = _ref.highlightTextProps,
      textProps = _ref.textProps,
      reverse = _ref.reverse,
      props = _objectWithoutProperties(_ref, _excluded);

  var escapedText = escapeStringRegex(highlight);
  var parts = text.split(new RegExp("(".concat(escapedText, ")"), "gi"));

  function renderHighlightedString(text, key) {
    return ___EmotionJSX(Span, _extends({
      key: key
    }, highlightTextProps), text);
  }

  return ___EmotionJSX(Span, _extends({}, textProps, props, {
    fontWeight: "bold"
  }), parts.map(function (part, idx) {
    return reverse ? part.toLowerCase() === highlight.toLowerCase() ? part : renderHighlightedString(part, idx) : part.toLowerCase() === highlight.toLowerCase() ? renderHighlightedString(part, idx) : part;
  }));
};
Highlighter.displayName = "Highlighter";
export default Highlighter;