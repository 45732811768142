export var getMockRecommendedProduct = function getMockRecommendedProduct(_ref) {
  var id = _ref.id;
  var productTitle = "Recommended Product ".concat(id);
  return {
    __typename: "Product",
    id: id || "123456",
    title: productTitle,
    ratingsSummary: {
      __typename: "RatingsSummary",
      numberOfRatings: 75,
      averageRating: 4.5
    },
    assets: {
      __typename: "ProductAssets",
      hero: {
        __typename: "Image",
        url: "https://static.devcat.ch/images/product/0000/0818/5773408789f26095525527.jpg",
        altText: productTitle
      }
    },
    brand: {
      __typename: "Brand",
      name: "Palmolive",
      slug: "palmolive"
    },
    bestOffer: {
      __typename: "Offer",
      id: "84782",
      clubExclusive: false,
      clubFreeShipping: true,
      freeShipping: false,
      badges: ["short-dated"],
      sellPrice: {
        amount: 699
      },
      seller: {
        __typename: "Seller",
        id: "1",
        name: "Catch"
      }
    },
    options: [],
    sellable: true,
    slug: "3-x-palmolive-naturals-soap-gold-4pk-90g-216178",
    priceTag: {
      priceDiscount: {
        label: "Price drop",
        percentageLabel: "30% off",
        endDate: "2022-01-01T12:23:15+10:00"
      },
      topLabel: "From",
      priceLabel: {
        wasPrice: {
          amount: 2999
        },
        price: {
          amount: 1999
        },
        saving: {
          label: "Save",
          savingAmount: {
            amount: 1000
          }
        }
      },
      clubSellPrice: {
        amount: 14999
      },
      clubExclusive: false,
      enabled: true,
      retailPriceDisclaimer: {
        description: "Limited time only"
      },
      __typename: "PriceTag"
    },
    adult: false
  };
};
export var mockRecommendedProducts = [{
  __typename: "Product",
  id: "216178",
  title: "3 x Palmolive Naturals Soap Gold 4pk 90g",
  adult: false,
  ratingsSummary: {
    __typename: "RatingsSummary",
    numberOfRatings: 75,
    averageRating: 4.5
  },
  assets: {
    __typename: "ProductAssets",
    hero: {
      __typename: "Image",
      url: "https://static.devcat.ch/images/product/0000/0818/5773408789f26095525527.jpg",
      altText: "3 x Palmolive Naturals Soap Gold 4pk 90g"
    }
  },
  brand: {
    __typename: "Brand",
    name: "Palmolive",
    slug: "palmolive"
  },
  bestOffer: {
    __typename: "Offer",
    id: "84782",
    clubExclusive: false,
    clubFreeShipping: true,
    freeShipping: false,
    badges: [],
    sellPrice: {
      amount: 699
    },
    seller: {
      __typename: "Seller",
      id: "1",
      name: "Catch"
    }
  },
  options: [],
  sellable: true,
  slug: "3-x-palmolive-naturals-soap-gold-4pk-90g-216178",
  priceTag: {
    priceDiscount: {
      label: "Price drop",
      percentageLabel: "30% off",
      endDate: "2022-01-01T12:23:15+10:00"
    },
    topLabel: "From",
    priceLabel: {
      wasPrice: {
        amount: 2999
      },
      price: {
        amount: 1999
      },
      saving: {
        label: "Save",
        savingAmount: {
          amount: 1000
        }
      }
    },
    clubSellPrice: {
      amount: 14999
    },
    clubExclusive: false,
    enabled: true,
    retailPriceDisclaimer: {
      description: "Limited time only"
    }
  }
}];
export var mockRecommendedProductItemType = {
  adult: false,
  almostGoneLimit: 5,
  averageRating: 0,
  category: "Groceries & Liquor",
  clubCatchEligible: true,
  eventId: 1,
  freeShippingAvailable: false,
  images: [{
    name: "368379_1.jpg",
    sources: [{
      descriptor: "100w",
      height: 81,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w100.jpg",
      width: 100
    }, {
      descriptor: "200w",
      height: 163,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w200.jpg",
      width: 200
    }, {
      descriptor: "300w",
      height: 245,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w300.jpg",
      width: 300
    }, {
      descriptor: "400w",
      height: 327,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w400.jpg",
      width: 400
    }, {
      descriptor: "500w",
      height: 409,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w500.jpg",
      width: 500
    }, {
      descriptor: "600w",
      height: 490,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w600.jpg",
      width: 600
    }, {
      descriptor: "700w",
      height: 572,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w700.jpg",
      width: 700
    }, {
      descriptor: "800w",
      height: 654,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w800.jpg",
      width: 800
    }, {
      descriptor: "900w",
      height: 736,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w900.jpg",
      width: 900
    }, {
      descriptor: "1000w",
      height: 818,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w1000.jpg",
      width: 1000
    }, {
      descriptor: "1100w",
      height: 900,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w1100.jpg",
      width: 1100
    }, {
      descriptor: "1200w",
      height: 981,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w1200.jpg",
      width: 1200
    }, {
      descriptor: "1300w",
      height: 1063,
      mimeType: "image/jpeg",
      src: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634_w1300.jpg",
      width: 1300
    }],
    type: "product"
  }],
  isPersonalisedProduct: false,
  productPath: "/product/3-x-harpic-active-fresh-hygienic-toilet-block-citrus-40g-368379/",
  productSkus: [],
  productUrl: "",
  purchaseLimit: 5,
  ratingCount: 3,
  selectedOfferId: 321677,
  selectedOfferPrice: 6,
  selectedOfferQuantity: 0,
  selectedOfferSellerId: 1,
  sellable: true,
  sourceId: "desksdfsdfsdfdf",
  sourcePlatform: 1,
  sourceType: 33,
  subCategory: "Household",
  title: "3 x Harpic Active Fresh Hygienic Toilet Block Citrus 40g",
  url: "/product/3-x-harpic-active-fresh-hygienic-toilet-block-citrus-40g-368379/?sid=desksdfsdfsdfdf&st=33",
  brand: {
    name: "Harpic",
    slug: "harpic/"
  },
  imageLocation: "https://static.devcat.ch/images/product/0000/0000/56554a69c62c3472926634.jpg",
  id: "368379",
  offerId: "321677",
  sellerId: "1",
  priceTag: {
    priceDiscount: null,
    topLabel: "Don't pay $12.23",
    priceLabel: {
      wasPrice: null,
      price: {
        amount: 600
      },
      saving: {
        label: "Save",
        savingAmount: {
          amount: 600
        }
      }
    },
    clubSellPrice: null,
    clubExclusive: false,
    enabled: true,
    retailPriceDisclaimer: null
  },
  badges: [],
  isProductItem: true
};
export var mockFromProduct = {
  from_product_ids: [123540],
  from_product_offer_id: 1354354,
  from_product_displayed_price: 10.0
};