import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import debounce from "lodash.debounce";
import useSWR from "swr";
import { Box, Button, Flex, IconCross, NoSSR, Text } from "@catchoftheday/cg-components";
import { useMedia } from "@catchoftheday/theme";
import { fetcher } from "../../api/index";
import { HEADER_COUPONS_API } from "../../consts";
import { sessionStorage } from "../../utilities";
import theme from "../theme";
import { CouponDetailsModal } from "./CouponDetailsModal/CouponDetailsModal";
import { trackCouponClick, trackCouponImpression } from "./utilities/analytics";
import { jsx as ___EmotionJSX } from "@emotion/react";
var STORAGE_KEY = "couponNotificationVisible";
export var CouponNotification = function CouponNotification(_ref) {
  var displayName = _ref.displayName;
  var hasTrackedImpression = useRef(false);

  var _useState = useState(true),
      isVisible = _useState[0],
      setIsVisible = _useState[1];

  var _useState2 = useState(false),
      isCouponModalOpen = _useState2[0],
      setIsCouponModalOpen = _useState2[1];

  var isDesktop = useMedia(["(min-width: ".concat(theme.breakpoints.lg, ")")], [true]);
  var debouncedTrackCouponClick = debounce(trackCouponClick, 500);

  var _useSWR = useSWR(isDesktop && isVisible ? HEADER_COUPONS_API : null, fetcher),
      customerCouponsData = _useSWR.data;

  var activeCoupon = useMemo(function () {
    var _customerCouponsData$, _customerCouponsData$2;

    if (!customerCouponsData || customerCouponsData && !((_customerCouponsData$ = customerCouponsData.data) !== null && _customerCouponsData$ !== void 0 && (_customerCouponsData$2 = _customerCouponsData$.coupons) !== null && _customerCouponsData$2 !== void 0 && _customerCouponsData$2.length)) {
      return null;
    }

    return _objectSpread(_objectSpread({}, customerCouponsData.data.coupons[0]), {}, {
      customerId: customerCouponsData.data.customerId
    });
  }, [customerCouponsData]);

  var handleCloseButtonClick = function handleCloseButtonClick() {
    if (sessionStorage) {
      sessionStorage.setItem(STORAGE_KEY, "false");
    }

    setIsVisible(false);
  };

  var handleCouponCodeClick = useCallback(function () {
    if (activeCoupon) {
      debouncedTrackCouponClick({
        couponData: activeCoupon,
        customerId: activeCoupon.customerId
      });
    }
  }, [activeCoupon, debouncedTrackCouponClick]);
  var toggleCouponModal = useCallback(function () {
    if (activeCoupon && !isCouponModalOpen) {
      debouncedTrackCouponClick({
        couponData: activeCoupon,
        customerId: activeCoupon.customerId
      });
    }

    setIsCouponModalOpen(function (prevState) {
      return !prevState;
    });
  }, [activeCoupon, debouncedTrackCouponClick, isCouponModalOpen]);
  useEffect(function () {
    if (sessionStorage) {
      var isNotificationVisible = sessionStorage.getItem(STORAGE_KEY) === "false" ? false : true;
      setIsVisible(isNotificationVisible);
    }
  }, []);
  useEffect(function () {
    if (activeCoupon && !hasTrackedImpression.current) {
      trackCouponImpression({
        couponData: activeCoupon,
        customerId: activeCoupon.customerId
      });
      hasTrackedImpression.current = true;
    }
  }, [activeCoupon]);

  if (!activeCoupon || !isDesktop || !isVisible || !activeCoupon.code.length) {
    return null;
  }

  return ___EmotionJSX(NoSSR, null, ___EmotionJSX(Box, {
    role: "alert",
    position: "relative",
    width: "100%",
    py: "10px",
    bg: "#F9EBBD",
    fontSize: "14px",
    textAlign: "center"
  }, ___EmotionJSX(Flex, {
    justifyContent: "center"
  }, ___EmotionJSX(Text, {
    as: "div",
    mr: "3px"
  }, displayName ? "Hey ".concat(displayName, ",") : "Hey!", " "), activeCoupon.salesRule.shortDescription && ___EmotionJSX(Text, {
    as: "div",
    mr: "3px",
    textTransform: "lowercase"
  }, activeCoupon.salesRule.shortDescription, " |", " "), ___EmotionJSX(Box, {
    mr: "3px",
    fontWeight: "medium",
    cursor: "text",
    onClick: handleCouponCodeClick
  }, "USE CODE: ".concat(activeCoupon.code)), ___EmotionJSX(Button, {
    variant: "plain",
    p: "0px",
    fontSize: "14px",
    border: "0px",
    minWidth: {
      xs: "auto"
    },
    onClick: toggleCouponModal
  }, ___EmotionJSX(Text, {
    lineHeight: "1.4",
    m: "0px",
    textTransform: "capitalize",
    textDecoration: "underline"
  }, "Details"))), ___EmotionJSX(Button, {
    variant: "plain",
    position: "absolute",
    right: "18px",
    top: "calc(50% - 6px)",
    lineHeight: "12px",
    border: "0px",
    p: "0px",
    minWidth: {
      xs: "0px"
    },
    onClick: handleCloseButtonClick
  }, ___EmotionJSX(IconCross, {
    width: "12px",
    height: "12px"
  }))), ___EmotionJSX(CouponDetailsModal, _extends({
    isOpen: isCouponModalOpen,
    displayName: displayName,
    handleModalClose: toggleCouponModal
  }, activeCoupon)));
};