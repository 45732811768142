import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
var _excluded = ["idPath", "onItemClick"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext, useMemo } from "react";
import { Box, List } from "@catchoftheday/cg-components";
import { SHOP_BY_BRAND_ID } from "../../../consts";
import { MenuItem } from "../../../MenuItem";
import { MobileMenuContext } from "../../../MobileMenuContext";
import { getCategoriesMenu } from "../CategoriesMenu";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var getSubCategoriesMenu = function getSubCategoriesMenu(transformedShop) {
  if (!(transformedShop !== null && transformedShop !== void 0 && transformedShop.shopNavigationGroups)) {
    return undefined;
  }

  var subcategoriesMenu = transformedShop.topBrands ? [].concat(_toConsumableArray(transformedShop.shopNavigationGroups), [{
    id: SHOP_BY_BRAND_ID,
    slug: "",
    url: "",
    name: "Shop by Brand",
    shopNavigationItems: transformedShop.topBrands
  }]) : transformedShop.shopNavigationGroups;
  return subcategoriesMenu.map(function (menuItem, index) {
    return _objectSpread(_objectSpread({}, menuItem), {}, {
      // Start from 1 and account for the Shop All link
      position: index + 2
    });
  });
};
export var SubCategoriesMenu = function SubCategoriesMenu(_ref) {
  var idPath = _ref.idPath,
      onItemClick = _ref.onItemClick,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useContext = useContext(MobileMenuContext),
      _useContext$shops = _useContext.shops,
      shops = _useContext$shops === void 0 ? [] : _useContext$shops;

  var activeCategory = useMemo(function () {
    return getCategoriesMenu(shops).find(function (_ref2) {
      var id = _ref2.id;
      return id === idPath[0];
    });
  }, [shops, idPath]);
  var subCategories = useMemo(function () {
    return getSubCategoriesMenu(activeCategory);
  }, [activeCategory]);

  if (!subCategories || !activeCategory) {
    return null;
  }

  return ___EmotionJSX(Box, _extends({
    as: "nav",
    overflowY: "scroll"
  }, props), ___EmotionJSX(List, {
    my: "0px"
  }, ___EmotionJSX(MenuItem, {
    key: "Shop All ".concat(activeCategory.name, "-0"),
    url: activeCategory.url,
    hasChildren: false,
    itemPath: [{
      name: activeCategory.name,
      id: activeCategory.id,
      position: activeCategory.position
    }, {
      name: "Shop All ".concat(activeCategory.name),
      id: activeCategory.id,
      position: 1
    }],
    shopAll: true
  }, "Shop All ".concat(activeCategory.name)), subCategories.map(function (menuItem, index) {
    return ___EmotionJSX(MenuItem, {
      key: "".concat(menuItem.name, "-").concat(index + 1),
      url: menuItem.url,
      hasChildren: menuItem.shopNavigationItems.length > 0,
      itemPath: [{
        name: activeCategory.name,
        id: activeCategory.id,
        position: activeCategory.position
      }, {
        name: menuItem.name,
        id: menuItem.id === SHOP_BY_BRAND_ID ? activeCategory.id : menuItem.id,
        position: menuItem.position
      }],
      onClick: function onClick() {
        return onItemClick([].concat(_toConsumableArray(idPath), [menuItem.id]));
      }
    }, menuItem.name);
  })));
};