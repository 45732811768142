import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useEffect, useRef, useState } from "react";
import { useUID } from "@catchoftheday/utilities";
import { Box } from "../Box";
import { Input } from "../Input";
import { List } from "../List";
import { ListItem } from "../ListItem";
import { Popover, PopoverContent, PopoverControl } from "../Popover";
import { defaultRenderItem, ensureHighlightedItemIsVisible } from "./utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var InputDropdown = function InputDropdown(_ref) {
  var _inputProps$id;

  var _ref$data = _ref.data,
      data = _ref$data === void 0 ? [] : _ref$data,
      inputProps = _ref.inputProps,
      dropdownContainerProps = _ref.dropdownContainerProps,
      _ref$autoHighlightFir = _ref.autoHighlightFirstItem,
      autoHighlightFirstItem = _ref$autoHighlightFir === void 0 ? false : _ref$autoHighlightFir,
      defaultValue = _ref.defaultValue,
      _ref$clearInputOnFocu = _ref.clearInputOnFocus,
      clearInputOnFocus = _ref$clearInputOnFocu === void 0 ? false : _ref$clearInputOnFocu,
      _ref$renderListItem = _ref.renderListItem,
      renderListItem = _ref$renderListItem === void 0 ? defaultRenderItem : _ref$renderListItem,
      onInputChange = _ref.onInputChange,
      onItemHighLighted = _ref.onItemHighLighted,
      onItemSelected = _ref.onItemSelected,
      onItemHover = _ref.onItemHover,
      onEnterKeyPressed = _ref.onEnterKeyPressed,
      onInputFocus = _ref.onInputFocus,
      _onBlur = _ref.onBlur,
      onDropdownClose = _ref.onDropdownClose;

  var _uid = useUID();

  var inputId = (_inputProps$id = inputProps === null || inputProps === void 0 ? void 0 : inputProps.id) !== null && _inputProps$id !== void 0 ? _inputProps$id : _uid; // State

  var _useState = useState(false),
      isOpen = _useState[0],
      setIsOpen = _useState[1];

  var _useState2 = useState(""),
      inputTerm = _useState2[0],
      setInputTerm = _useState2[1];

  var _useState3 = useState(false),
      isItemMouseover = _useState3[0],
      setIsItemMouseOver = _useState3[1];

  var _useState4 = useState(-1),
      highlightedIdx = _useState4[0],
      setHighlightedIdx = _useState4[1];

  var highlightedItem = data === null || data === void 0 ? void 0 : data[highlightedIdx]; // Refs

  var itemContainerRef = useRef(null);
  var itemRef = useRef(null);

  function updateHighlightedIndex(_ref2) {
    var index = _ref2.index,
        _ref2$changeOnHighlig = _ref2.changeOnHighlight,
        changeOnHighlight = _ref2$changeOnHighlig === void 0 ? true : _ref2$changeOnHighlig;
    setHighlightedIdx(index);
    var highlightedItem = data === null || data === void 0 ? void 0 : data[index];

    if (highlightedItem && !(highlightedItem !== null && highlightedItem !== void 0 && highlightedItem.unclickable) && changeOnHighlight) {
      setInputTerm(highlightedItem.title);

      if (typeof onItemHighLighted === "function") {
        onItemHighLighted(highlightedItem);
      }

      if (typeof onInputChange === "function") {
        onInputChange(highlightedItem.title, {
          onChange: false,
          onHighlighted: true
        });
      }
    }
  }

  useEffect(function () {
    if (defaultValue) {
      setInputTerm(defaultValue);
    }
  }, [defaultValue]); // Event handlers

  function openPopover() {
    setIsOpen(true);
  }

  function closePopover() {
    if (typeof onDropdownClose === "function") {
      onDropdownClose();
    }

    setIsOpen(false);
  }

  function onListItemClick(itemData) {
    if (itemData !== null && itemData !== void 0 && itemData.unclickable) {
      return;
    }

    if (itemData) {
      setInputTerm(itemData.title);
    }

    if (typeof onItemSelected === "function") {
      onItemSelected(itemData);
    }

    closePopover();
  }

  function onInputKeyDown(event) {
    if (!isOpen) {
      openPopover();
    }

    if (event.key === "ArrowDown") {
      updateHighlightedIndex({
        index: highlightedIdx >= data.length - 1 ? 0 : highlightedIdx + 1
      });
    } else if (event.key === "ArrowUp") {
      updateHighlightedIndex({
        index: highlightedIdx === -1 || highlightedIdx === 0 ? data.length - 1 : highlightedIdx - 1
      });
    } else if (event.key === "Escape") {
      updateHighlightedIndex({
        index: -1
      });
      closePopover();
    } else if (event.key === "Enter") {
      if (typeof onEnterKeyPressed === "function") {
        onEnterKeyPressed(event);
      }

      if (data && highlightedIdx !== -1) {
        onListItemClick(highlightedItem);
      } else {
        onListItemClick(undefined);
      }
    }
  }

  function handleItemHover(hovered) {
    setIsItemMouseOver(hovered);

    if (typeof onItemHover === "function") {
      onItemHover(hovered);
    }
  }

  useEffect(function () {
    ensureHighlightedItemIsVisible({
      itemRef: itemRef,
      itemContainerRef: itemContainerRef
    });
  }, [highlightedIdx]);
  var showData = data && data.length;
  return ___EmotionJSX(Popover, {
    isOpen: isOpen,
    fullWidth: true,
    hideArrow: true,
    hideOnClickOutside: true,
    flexGrow: 1,
    role: "combobox",
    "aria-haspopup": "listbox",
    zIndex: 3,
    outsideClickHandlerProps: {
      onOutsideClick: closePopover
    }
  }, ___EmotionJSX(PopoverControl, null, function (_ref3) {
    var ref = _ref3.ref,
        isOpen = _ref3.isOpen;
    var dropdownOpenProps = showData && isOpen ? {
      borderRadius: "default",
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0
    } : {};
    return ___EmotionJSX(Input, _extends({
      ref: ref,
      onChange: function onChange(event) {
        var inputValue = event.currentTarget.value;
        setInputTerm(inputValue);
        updateHighlightedIndex({
          index: autoHighlightFirstItem ? 0 : -1,
          changeOnHighlight: false
        });

        if (!isOpen) {
          openPopover();
        }

        if (typeof onInputChange === "function") {
          onInputChange(inputValue, {
            onChange: true,
            onHighlighted: false
          });
        }
      },
      onBlur: function onBlur(e) {
        if (clearInputOnFocus && defaultValue) {
          setInputTerm(defaultValue);
        }

        if (!isItemMouseover) {
          closePopover();

          if (typeof _onBlur === "function") {
            _onBlur(e);
          }
        }
      },
      value: inputTerm,
      onFocus: function onFocus(e) {
        openPopover();

        if (clearInputOnFocus) {
          setInputTerm("");
        }

        if (typeof onInputFocus === "function") {
          onInputFocus(e);
        }
      },
      onKeyDown: function onKeyDown(event) {
        return onInputKeyDown(event);
      },
      autoComplete: "off"
    }, dropdownOpenProps, inputProps, {
      id: inputId
    }));
  }), ___EmotionJSX(PopoverContent, null, showData ? ___EmotionJSX(Box, _extends({
    ref: itemContainerRef,
    border: "1px solid",
    borderColor: "ui.borderColor",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    backgroundColor: "white",
    borderTop: "none",
    maxHeight: "200px",
    overflow: "auto"
  }, dropdownContainerProps), ___EmotionJSX(List, {
    margin: 0,
    type: "none",
    role: "listbox",
    "aria-labelledby": inputId
  }, data.map(function (itemData, idx) {
    var highlighted = highlightedIdx === idx;
    var props = {
      onClick: function onClick() {
        return onListItemClick(itemData);
      },
      role: "option",
      highlighted: highlighted,
      "aria-selected": highlighted,
      ref: highlighted ? itemRef : null,
      "data-suggestion-index": idx
    };
    return ___EmotionJSX(ListItem, _extends({
      mb: "0",
      key: idx
    }, props, {
      onFocus: openPopover,
      onMouseOver: function onMouseOver() {
        return handleItemHover(true);
      },
      onMouseLeave: function onMouseLeave() {
        return handleItemHover(false);
      }
    }), renderListItem({
      itemData: itemData,
      isHighlighted: highlighted
    }));
  }))) : null));
};
InputDropdown.displayName = "InputDropdown";
export default InputDropdown;