import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useState } from "react";
import { Box, Flex, IconFlybuys, Link, LinkButton, NotificationBadge, Text } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { HeaderTile } from "../../../components/HeaderTile";
import { SpanWithAfterDownArrow } from "../../../components/SpanWithAfterDownArrow";
import { sessionStorage } from "../../../utilities/index";
import flybuysTheme from "../../flybuysTheme";
import { HeaderTileContainer } from "../HeaderTileContainer";
import { jsx as ___EmotionJSX } from "@emotion/react";
var LinkFlybuysButton = styled(LinkButton)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transform: translateY(-50%);\n"])));
var SESSION_STORAGE_KEY = "flyBuysTileHover";
export var FlybuysTile = function FlybuysTile() {
  var flyBuysTileHoverSessionStorage = (sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem(SESSION_STORAGE_KEY)) != "true";

  var _useState = useState(true),
      showNotificationBadge = _useState[0],
      setShowNotificationBadge = _useState[1];

  var renderFlybuysContent = function renderFlybuysContent() {
    return ___EmotionJSX(Flex, {
      width: "400px",
      flexDirection: "column",
      color: "typography.text",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.50)",
      bg: "white"
    }, ___EmotionJSX(Flex, {
      position: "relative",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      p: "1em 1em 2.2em",
      color: "white",
      overflow: "hidden",
      backgroundColor: "secondary"
    }, ___EmotionJSX(Text, {
      textStyle: "h3",
      m: "0",
      textAlign: "center"
    }, "Deals, meet points"), ___EmotionJSX(Text, {
      m: "0",
      textAlign: "center"
    }, "Collect 1 point per $2 spent with Catch", ___EmotionJSX("sup", null, "*"))), ___EmotionJSX(Flex, {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }, ___EmotionJSX(LinkFlybuysButton, {
      block: false,
      sizeVariant: "sm",
      variant: "primary",
      minWidth: "170px",
      mb: "-2em",
      textTransform: "none",
      href: "/rewards/flybuys/link",
      "data-tracking": "header-app-flybuys-link-flybuys"
    }, "LINK flybuys"), ___EmotionJSX(Flex, {
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
      width: "100%",
      p: "15px 15px 5px 15px"
    }, ___EmotionJSX(Link, {
      href: "/flybuys-catch",
      "data-tracking": "header-app-flybuys-learn-more"
    }, "Learn More")), ___EmotionJSX(Text, {
      textStyle: "small",
      p: "5px 15px 15px 15px"
    }, ___EmotionJSX("sup", null, "*"), " Terms, conditions and exclusions apply")));
  };

  var renderIcon = function renderIcon(_ref) {
    var isOpen = _ref.isOpen;
    var iconStyleOnHover = {
      svg: {
        fill: flybuysTheme.bgColor
      }
    };
    var iconStyle = {
      "&:hover": iconStyleOnHover,
      svg: isOpen ? iconStyleOnHover.svg : undefined
    };
    return ___EmotionJSX(HeaderTileContainer, {
      hasRightBorder: true,
      linkProps: {
        css: iconStyle,
        backgroundColor: isOpen ? "white" : undefined,
        color: isOpen ? "secondary" : "inherit",
        href: "/rewards/flybuys/link",
        "aria-label": "flybuys",
        className: "js-dnt",
        "data-tracking": "header-app-flybuys"
      },
      flexProps: {
        px: "0.875rem"
      }
    }, ___EmotionJSX(Flex, {
      flexDirection: "column",
      alignItems: "center"
    }, ___EmotionJSX(Box, {
      position: "relative",
      title: "Flybuys"
    }, flyBuysTileHoverSessionStorage && showNotificationBadge && ___EmotionJSX(NotificationBadge, {
      text: "1",
      "data-testid": "flybuys-new",
      absRight: "-18px"
    }), ___EmotionJSX(IconFlybuys, {
      width: "65px",
      height: "34px",
      title: "Flybuys icon"
    })), ___EmotionJSX(SpanWithAfterDownArrow, null, "$2 = 1 point")));
  };

  return ___EmotionJSX(HeaderTile, {
    popoverProps: {
      placement: "bottom"
    },
    icon: renderIcon,
    onTileShow: function onTileShow(isOpen) {
      if (isOpen) {
        sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.setItem(SESSION_STORAGE_KEY, "true");
        setShowNotificationBadge(false);
      }
    }
  }, renderFlybuysContent());
};
export default FlybuysTile;