import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "loading"];
import React from "react";
import { LoadingSpinner, Modal } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var OnePassModal = function OnePassModal(_ref) {
  var children = _ref.children,
      loading = _ref.loading,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Modal, _extends({
    sizeVariant: "medium",
    height: {
      sm: "auto"
    },
    borderRadius: "corner",
    closeButtonProps: {
      bg: "transparent",
      width: "36px",
      height: "36px",
      color: "text",
      p: 0,
      mt: 6,
      mr: 2
    },
    closeIconProps: {
      width: "16px",
      height: "16px"
    },
    contentProps: {
      height: "auto",
      px: 6,
      py: 6
    },
    enableDefaultResponsiveStyles: false,
    maxHeight: "95%"
  }, props), loading && ___EmotionJSX(LoadingSpinner, {
    containerProps: {
      "aria-label": "loading spinner",
      zIndex: 1
    },
    hasOverlay: true,
    ringBg: "ui.borderColor"
  }), children);
};