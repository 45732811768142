import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isFreeTrialEligible", "showSecondaryCTA", "mainCTAHref", "onMainCTAClick", "onLearnMoreClick", "isLoggedIn", "isResponsiveFontSize", "hideMainPricingUI", "logoContainerFontSize"];
import React from "react";
import { Box, IconOnePass, Link, LinkButton, Span, Text } from "@catchoftheday/cg-components";
import { rem } from "@catchoftheday/theme";
import { formatPrice } from "@catchoftheday/utilities";
import { ONEPASS_MONTHLY_PRICE, ONEPASS_YEARLY_PRICE, URL_FLYBUYS_CATCH, URL_ONEPASS_LANDING, URL_ONEPASS_TERMS_AND_CONDITIONS } from "../../../consts";
import { CTAButton } from "../CTAButton";
import { OnePassIconsGroup } from "../OnePassIconsGroup";
import { UpsellSecondaryCTA } from "../UpsellSecondaryCTA";
import { Benefits } from "./Benefits";
import { Pricing } from "./Pricing";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Content = function Content(_ref) {
  var isFreeTrialEligible = _ref.isFreeTrialEligible,
      showSecondaryCTA = _ref.showSecondaryCTA,
      mainCTAHref = _ref.mainCTAHref,
      onMainCTAClick = _ref.onMainCTAClick,
      onLearnMoreClick = _ref.onLearnMoreClick,
      isLoggedIn = _ref.isLoggedIn,
      isResponsiveFontSize = _ref.isResponsiveFontSize,
      hideMainPricingUI = _ref.hideMainPricingUI,
      logoContainerFontSize = _ref.logoContainerFontSize,
      secondaryCTAProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(IconOnePass, {
    height: rem("32px"),
    width: "auto",
    color: "onePass.primary",
    "aria-label": "OnePass",
    mb: 4
  }), ___EmotionJSX(Text, {
    fontSize: isResponsiveFontSize ? {
      xs: "xs",
      md: "base"
    } : "xs",
    fontWeight: "medium",
    color: "text",
    textAlign: "center",
    mt: 0,
    mb: 3
  }, "More across Kmart, Target, Catch, Bunnings Warehouse and Officeworks"), ___EmotionJSX(Benefits, {
    isReponsiveFontSize: isResponsiveFontSize
  }), ___EmotionJSX(Box, {
    role: "separator",
    width: rem("24px"),
    height: 1,
    backgroundColor: "darkGrey"
  }), ___EmotionJSX(Box, {
    mt: 3,
    mb: hideMainPricingUI ? 0 : 3
  }, ___EmotionJSX(Text, {
    m: 0,
    fontSize: isResponsiveFontSize ? {
      xs: "xs",
      md: "sm"
    } : "xs",
    color: "onePass.textGrey",
    fontWeight: "semibold",
    textAlign: "center"
  }, "Catch exclusive"), ___EmotionJSX(Text, {
    fontSize: isResponsiveFontSize ? {
      xs: "xs",
      md: "sm"
    } : "xs",
    m: 0,
    color: "onePass.textGrey",
    fontWeight: "normal",
    textAlign: "center"
  }, ___EmotionJSX(Span, {
    fontSize: "inherit",
    color: "textSecondary",
    fontWeight: "semibold"
  }, "Collect 2 Flybuys points"), " ", ___EmotionJSX(Span, {
    fontSize: "inherit",
    fontWeight: "inherit",
    whiteSpace: "nowrap"
  }, "for every $2 spent online*"))), !hideMainPricingUI && ___EmotionJSX(Pricing, null), ___EmotionJSX(LinkButton, {
    my: 3,
    href: mainCTAHref,
    variant: "onePassPrimary",
    sizeVariant: isResponsiveFontSize ? {
      xs: "sm",
      md: "md"
    } : "sm" // NOTE: have to do this since setting responsive sizeVariant overrides the fontweight
    ,
    fontWeight: {
      xs: "semibold",
      md: "semibold"
    },
    textTransform: "uppercase",
    whiteSpace: "wrap",
    maxWidth: "245px",
    rounded: true,
    onClick: onMainCTAClick
  }, isFreeTrialEligible ? "Start your free trial" : "Join OnePass today"), ___EmotionJSX(Text, {
    fontSize: "xs",
    fontWeight: "normal",
    textAlign: "center",
    color: "textSecondary",
    mb: 1,
    mt: 0
  }, isFreeTrialEligible ? "Try it free, cancel anytime." : "Cancel anytime.", " ", ___EmotionJSX(CTAButton, {
    fontWeight: "semibold",
    fontSize: "inherit",
    href: URL_ONEPASS_LANDING,
    target: "_blank",
    onClick: onLearnMoreClick
  }, "Learn more")), showSecondaryCTA && ___EmotionJSX(UpsellSecondaryCTA, _extends({
    tight: true,
    isLoggedIn: isLoggedIn
  }, secondaryCTAProps)), hideMainPricingUI && ___EmotionJSX(Text, {
    fontSize: "xs",
    fontWeight: "normal",
    textAlign: "center",
    color: "onePass.textGrey",
    mt: 2,
    mb: 0
  }, isFreeTrialEligible && "After your free trial, ", "OnePass is only ", formatPrice(ONEPASS_MONTHLY_PRICE, false), "/month or", " ", formatPrice(ONEPASS_YEARLY_PRICE, false), "/year"), ___EmotionJSX(Box, {
    role: "separator",
    height: 1,
    alignSelf: "stretch",
    backgroundColor: "ui.borderColor",
    mt: 3
  }), ___EmotionJSX(OnePassIconsGroup, {
    fontSize: logoContainerFontSize,
    my: 6
  }), ___EmotionJSX(Text, {
    color: "onePass.textGrey",
    fontWeight: "normal",
    fontSize: "xs",
    m: 0,
    textAlign: "center"
  }, ___EmotionJSX(Link, {
    color: "inherit",
    textDecoration: "underline",
    fontWeight: "semibold",
    href: URL_ONEPASS_TERMS_AND_CONDITIONS
  }, "*T&Cs"), ", Exclusions apply. Express click & collect already available to all customers at Officeworks & Target.", " ", ___EmotionJSX(Link, {
    color: "inherit",
    textDecoration: "underline",
    href: URL_FLYBUYS_CATCH
  }, "Alternative Flybuys offer for Catch.")));
};