import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["onePassPriceMonthly", "appStoreLink", "googlePlayLink"];
import React from "react";
import { Box, Flex, IconOnePass } from "@catchoftheday/cg-components";
import { AppTile } from "./AppTile";
import { BannerTile } from "./BannerTile";
import { IconCatchCollect } from "./IconCatchCollect";
import { IconCoinHand } from "./IconCoinHand";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CatchAppBanner = function CatchAppBanner(_ref) {
  var onePassPriceMonthly = _ref.onePassPriceMonthly,
      appStoreLink = _ref.appStoreLink,
      googlePlayLink = _ref.googlePlayLink,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Box, _extends({
    display: "flex",
    borderRadius: "1px",
    backgroundColor: "white",
    borderColor: "ui.borderColor",
    border: 1,
    flexDirection: {
      xs: "column",
      lg: "row"
    },
    "data-testid": "banner-catchAppBanner"
  }, boxProps), ___EmotionJSX(Flex, {
    height: "100%",
    width: {
      xs: "100%",
      lg: "70%"
    },
    alignItems: {
      xs: "center",
      lg: "flex-start"
    },
    justifyContent: "center",
    flexDirection: {
      xs: "column",
      lg: "row"
    },
    p: 4
  }, ___EmotionJSX(BannerTile, {
    isOnePass: true,
    icon: ___EmotionJSX(IconOnePass, {
      width: "100px",
      height: "auto",
      color: {
        xs: "white",
        lg: "onePass.primary"
      },
      title: "OnePass"
    }),
    desc: "Free delivery on eligible products for ".concat(onePassPriceMonthly, " a month! Cancel anytime")
  }), ___EmotionJSX(BannerTile, {
    icon: ___EmotionJSX(IconCatchCollect, {
      color: "brand.primary",
      height: {
        xs: "24px",
        md: "32px",
        lg: "40px"
      },
      width: "auto",
      title: "Catch Collect"
    }),
    desc: "Click and collect at over 400 Kmart, K-hub & Target locations"
  }), ___EmotionJSX(BannerTile, {
    icon: ___EmotionJSX(IconCoinHand, {
      color: "brand.primary",
      height: "44px",
      width: "auto",
      title: "Amazing Prices"
    }),
    desc: "Amazing prices on 1.5 million products - and counting!"
  })), ___EmotionJSX(AppTile, {
    appStoreLink: appStoreLink,
    googlePlayLink: googlePlayLink
  }));
};