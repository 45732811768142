import React from "react";
import { DisneyPlusBoxZone, DisneyPlusContentZone, DisneyPlusSideImageZone } from "../../layouts/DisneyPlusBundleLayout/DisneyPlusBundleLayout";
import { Disclaimer, DisneyPlusBundleContent } from "../../pure/DisneyPlusBundleContent";
import { DisneyPlusBundleBoxSideImage } from "../../pure/image-assets/DisneyPlusBundleBoxSideImage";
import { OnePassXDisneyPlusIcon } from "../../pure/OnePassXDisneyPlusIcon";
import { SubscriptionSectionHeading } from "../../pure/SubscriptionSectionHeading";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var DisneyPlusBundleContainer = function DisneyPlusBundleContainer(_ref) {
  var isFreeTrialEligible = _ref.isFreeTrialEligible,
      isLapsed = _ref.isLapsed;
  var ctaText = isFreeTrialEligible ? "Start free trial" : isLapsed ? "Get the bundle" : "Upgrade now";
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(SubscriptionSectionHeading, {
    mt: 8
  }, "Want more?"), ___EmotionJSX(DisneyPlusBoxZone, null, ___EmotionJSX(DisneyPlusSideImageZone, null, ___EmotionJSX(DisneyPlusBundleBoxSideImage, null)), ___EmotionJSX(DisneyPlusContentZone, null, ___EmotionJSX(OnePassXDisneyPlusIcon, null), ___EmotionJSX(DisneyPlusBundleContent, {
    ctaText: ctaText
  }))), ___EmotionJSX(Disclaimer, null));
};