import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useContext, useRef } from "react";
import { AppExperimentContext, emitRecommenderRequest } from "@catchoftheday/analytics";
import { useRecentlyViewedProductsQuery } from "@personalisation/generated/graphql";
import { RecommenderCarousel } from "../RecommenderCarousel";
import { getProductTiles, getRecommenderTrackingData, handleRecommenderQueryUpdate, useVisibleRecommenderTiles } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var RecentlyViewedProducts = function RecentlyViewedProducts(_ref) {
  var sourceType = _ref.sourceType,
      sourceId = _ref.sourceId,
      carouselWrapperProps = _ref.carouselWrapperProps,
      Wrapper = _ref.Wrapper;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["recently_viewed_recommender_title_july_2022"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      experiment = _getExperiments$exper[0],
      experimentLoading = _getExperiments.loading;

  var visibleTiles = useVisibleRecommenderTiles();
  var trackingVariables = useRef({
    offset: 0,
    limit: visibleTiles
  });

  var _useRecentlyViewedPro = useRecentlyViewedProductsQuery({
    variables: {
      limit: visibleTiles,
      offset: 0,
      excludePurchased: true,
      maxDays: 45
    },
    skip: !visibleTiles,
    ssr: false,
    onCompleted: function onCompleted(_ref2) {
      var _ref2$recentlyViewed$ = _ref2.recentlyViewed.metaData,
          preHydrationCount = _ref2$recentlyViewed$.preHydrationCount,
          postHydrationCount = _ref2$recentlyViewed$.postHydrationCount,
          hydrationErrorCount = _ref2$recentlyViewed$.hydrationErrorCount;
      emitRecommenderRequest({
        source_type: sourceType,
        pre_hydration_product_count: preHydrationCount,
        post_hydration_product_count: postHydrationCount,
        hydration_error_count: hydrationErrorCount,
        page_offset: trackingVariables.current.offset,
        page_limit: trackingVariables.current.limit,
        from_product_ids: null,
        from_product_displayed_price: null,
        from_product_offer_id: null
      });
    }
  }),
      data = _useRecentlyViewedPro.data,
      loading = _useRecentlyViewedPro.loading,
      error = _useRecentlyViewedPro.error,
      fetchMore = _useRecentlyViewedPro.fetchMore;

  var heading = (experiment === null || experiment === void 0 ? void 0 : experiment.bucket) === 1 ? "Pick Up Where You Left Off" : (experiment === null || experiment === void 0 ? void 0 : experiment.bucket) === 2 ? "Continue Browsing" : "Recently Viewed";

  if (!(data !== null && data !== void 0 && data.recentlyViewed.products.length) || error || experimentLoading) {
    return null;
  }

  var products = data.recentlyViewed.products;
  var _data$recentlyViewed$ = data.recentlyViewed.pagination,
      totalCount = _data$recentlyViewed$.totalCount,
      nextItemIndex = _data$recentlyViewed$.nextItemIndex;
  var fromProduct = {
    from_product_ids: null,
    from_product_displayed_price: null,
    from_product_offer_id: null
  };
  var tiles = getProductTiles({
    products: products,
    sourceType: sourceType,
    fromProduct: fromProduct
  });
  var trackingData = getRecommenderTrackingData(products, "product");
  var WrapperComponent = Wrapper !== null && Wrapper !== void 0 ? Wrapper : React.Fragment;
  return ___EmotionJSX(WrapperComponent, null, ___EmotionJSX(RecommenderCarousel, {
    tiles: tiles,
    heading: heading,
    wrapperProps: carouselWrapperProps,
    sourceType: sourceType,
    sourceId: sourceId,
    trackingData: trackingData,
    fromProduct: fromProduct,
    paginate: true,
    maxTiles: totalCount,
    carouselProps: {
      buttonOffset: [-10, 0],
      onSlideChange: function onSlideChange(_ref3) {
        var currentIndex = _ref3.currentIndex,
            slidesPerView = _ref3.slidesPerView,
            direction = _ref3.direction;
        var lastVisibleTile = currentIndex + slidesPerView - 1;

        if (direction === "forward" && !products[lastVisibleTile]) {
          trackingVariables.current = {
            offset: nextItemIndex,
            limit: slidesPerView
          };
          fetchMore({
            variables: {
              offset: nextItemIndex
            },
            updateQuery: function updateQuery(prevResult, _ref4) {
              var fetchMoreResult = _ref4.fetchMoreResult;
              return handleRecommenderQueryUpdate({
                prevResult: prevResult,
                fetchMoreResult: fetchMoreResult,
                recommenderKey: "recentlyViewed"
              });
            }
          });
        }
      },
      disableNavButtons: {
        next: loading
      },
      touchEnabled: !loading
    }
  }));
};