import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
import React from "react";
import { Span } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AvailabilityTag = function AvailabilityTag(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  if (!children) {
    return null;
  }

  return ___EmotionJSX(Span, _extends({
    textTransform: "uppercase",
    color: "#FFF",
    lineHeight: "1",
    display: "inline-block",
    fontWeight: "semibold",
    marginLeft: "6px",
    padding: "4px 5px",
    textAlign: "center",
    marginBottom: {
      xs: "6px",
      sm: "5px"
    },
    fontSize: "2xs"
  }, props), children);
};