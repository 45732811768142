import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["children", "hasRightBorder", "flexProps", "linkProps"];

var _templateObject, _templateObject2;

import React from "react";
import { Flex, Link } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var FlexContainer = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return props.hasRightBorder && "border-right: 1px solid white;";
});
var LinkContainer = styled(Link)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  height: 100%;\n  &:hover,\n  &:focus {\n    color: ", ";\n    background-color: white;\n    text-decoration: underline;\n  }\n"])), themeGet("colors.secondaryDark"));
export var HeaderTileContainer = function HeaderTileContainer(_ref) {
  var children = _ref.children,
      hasRightBorder = _ref.hasRightBorder,
      flexProps = _ref.flexProps,
      linkProps = _ref.linkProps,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(LinkContainer, _extends({}, linkProps, props), ___EmotionJSX(FlexContainer, _extends({
    hasRightBorder: hasRightBorder,
    height: "100%",
    alignItems: "center"
  }, flexProps), children));
};
HeaderTileContainer.defaultProps = {
  hasRightBorder: true
};
export default HeaderTileContainer;