import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["data", "inputProps", "onItemHighLighted", "onItemSelected", "dropdownContainerProps", "onSearchButtonClicked", "onInputFocus", "flexContainerProps", "isLoading"];

var _templateObject;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { styled, themeGet, useMedia, useTheme } from "@catchoftheday/theme";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { IconSearch } from "../Icon";
import { InputDropdown } from "../InputDropdown";
import { LoadingSpinner } from "../LoadingSpinner";
import { jsx as ___EmotionJSX } from "@emotion/react";
var StyledIconButton = styled(Button)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &:active {\n    box-shadow: ", ";\n  }\n"])), themeGet("shadows.button"));
export var SearchBar = function SearchBar(_ref) {
  var _ref$data = _ref.data,
      data = _ref$data === void 0 ? [] : _ref$data,
      inputProps = _ref.inputProps,
      onItemHighLighted = _ref.onItemHighLighted,
      onItemSelected = _ref.onItemSelected,
      dropdownContainerProps = _ref.dropdownContainerProps,
      onSearchButtonClicked = _ref.onSearchButtonClicked,
      onInputFocus = _ref.onInputFocus,
      flexContainerProps = _ref.flexContainerProps,
      isLoading = _ref.isLoading,
      props = _objectWithoutProperties(_ref, _excluded);

  var theme = useTheme();
  var isDesktop = useMedia(["(min-width: ".concat(theme.breakpoints.md, ")")], [true]);
  return ___EmotionJSX(Flex, flexContainerProps, ___EmotionJSX(Flex, {
    flexGrow: 1,
    position: "relative"
  }, ___EmotionJSX(InputDropdown, _extends({
    data: data,
    onItemHighLighted: onItemHighLighted,
    onItemSelected: onItemSelected,
    inputProps: _objectSpread({
      border: 1,
      borderColor: "ui.borderColor",
      borderRadius: "4px 0px 0px 4px",
      fontWeight: "light",
      p: 0,
      px: {
        sm: "10px",
        md: "26px"
      },
      "aria-label": "Search",
      placeholder: !isDesktop ? "Search" : "Search for products and brands...",
      height: "47px",
      focusBorderColor: "tertiary",
      id: "searchbar"
    }, inputProps),
    dropdownContainerProps: _objectSpread({
      maxHeight: "250px",
      overflow: "auto",
      boxShadow: "dropDown"
    }, dropdownContainerProps)
  }, props, {
    onInputFocus: onInputFocus
  })), isLoading && ___EmotionJSX(LoadingSpinner, {
    "data-testid": "search-loading-spinner",
    position: "absolute",
    width: {
      xs: "20px",
      md: "25px"
    },
    height: {
      xs: "20px",
      md: "25px"
    },
    right: {
      xs: "5px",
      md: "10px"
    },
    top: {
      xs: "8px",
      md: "12px"
    },
    ringSize: "3px"
  })), ___EmotionJSX(StyledIconButton, {
    "aria-label": "Search",
    sizeVariant: "sm",
    variant: "tertiary",
    outline: true,
    bg: "white",
    border: "1px solid",
    borderColor: "ui.borderColor",
    borderLeft: "none",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: "47px",
    py: {
      sm: "0px",
      md: "12px"
    },
    onClick: onSearchButtonClicked
  }, ___EmotionJSX(IconSearch, {
    size: 25
  })));
};
SearchBar.displayName = "SearchBar";
export default SearchBar;