import React from "react";
import { Flex, IconTick, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NotificationToastContent = function NotificationToastContent(_ref) {
  var message = _ref.message;
  return ___EmotionJSX(Flex, {
    color: "primary",
    alignItems: "center"
  }, ___EmotionJSX(IconTick, {
    mr: "10px",
    width: "20px",
    "aria-hidden": "true"
  }), ___EmotionJSX(Text, {
    mb: "0",
    mt: "0",
    fontWeight: "bold"
  }, message));
};