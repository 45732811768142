import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import CountUp from "react-countup";
import { Flex, IconCart, IconClock, IconEye, IconFlame, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
var countUpProps = {
  useEasing: true,
  preserveValue: true,
  duration: 3
};
export var SocialUrgency = function SocialUrgency(_ref) {
  var _ref$bordered = _ref.bordered,
      bordered = _ref$bordered === void 0 ? true : _ref$bordered,
      stats = _ref.stats,
      containerProps = _ref.containerProps;

  if (!stats) {
    return null;
  }

  var quantityRemaining = stats.quantityRemaining,
      quantitySold = stats.quantitySold,
      viewerCount = stats.viewerCount,
      viewerEffect = stats.viewerEffect;

  if (!quantityRemaining && !viewerCount && !quantitySold) {
    return null;
  }

  return ___EmotionJSX(Flex, _extends({
    m: {
      xs: "20px 0 0",
      lg: "0 0 10px"
    },
    justifyContent: {
      xs: "center",
      lg: "flex-start"
    }
  }, containerProps), quantityRemaining && ___EmotionJSX(Flex, {
    mr: 2,
    border: bordered ? "1px solid" : "none",
    borderColor: "secondary",
    height: "24px",
    alignItems: "center",
    px: bordered ? 3 : 0
  }, ___EmotionJSX(IconClock, {
    title: "Clock Icon",
    role: "img",
    width: "auto",
    height: "16px",
    color: "secondary"
  }), ___EmotionJSX(Text, {
    m: 0,
    ml: "4px",
    fontSize: "12px",
    color: "secondary"
  }, ___EmotionJSX(CountUp, _extends({
    prefix: "HURRY! ",
    end: quantityRemaining,
    suffix: " LEFT"
  }, countUpProps)))), viewerCount && ___EmotionJSX(Flex, {
    mr: 2,
    color: viewerEffect === "HOT" ? "error" : "secondary",
    border: bordered ? "1px solid" : "none",
    borderColor: "currentColor",
    height: "24px",
    alignItems: "center",
    px: bordered ? 3 : 0
  }, viewerEffect === "HOT" ? ___EmotionJSX(IconFlame, {
    title: "Flame Icon",
    role: "img",
    width: "auto",
    height: "16px",
    color: "currentColor"
  }) : ___EmotionJSX(IconEye, {
    title: "Eye Icon",
    role: "img",
    width: "auto",
    height: "16px",
    color: "currentColor"
  }), ___EmotionJSX(Text, {
    m: 0,
    ml: "4px",
    fontSize: "12px",
    color: "currentColor"
  }, ___EmotionJSX(CountUp, _extends({
    end: viewerCount,
    suffix: " currently viewing"
  }, countUpProps)))), quantitySold && ___EmotionJSX(Flex, {
    mr: 2,
    border: bordered ? "1px solid" : "none",
    borderColor: "secondary",
    height: "24px",
    alignItems: "center",
    px: bordered ? 3 : 0
  }, ___EmotionJSX(IconCart, {
    title: "Cart Icon",
    role: "img",
    width: "auto",
    height: "16px",
    color: "secondary"
  }), ___EmotionJSX(Text, {
    m: 0,
    ml: "4px",
    fontSize: "12px",
    color: "secondary"
  }, ___EmotionJSX(CountUp, _extends({
    end: quantitySold,
    suffix: " bought so far"
  }, countUpProps)))));
};