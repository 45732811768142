import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Icon } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconFlame = function IconFlame(props) {
  return ___EmotionJSX(Icon, _extends({
    fill: "currentColor",
    viewBox: "0 0 94 126"
  }, props), ___EmotionJSX("path", {
    d: "M0.518555 73.6971C1.95898 55.0447 10.626 43.3748 18.292 33.0721C25.3721 23.5017 31.5 15.2742 31.5 3.116C31.5 2.13944 32.0371 1.23612 32.916 0.796664C33.7949 0.357211 34.8203 0.430454 35.626 0.991977C47.125 9.21952 56.7197 23.0867 60.0645 36.3191C62.3838 45.5232 62.7012 55.8992 62.75 62.7351C73.3701 60.4646 75.7871 44.5711 75.8115 44.4002C75.9336 43.5701 76.4463 42.8621 77.1787 42.4715C77.9111 42.0808 78.79 42.0564 79.5469 42.4226C80.1084 42.6912 93.2188 49.3562 93.9756 75.9676C94 76.8465 94 77.7498 94 78.6287C94 104.483 72.9795 125.504 47.125 125.504C47.1006 125.504 47.0518 125.504 47.0273 125.504H47.0029C21.1973 125.43 0.25 104.434 0.25 78.6287C0.25 77.3347 0.518555 73.6971 0.518555 73.6971ZM47.125 120.303C58.3066 120.303 67.4131 110.611 67.4131 98.6971C67.4131 98.282 67.4131 97.8914 67.3887 97.3787C67.2422 92.3494 66.29 88.9314 65.2646 86.6365C63.3115 90.8601 59.7959 94.7176 54.1074 94.7176C52.2275 94.7176 50.7383 93.2039 50.7383 91.3484C50.7383 86.5388 50.6406 80.9724 49.4443 75.9676C48.3701 71.5242 45.8311 66.7879 42.6084 63.0037C41.168 67.9109 38.3848 71.9148 35.6504 75.7967C31.7441 81.3631 27.6914 87.1004 26.9834 96.9148C26.9346 97.5008 26.8857 98.0867 26.8857 98.6971C26.8369 110.611 35.9434 120.303 47.125 120.303Z"
  }));
};