import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { IconSVG } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconCoinHand = function IconCoinHand(props) {
  return ___EmotionJSX(IconSVG, _extends({
    viewBox: "0 0 60 45",
    fill: "none",
    paths: [{
      d: "M59.1647 18.5546C57.459 17.325 53.2653 18.5203 51.3959 19.9121C49.8062 21.0644 45.0668 24.0121 42.4028 25.643C42.0357 24.7589 41.1681 24.204 40.2265 24.2633L39.3732 24.2626C35.6363 24.3498 26.8827 24.5382 19.6164 22.6755C12.7914 20.9029 9.53741 24.5557 8.46226 26.2027C8.22664 26.5321 7.88808 26.7865 7.5055 26.8911L0.736705 28.7737C0.236417 28.8932 -0.0728878 29.3873 0.029727 29.8967C0.0884011 30.1364 0.220656 30.3462 0.441236 30.4812C0.647104 30.6162 0.897172 30.6613 1.1326 30.6015C1.16203 30.6015 1.19146 30.5866 1.2209 30.5716L7.93083 28.704C8.74014 28.4799 9.44669 27.9561 9.93269 27.2524C11.2729 25.261 13.9814 23.1657 19.1148 24.5176C26.9106 26.5306 36.2823 26.2527 39.3571 26.1651L40.2104 26.1657C40.5046 26.1659 40.7253 26.211 40.7396 26.7503C40.7828 28.0536 39.2067 30.674 35.3668 30.836C30.0704 31.0568 24.3771 30.888 22.7441 30.8269L22.6558 30.8268C22.391 30.7966 22.1409 30.9013 21.9642 31.0809C21.7875 31.2606 21.6844 31.5002 21.6842 31.7548L21.6835 32.6986L35.4096 32.7235C39.4113 32.5466 41.8406 30.0767 42.416 27.8301C42.7398 27.6356 43.2843 27.2914 43.9614 26.8725C46.5224 25.2864 50.8201 22.6381 52.4099 21.4858C54.1026 20.2586 57.3102 19.6168 58.0455 20.1416C58.0749 20.1566 58.119 20.2016 58.0452 20.4712C57.4109 22.7777 42.1151 36.9527 32.9912 40.5862C27.1048 42.9339 16.7201 40.3648 11.0573 38.623C10.0277 38.2927 8.9389 38.4567 8.04105 39.0552L3.18383 42.2724C2.75698 42.5567 2.63886 43.1409 2.91808 43.5755C3.09442 43.8453 3.37384 43.9953 3.68279 43.9955L3.6975 43.9955C3.87404 43.9956 4.05061 43.9508 4.19781 43.8461L9.05506 40.5839C9.48191 40.2996 10.0116 40.2251 10.5117 40.3753C15.189 41.8617 26.7943 45.0158 33.6667 42.2944C37.6106 40.7244 43.6012 36.744 49.7103 31.6103C55.3189 26.9106 59.3824 22.6143 59.8397 20.9369C60.2081 20.0833 59.9146 19.0944 59.1647 18.5546Z",
      props: {
        fill: "currentColor"
      }
    }, {
      d: "M38.7985 9.82451C38.795 14.7005 34.6063 18.7317 29.3398 18.7279C24.0732 18.7241 19.8904 14.6868 19.8939 9.81085C19.8974 4.93488 24.086 0.903673 29.3526 0.907476C34.6192 0.91128 38.802 4.94853 38.7985 9.82451Z",
      props: {
        stroke: "currentColor",
        strokeWidth: "1.8"
      }
    }, {
      d: "M32.5225 11.9648C32.5249 8.64161 28.2509 9.578 28.2522 7.72709C28.2526 7.16621 28.6606 6.75986 29.4477 6.76043C29.8385 6.76071 30.1704 6.87829 30.4228 7.12594C30.7731 7.46965 31.2525 7.77331 31.7112 7.59896C32.1034 7.44993 32.3353 7.01929 32.1204 6.65901C31.8366 6.18337 31.3992 5.82163 30.8447 5.60509C30.5256 5.48053 30.2782 5.19536 30.2785 4.8529C30.2788 4.45857 29.9593 4.13867 29.565 4.13838L29.5268 4.13836C29.1426 4.13808 28.8309 4.44931 28.8306 4.83352C28.8304 5.1758 28.5771 5.45794 28.2532 5.56885C27.1985 5.93009 26.6223 6.77299 26.6215 7.85211C26.6192 10.965 30.8651 10.0426 30.8637 12.0057C30.8632 12.6647 30.371 13.071 29.5416 13.0704C28.9701 13.07 28.5066 12.8717 28.1869 12.4969C27.8701 12.1256 27.3842 11.8335 26.9279 12.0069C26.5367 12.1556 26.3099 12.585 26.5096 12.9528C26.8753 13.6266 27.4706 14.0904 28.2139 14.3182C28.5399 14.4181 28.7954 14.6988 28.7951 15.0398C28.7949 15.4155 29.0992 15.7203 29.4749 15.7206L29.5304 15.7206C29.9237 15.7209 30.2428 15.4023 30.243 15.009C30.2433 14.6659 30.492 14.3801 30.8155 14.2659C31.8993 13.8835 32.5217 13.0484 32.5225 11.9648Z",
      props: {
        fill: "currentColor"
      }
    }]
  }, props));
};