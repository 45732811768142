import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

/**
 * Global Message Banner
 * Watches GlobalAlert for messages and displays them.
 */
import React, { useContext } from "react";
import { Alert, Box, INLINE_ALERT_VARIANT_MAP } from "@catchoftheday/cg-components";
import { GlobalAlertContext } from "@catchoftheday/common-vendors";
import { useTheme } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function GlobalAlertBanner() {
  var _appTheme$maxWidths$c;

  var globalAlertMap = useContext(GlobalAlertContext);
  var appTheme = useTheme();
  var maxWidth = (_appTheme$maxWidths$c = appTheme.maxWidths.cotdWebsite) !== null && _appTheme$maxWidths$c !== void 0 ? _appTheme$maxWidths$c : appTheme.maxWidths["default"]; // For some reason cotdWebsite isn't always set.

  if (!globalAlertMap) return null; // No context, nothing to do.

  return ___EmotionJSX(React.Fragment, null, Array.from(globalAlertMap.entries()).map(function (_ref) {
    var _props$theme, _props$variant;

    var _ref2 = _slicedToArray(_ref, 2),
        id = _ref2[0],
        _ref2$ = _ref2[1],
        props = _ref2$.props,
        children = _ref2$.children;

    var theme = (_props$theme = props.theme) !== null && _props$theme !== void 0 ? _props$theme : "light";
    var variant = (_props$variant = props.variant) !== null && _props$variant !== void 0 ? _props$variant : "default";
    var backgroundColor = INLINE_ALERT_VARIANT_MAP[variant].theme[theme].backgroundColor;
    return ___EmotionJSX(Box, {
      id: id,
      key: id,
      backgroundColor: backgroundColor
    }, ___EmotionJSX(Box, {
      maxWidth: maxWidth,
      margin: "auto",
      px: 2
    }, ___EmotionJSX(Alert, _extends({}, props, {
      type: "inline"
    }), children)));
  }));
}