import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box, Button, LinkButton } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
var commonButtonProps = {
  width: {
    xs: "100%",
    md: "auto"
  }
};
export var CTA = function CTA(_ref) {
  var eventUrl = _ref.eventUrl,
      isSingleProductEvent = _ref.isSingleProductEvent,
      isActiveMember = _ref.isActiveMember,
      isSoldOut = _ref.isSoldOut,
      onPrimaryClick = _ref.onPrimaryClick;
  return ___EmotionJSX(Box, {
    mt: 4,
    textAlign: {
      xs: "center",
      md: "left"
    },
    width: "100%"
  }, !isSingleProductEvent ? ___EmotionJSX(LinkButton, _extends({
    href: eventUrl,
    variant: "onePassPrimary"
  }, commonButtonProps), "View Deal of the Week") : ___EmotionJSX(Box, null, ___EmotionJSX(Button, _extends({
    variant: isSoldOut ? "error" : "onePassPrimary",
    mr: {
      xs: 0,
      md: 3
    },
    mb: {
      xs: 3,
      md: 0
    },
    disabled: isSoldOut,
    onClick: isSoldOut ? undefined : onPrimaryClick
  }, commonButtonProps), isSoldOut ? "Sold Out" : isActiveMember ? "Add to Cart" : "Join OnePass to Access"), ___EmotionJSX(LinkButton, _extends({
    href: eventUrl,
    variant: "onePassPrimary",
    outline: true
  }, commonButtonProps), "View Deal")));
};