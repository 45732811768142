import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
import React from "react";
import { Heading } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SubscriptionSectionHeading = function SubscriptionSectionHeading(_ref) {
  var children = _ref.children,
      headingProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Heading.h2, _extends({
    fontSize: {
      xs: "xl",
      md: "2xl"
    },
    color: "onePass.textPrimary",
    m: 0,
    mb: 4,
    fontWeight: "bold"
  }, headingProps), children);
};