import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Link } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CTAButton = function CTAButton(props) {
  return ___EmotionJSX(Link, _extends({
    fontSize: "xs",
    fontWeight: "bold",
    color: "onePass.primary",
    textDecoration: "underline"
  }, !props.href && {
    role: "button"
  }, props));
};