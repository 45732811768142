import _toConsumableArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var handleRecommenderQueryUpdate = function handleRecommenderQueryUpdate(_ref) {
  var prevResult = _ref.prevResult,
      fetchMoreResult = _ref.fetchMoreResult,
      recommenderKey = _ref.recommenderKey;

  if (!fetchMoreResult) {
    return prevResult;
  }

  var prevData = prevResult[recommenderKey];
  var fetchMoreData = fetchMoreResult[recommenderKey];
  return _defineProperty({}, recommenderKey, _objectSpread(_objectSpread(_objectSpread({}, prevData), fetchMoreData), {}, {
    products: [].concat(_toConsumableArray(prevData.products), _toConsumableArray(fetchMoreData.products.filter(function (product) {
      return !prevData.products.map(function (recommendation) {
        return recommendation.id;
      }).includes(product.id);
    })))
  }));
};