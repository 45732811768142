import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["event", "isActiveMember", "isOnePassExclusive", "onCardSeen", "onCardClick", "onCTAClick"];
import React, { useCallback, useState } from "react";
import { Box, ImageResponsive, Link, Text } from "@catchoftheday/cg-components";
import { useIsInViewport } from "@catchoftheday/utilities";
import { getEventTitles } from "../../..//utilities";
import { Card } from "./Card";
import { EventCardCTA } from "./EventCardCTA";
import { Rating } from "./Rating";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var OnePassEventCard = function OnePassEventCard(_ref) {
  var _product$ratingsSumma, _product$ratingsSumma2;

  var event = _ref.event,
      isActiveMember = _ref.isActiveMember,
      isOnePassExclusive = _ref.isOnePassExclusive,
      onCardSeen = _ref.onCardSeen,
      onCardClick = _ref.onCardClick,
      onCTAClick = _ref.onCTAClick,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  var _useState = useState(false),
      isCardHovered = _useState[0],
      setIsCardHovered = _useState[1];

  var _ref2 = event || {},
      image = _ref2.image,
      url = _ref2.url,
      product = _ref2.product;

  var isSoldOut = product && !product.sellable;
  var rating = product === null || product === void 0 ? void 0 : (_product$ratingsSumma = product.ratingsSummary) === null || _product$ratingsSumma === void 0 ? void 0 : _product$ratingsSumma.averageRating;
  var ratingCount = product === null || product === void 0 ? void 0 : (_product$ratingsSumma2 = product.ratingsSummary) === null || _product$ratingsSumma2 === void 0 ? void 0 : _product$ratingsSumma2.numberOfRatings;

  var handleCardMouseOver = function handleCardMouseOver() {
    var hovered = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    setIsCardHovered(hovered);
  };

  var handleEventImpression = useCallback(function () {
    onCardSeen && onCardSeen();
  }, [onCardSeen]);

  var _useIsInViewport = useIsInViewport({
    threshold: 1,
    onComponentSeen: handleEventImpression
  }),
      cardRef = _useIsInViewport.ref;

  var _getEventTitles = getEventTitles(event),
      title = _getEventTitles.title,
      subtitle = _getEventTitles.subtitle;

  return ___EmotionJSX(Card, _extends({
    ref: cardRef,
    border: 1,
    borderColor: "grey",
    borderRadius: "default",
    "aria-label": title,
    role: "listitem",
    onMouseEnter: function onMouseEnter() {
      return handleCardMouseOver(true);
    },
    onMouseLeave: function onMouseLeave() {
      return handleCardMouseOver(false);
    },
    onClick: onCardClick
  }, boxProps), ___EmotionJSX(Link, {
    href: url,
    title: title,
    "aria-label": title,
    display: "block",
    position: "relative"
  }, ___EmotionJSX(ImageResponsive, {
    src: image === null || image === void 0 ? void 0 : image.single.url,
    alt: (image === null || image === void 0 ? void 0 : image.single.altText) || title,
    width: "100%",
    height: "auto",
    ratio: 66
  }), isSoldOut && ___EmotionJSX(Box, {
    backgroundColor: "error",
    position: "absolute",
    right: 0,
    bottom: "16px",
    py: 2,
    px: 4,
    color: "white",
    fontSize: "sm"
  }, "SOLD OUT"), !!product && ___EmotionJSX(EventCardCTA, {
    isSoldOut: !!isSoldOut,
    visible: isCardHovered,
    isActiveMember: isActiveMember,
    onCTAClick: onCTAClick
  })), ___EmotionJSX(Box, {
    py: 3,
    px: 5,
    backgroundColor: "white",
    borderTop: "3px solid",
    height: "105px",
    borderTopColor: isSoldOut ? "error" : isOnePassExclusive ? "onePass.primary" : "grey"
  }, ___EmotionJSX(Link, {
    href: url,
    title: title,
    "aria-label": title,
    display: "block",
    hoverTextDecoration: "none",
    hoverColor: "initial"
  }, ___EmotionJSX(Text, {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: {
      xs: "base",
      lg: "lg"
    },
    fontWeight: "normal",
    color: "text",
    m: 0
  }, title), ___EmotionJSX(Text, {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: {
      xs: "xs",
      lg: "base"
    },
    fontWeight: "normal",
    color: "textTertiary",
    m: 0
  }, subtitle), !!ratingCount && ratingCount > 0 && rating && ___EmotionJSX(Rating, {
    rating: rating,
    ratingCount: ratingCount
  }))));
};