import React from "react";
import { Text } from "@catchoftheday/cg-components";
import { date } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Disclaimer = function Disclaimer() {
  var thisYear = date.format(new Date(), "yyyy");
  return ___EmotionJSX(Text, {
    m: 0,
    fontSize: "2xs",
    color: "darkGrey",
    textAlign: {
      xs: "center",
      lg: "left"
    }
  }, "*Eligible items or orders. Exclusions, T&Cs apply ", ___EmotionJSX("sup", null, "^"), "Savings compared to separately purchasing OnePass and Disney+ monthly subscriptions. \xA9 ", thisYear, " Disney and related entities \xA9 OnePass", " ", thisYear, ".");
};