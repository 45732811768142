import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Badge = function Badge(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  if (!children) {
    return null;
  }

  return ___EmotionJSX(Box, _extends({
    as: "span",
    textTransform: "uppercase",
    color: "#FFF",
    lineHeight: "1",
    display: "inline-block",
    fontWeight: "bold",
    padding: "6px 8px",
    textAlign: "center",
    fontSize: "xs",
    borderRadius: "2px",
    border: "solid 1px transparent",
    boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.15)"
  }, props), children);
};