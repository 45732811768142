import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["hasOverlay", "overlayBackgroundColor", "containerProps"];

var _templateObject, _templateObject2, _templateObject3;

import React from "react";
import { keyframes, styled, system } from "@catchoftheday/theme";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { jsx as ___EmotionJSX } from "@emotion/react";
var spin = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n"])));
var mainRingProps = system({
  ringColor: {
    property: "borderTopColor",
    scale: "colors"
  },
  ringSize: {
    properties: ["borderWidth", "borderTopWidth"]
  }
});
var ringBgColorProps = system({
  ringBg: {
    property: "borderColor",
    scale: "colors"
  }
});
var overlayBgColorProps = system({
  overlayBackgroundColor: {
    property: "backgroundColor",
    scale: "colors"
  }
});
var animationDurationProps = system({
  animationDuration: {
    property: "animationDuration"
  }
});
var Spinner = styled(Box)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  border-style: solid;\n  border-top-style: solid;\n  border-radius: 50%;\n  animation: ", " linear infinite;\n  ", "\n"])), ringBgColorProps, mainRingProps, spin, animationDurationProps);
var Overlay = styled(Flex)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  justify-content: center;\n  align-items: center;\n  ", "\n"])), overlayBgColorProps);
export var LoadingSpinner = function LoadingSpinner(_ref) {
  var hasOverlay = _ref.hasOverlay,
      overlayBackgroundColor = _ref.overlayBackgroundColor,
      containerProps = _ref.containerProps,
      spinnerProps = _objectWithoutProperties(_ref, _excluded);

  if (hasOverlay) {
    return ___EmotionJSX(Overlay, _extends({
      overlayBackgroundColor: overlayBackgroundColor
    }, containerProps), ___EmotionJSX(Spinner, _extends({}, spinnerProps, {
      role: "progressbar"
    })));
  }

  return ___EmotionJSX(Spinner, spinnerProps);
};
LoadingSpinner.defaultProps = {
  ringSize: "5px",
  animationDuration: ".75s",
  ringColor: "secondary",
  ringBg: "ui.backgroundColor",
  width: "40px",
  height: "40px",
  hasOverlay: false,
  overlayBackgroundColor: "rgba(255, 255, 255, 0.7)"
};
LoadingSpinner.displayName = "LoadingSpinner";
export default LoadingSpinner;