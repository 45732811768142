import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box, ContentPlaceHolder, Flex } from "@catchoftheday/cg-components";
import { MiniProductCardPlaceholder } from "@catchoftheday/product-components";
import { jsx as ___EmotionJSX } from "@emotion/react";

var PlaceholderHeading = function PlaceholderHeading() {
  return ___EmotionJSX(Box, {
    my: "20px"
  }, ___EmotionJSX(ContentPlaceHolder, {
    width: 160,
    height: 20,
    viewBox: "0 0 160 20",
    uniqueKey: "heading",
    speed: 2,
    backgroundColor: "#f3f3f3",
    foregroundColor: "#ecebeb"
  }, ___EmotionJSX("rect", {
    x: "0",
    y: "0",
    rx: "3",
    ry: "3",
    width: "160",
    height: "20"
  })));
};

var cardProps = {
  mb: "20px",
  width: {
    xs: "100%",
    md: "50%"
  }
};
export var ProductCardPlaceholder = function ProductCardPlaceholder() {
  return ___EmotionJSX(Flex, {
    flexDirection: "column"
  }, ___EmotionJSX(PlaceholderHeading, null), ___EmotionJSX(Flex, {
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center"
  }, ___EmotionJSX(MiniProductCardPlaceholder, _extends({
    uniqueKey: "card1"
  }, cardProps)), ___EmotionJSX(MiniProductCardPlaceholder, _extends({
    uniqueKey: "card2"
  }, cardProps)), ___EmotionJSX(MiniProductCardPlaceholder, _extends({
    uniqueKey: "card3"
  }, cardProps, {
    display: {
      xs: "none",
      md: "block"
    }
  })), ___EmotionJSX(MiniProductCardPlaceholder, _extends({
    uniqueKey: "card4"
  }, cardProps, {
    display: {
      xs: "none",
      md: "block"
    }
  }))));
};