import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { emitEventClick, EVENT_RECOMMENDER_EVENT_TYPE } from "@catchoftheday/analytics";
import { EventCard } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var getEventTiles = function getEventTiles(_ref) {
  var events = _ref.events,
      sourceType = _ref.sourceType,
      fromProduct = _ref.fromProduct;

  var handleEventClick = function handleEventClick(_ref2) {
    var event = _ref2.event,
        position = _ref2.position;
    emitEventClick(_objectSpread({
      title: event.title,
      subtitle: null,
      source_type: sourceType,
      badge_text: event.badgeText,
      event_id: Number(event.id),
      position: position,
      event_type: EVENT_RECOMMENDER_EVENT_TYPE,
      schedule_entry_id: 0,
      click_target: event !== null && event !== void 0 && event.product ? "product" : "event"
    }, fromProduct));
  };

  return events.map(function (event, index) {
    var id = event.id,
        title = event.title,
        badgeText = event.badgeText,
        url = event.url,
        single = event.image.single;
    return ___EmotionJSX(EventCard, {
      key: id,
      title: title,
      badgeText: badgeText,
      url: url,
      image: {
        url: single.url,
        altText: single.altText
      },
      mx: {
        xs: "2px",
        md: "10px"
      },
      my: "2px",
      onClick: function onClick() {
        return handleEventClick({
          event: event,
          position: index + 1
        });
      }
    });
  });
};