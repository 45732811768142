import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import React from "react";
import { Box, Button, Flex, IconFacebook, IconInstagram, IconTikTok, IconTwitter, ImageResponsive, Link, Text } from "@catchoftheday/cg-components";
import { DEFAULT_APP_DOWNLOAD_LINK } from "@catchoftheday/common-vendors";
import { css, minusOnePx, styled, theme, useMedia } from "@catchoftheday/theme";
import { LinkImage } from "../IESupportingImageLink";
import { jsx as ___EmotionJSX } from "@emotion/react";

function switchSite() {
  return isMobileSite() ? location.href.replace("m.catch", "www.catch") : location.href.replace("www.catch", "m.catch");
}

function isMobileSite() {
  if (typeof location === "undefined") {
    return null;
  }

  return location.host.includes("m.catch");
}

export var SiteTypeButton = function SiteTypeButton() {
  return ___EmotionJSX(Button, {
    variant: "plain",
    fontSize: "sm",
    textTransform: "none",
    color: "secondary",
    fontWeight: "semibold",
    onClick: function onClick() {
      return location.assign(switchSite());
    }
  }, isMobileSite() ? "View Desktop Site ›" : "View Mobile Site ›");
};
var SocialContainer = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  align-items: center;\n\n  & > a {\n    margin-left: 6px;\n    margin-right: 6px;\n    height: 30px;\n    width: 30px;\n    background: black;\n    border-radius: 50%;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    text-decoration: none;\n\n    svg {\n      color: white;\n    }\n  }\n"])));
export var IconLinks = function IconLinks(_ref) {
  var _appStoreLink, _googlePlayLink;

  var appDownloadLinks = _ref.appDownloadLinks;
  var appStoreLink, googlePlayLink;

  if (appDownloadLinks && appDownloadLinks.length > 0) {
    appStoreLink = appDownloadLinks.find(function (link) {
      return link.name === "appStore";
    });
    googlePlayLink = appDownloadLinks.find(function (link) {
      return link.name === "googlePlay";
    });
  }

  var isMobile = useMedia(["(max-width: ".concat(minusOnePx(theme.breakpoints.md, true), ")")], [true]);
  return ___EmotionJSX(Box, {
    paddingRight: "20px",
    paddingLeft: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: {
      xs: "column",
      md: "row"
    },
    marginTop: "30px",
    width: "100%"
  }, isMobile ? ___EmotionJSX(Flex, {
    height: "60px",
    alignItems: "center"
  }, ___EmotionJSX(LinkImage, {
    href: ((_appStoreLink = appStoreLink) === null || _appStoreLink === void 0 ? void 0 : _appStoreLink.url) || DEFAULT_APP_DOWNLOAD_LINK,
    title: "Download app from App Store",
    src: "https://s.catch.com.au/static/catch_mobile/images/sprites/normal/app-icon--ios.png",
    alt: "App Store Logo",
    width: "120px",
    height: "40px",
    marginRight: "20px"
  }), ___EmotionJSX(LinkImage, {
    href: ((_googlePlayLink = googlePlayLink) === null || _googlePlayLink === void 0 ? void 0 : _googlePlayLink.url) || DEFAULT_APP_DOWNLOAD_LINK,
    title: "Download app from Google Play",
    src: "https://s.catch.com.au/static/catch_mobile/images/sprites/normal/app-icon--android.png",
    alt: "Play Store Logo",
    width: "135px",
    height: "40px",
    marginRight: "20px"
  })) : ___EmotionJSX(Flex, {
    height: "100px",
    alignItems: "center"
  }, ___EmotionJSX(Box, {
    width: "100px",
    height: "100px",
    mr: "16px"
  }, ___EmotionJSX(ImageResponsive, {
    src: "https://s.catch.com.au/assets/0000/0973/60b9ae8faf321792202603.jpg",
    sourceWidths: [100, 200, 300, 400],
    width: "100",
    height: "100",
    alt: "Catch App QR Code",
    ratio: 100,
    lazyLoad: true
  })), ___EmotionJSX(Box, null, ___EmotionJSX(Box, null, ___EmotionJSX(Text, {
    fontWeight: "semibold",
    color: "secondary",
    textTransform: "uppercase",
    mt: "0",
    mb: "0"
  }, "Even Better on the App")), ___EmotionJSX(Box, null, "Scan this code to download the Catch app!"))), ___EmotionJSX(SocialContainer, null, ___EmotionJSX(Link, {
    css: css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n            &:hover {\n              background-color: #39599b;\n            }\n          "]))),
    hoverColor: "white",
    target: "_blank",
    rel: "noopener noreferrer",
    href: "https://www.facebook.com/CatchAustralia",
    title: "Facebook"
  }, ___EmotionJSX(IconFacebook, {
    width: 16,
    height: 16
  })), ___EmotionJSX(Link, {
    css: css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n            &:hover {\n              background-color: #1da1f2;\n            }\n          "]))),
    hoverColor: "white",
    target: "_blank",
    rel: "noopener noreferrer",
    href: "https://twitter.com/catchaustralia",
    title: "X"
  }, ___EmotionJSX(IconTwitter, {
    width: 16,
    height: 16
  })), ___EmotionJSX(Link, {
    css: css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n            &:hover {\n              background: linear-gradient(-135deg, #1400c8, #b900b4, #f50000);\n            }\n          "]))),
    hoverColor: "white",
    target: "_blank",
    rel: "noopener noreferrer",
    href: "https://www.instagram.com/catchaustralia",
    title: "Instagram"
  }, ___EmotionJSX(IconInstagram, {
    width: 16,
    height: 16
  })), ___EmotionJSX(Link, {
    css: css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n            &:hover {\n              background-color: #fe2c55;\n            }\n          "]))),
    hoverColor: "white",
    target: "_blank",
    rel: "noopener noreferrer",
    href: "https://www.tiktok.com/@catch.com.au",
    title: "TikTok"
  }, ___EmotionJSX(IconTikTok, {
    width: 16,
    height: 16
  })), ___EmotionJSX(SiteTypeButton, null)));
};