import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// @ts-ignore

/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var OnePassRecommenderMetaFragmentDoc = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    fragment OnePassRecommenderMeta on RecommenderMetaData {\n  __typename\n  preHydrationCount\n  postHydrationCount\n  hydrationErrorCount\n}\n    "])));
export var OnePassRecommenderPaginationFragmentDoc = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    fragment OnePassRecommenderPagination on RecommenderPaginationData {\n  __typename\n  totalCount\n  nextItemIndex\n}\n    "])));
export var OnePassBannerDataFragmentDoc = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    fragment OnePassBannerData on Banner {\n  __typename\n  id\n  url\n  image {\n    id\n    url\n    altText\n    width\n    height\n  }\n  platforms\n}\n    "])));
export var OnePassProductAssetsDataFragmentDoc = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    fragment OnePassProductAssetsData on ProductAssets {\n  __typename\n  gallery {\n    id\n    altText\n    url\n  }\n  hero {\n    id\n    altText\n    url\n  }\n}\n    "])));
export var OnePassOfferDataFragmentDoc = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    fragment OnePassOfferData on Offer {\n  __typename\n  id\n  clubExclusive\n  clubFreeShipping\n  freeShipping\n  sellPrice {\n    amount\n    currency\n  }\n  clubSellPrice {\n    amount\n    currency\n  }\n  retailPrice {\n    amount\n    currency\n  }\n  seller {\n    id\n    name\n  }\n  badges\n}\n    "])));
export var OnePassProductTileDataFragmentDoc = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    fragment OnePassProductTileData on Product {\n  __typename\n  id\n  title\n  ratingsSummary {\n    numberOfRatings\n    averageRating\n  }\n  assets {\n    ...OnePassProductAssetsData\n  }\n  brand {\n    name\n    slug\n  }\n  priceTag {\n    priceDiscount {\n      label\n      percentageLabel\n      endDate\n    }\n    topLabel\n    priceLabel {\n      wasPrice {\n        amount\n      }\n      price {\n        amount\n      }\n      saving {\n        label\n        savingAmount {\n          amount\n        }\n      }\n    }\n    clubSellPrice {\n      amount\n    }\n    clubExclusive\n    enabled\n    retailPriceDisclaimer {\n      description\n    }\n  }\n  bestOffer {\n    ...OnePassOfferData\n  }\n  options {\n    name\n  }\n  sellable\n  slug\n  adult\n}\n    ", "\n", ""])), OnePassProductAssetsDataFragmentDoc, OnePassOfferDataFragmentDoc);
export var OnePassEventDataFragmentDoc = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    fragment OnePassEventData on Event {\n  __typename\n  id\n  url\n  title\n  onePassTitle\n  pillText\n  onePassSubtext\n  badgeText\n  productCount\n  sellableProductCount\n  product {\n    ...OnePassProductTileData\n  }\n  image {\n    single {\n      id\n      url\n      altText\n    }\n    onePassPrePromote {\n      id\n      url\n      altText\n    }\n  }\n}\n    ", ""])), OnePassProductTileDataFragmentDoc);
export var OnePassScheduleEntryDataFragmentDoc = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    fragment OnePassScheduleEntryData on ScheduleEntry {\n  __typename\n  id\n  startDate\n  endDate\n  banner {\n    ...OnePassBannerData\n  }\n  event {\n    ...OnePassEventData\n  }\n  onePassExclusive\n}\n    ", "\n", ""])), OnePassBannerDataFragmentDoc, OnePassEventDataFragmentDoc);
export var FeaturedEventProductsDocument = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    query FeaturedEventProducts($eventId: ID!, $limit: Int) {\n  featuredProductsByEventId(input: {eventId: $eventId, limit: $limit}) {\n    products {\n      ...OnePassProductTileData\n    }\n  }\n}\n    ", ""])), OnePassProductTileDataFragmentDoc);
/**
 * __useFeaturedEventProductsQuery__
 *
 * To run a query within a React component, call `useFeaturedEventProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedEventProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedEventProductsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      limit: // value for 'limit'
 *   },
 * });
 */

export function useFeaturedEventProductsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FeaturedEventProductsDocument, options);
}
export function useFeaturedEventProductsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FeaturedEventProductsDocument, options);
}
export var OnePassConfigurationDocument = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    query OnePassConfiguration {\n  cmsConfiguration {\n    onePass {\n      trialLength\n      hotlineNo\n      priceMonthly {\n        amount\n        currency\n      }\n      priceYearly {\n        amount\n        currency\n      }\n    }\n  }\n}\n    "])));
/**
 * __useOnePassConfigurationQuery__
 *
 * To run a query within a React component, call `useOnePassConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnePassConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnePassConfigurationQuery({
 *   variables: {
 *   },
 * });
 */

export function useOnePassConfigurationQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(OnePassConfigurationDocument, options);
}
export function useOnePassConfigurationLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(OnePassConfigurationDocument, options);
}
export var OnePassRecommendedProductsDocument = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    query OnePassRecommendedProducts($limit: Int!) {\n  onePassRecommendedProducts(input: {limit: $limit}) {\n    products {\n      ...OnePassProductTileData\n    }\n    metaData {\n      ...OnePassRecommenderMeta\n    }\n  }\n}\n    ", "\n", ""])), OnePassProductTileDataFragmentDoc, OnePassRecommenderMetaFragmentDoc);
/**
 * __useOnePassRecommendedProductsQuery__
 *
 * To run a query within a React component, call `useOnePassRecommendedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnePassRecommendedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnePassRecommendedProductsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */

export function useOnePassRecommendedProductsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(OnePassRecommendedProductsDocument, options);
}
export function useOnePassRecommendedProductsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(OnePassRecommendedProductsDocument, options);
}
export var OnePassScheduleEntriesDocument = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    query OnePassScheduleEntries($input: OnePassScheduleEntryInput!) {\n  onePassScheduleEntries(input: $input) {\n    scheduleEntries: entries {\n      ...OnePassScheduleEntryData\n    }\n    ordinals {\n      catchAppBanner\n      recentlyViewedProducts\n    }\n  }\n}\n    ", ""])), OnePassScheduleEntryDataFragmentDoc);
/**
 * __useOnePassScheduleEntriesQuery__
 *
 * To run a query within a React component, call `useOnePassScheduleEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnePassScheduleEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnePassScheduleEntriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */

export function useOnePassScheduleEntriesQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(OnePassScheduleEntriesDocument, options);
}
export function useOnePassScheduleEntriesLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(OnePassScheduleEntriesDocument, options);
}
export var OnePassScheduleEntryCategoriesDocument = gql(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    query OnePassScheduleEntryCategories {\n  onePassScheduleEntryCategories {\n    categories {\n      id\n      label\n      totalEntries\n    }\n  }\n}\n    "])));
/**
 * __useOnePassScheduleEntryCategoriesQuery__
 *
 * To run a query within a React component, call `useOnePassScheduleEntryCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnePassScheduleEntryCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnePassScheduleEntryCategoriesQuery({
 *   variables: {
 *   },
 * });
 */

export function useOnePassScheduleEntryCategoriesQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(OnePassScheduleEntryCategoriesDocument, options);
}
export function useOnePassScheduleEntryCategoriesLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(OnePassScheduleEntryCategoriesDocument, options);
}
export var OnePassShopHeroDealsDocument = gql(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    query OnePassShopHeroDeals {\n  onePassHeroEntries {\n    heroScheduleEntries: entries {\n      ...OnePassScheduleEntryData\n    }\n  }\n}\n    ", ""])), OnePassScheduleEntryDataFragmentDoc);
/**
 * __useOnePassShopHeroDealsQuery__
 *
 * To run a query within a React component, call `useOnePassShopHeroDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnePassShopHeroDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnePassShopHeroDealsQuery({
 *   variables: {
 *   },
 * });
 */

export function useOnePassShopHeroDealsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(OnePassShopHeroDealsDocument, options);
}
export function useOnePassShopHeroDealsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(OnePassShopHeroDealsDocument, options);
}
export var ShopDataDocument = gql(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n    query ShopData($shopId: ID!) {\n  shop(input: {id: $shopId}) {\n    id\n    name\n    metaTitle\n    metaDescription\n    slug\n  }\n}\n    "])));
/**
 * __useShopDataQuery__
 *
 * To run a query within a React component, call `useShopDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopDataQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */

export function useShopDataQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(ShopDataDocument, options);
}
export function useShopDataLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(ShopDataDocument, options);
}
export var OnePassShopUpcomingDealsDocument = gql(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n    query OnePassShopUpcomingDeals {\n  onePassUpcomingEntries {\n    entries {\n      ...OnePassScheduleEntryData\n    }\n  }\n}\n    ", ""])), OnePassScheduleEntryDataFragmentDoc);
/**
 * __useOnePassShopUpcomingDealsQuery__
 *
 * To run a query within a React component, call `useOnePassShopUpcomingDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnePassShopUpcomingDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnePassShopUpcomingDealsQuery({
 *   variables: {
 *   },
 * });
 */

export function useOnePassShopUpcomingDealsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(OnePassShopUpcomingDealsDocument, options);
}
export function useOnePassShopUpcomingDealsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(OnePassShopUpcomingDealsDocument, options);
}