import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["title", "level", "closeMenu", "onBackButtonClick"];

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import React from "react";
import { Box, Button, IconCross, IconDownArrow, Text } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var PlainButton = styled(Button)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: transparent;\n  color: ", ";\n  min-width: 0 !important;\n  line-height: 1;\n  padding: 0px;\n  border: none;\n  top: 17px;\n  position: absolute;\n  &:hover:not(:disabled):not([disabled]) {\n    background-color: transparent;\n  }\n  &:active {\n    box-shadow: none;\n  }\n"])), themeGet("colors.text"));
var BackButton = styled(PlainButton)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  left: 15px;\n  padding: 15px 20px;\n  margin: -15px -20px;\n"])));
var CloseButton = styled(PlainButton)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  right: 32px;\n  padding: 15px 20px;\n  margin: -15px -20px;\n"])));
var BackArrow = styled(IconDownArrow)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  transform: rotate(90deg);\n  width: 10px;\n  height: 5px;\n"])));
export var MenuHeader = function MenuHeader(_ref) {
  var title = _ref.title,
      level = _ref.level,
      closeMenu = _ref.closeMenu,
      onBackButtonClick = _ref.onBackButtonClick,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Box, _extends({
    position: "relative"
  }, props), ___EmotionJSX(Text, {
    py: "14px",
    my: "0px",
    fontSize: "base",
    lineHeight: "24px",
    textAlign: "center",
    fontWeight: "medium"
  }, title), closeMenu && ___EmotionJSX(CloseButton, {
    "aria-label": "Close button",
    onClick: closeMenu
  }, ___EmotionJSX(IconCross, {
    width: "11px",
    height: "11px",
    color: "secondary"
  })), level && level > 1 && onBackButtonClick && ___EmotionJSX(BackButton, {
    "aria-label": "Back button",
    onClick: onBackButtonClick
  }, ___EmotionJSX(BackArrow, null)));
};