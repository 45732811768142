import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, IconOnePass, Link } from "@catchoftheday/cg-components";
import { minusOnePx, styled, themeGet, useMedia, useTheme } from "@catchoftheday/theme";
import { isTouchDevice } from "@catchoftheday/utilities";
import { trackMenuClick } from "../../utilities/analytics";
import { jsx as ___EmotionJSX } from "@emotion/react";
var FIRST_MENU_ITEM_INDEX = 1;
var Popover = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  flex-shrink: 0;\n  height: 100%;\n  align-items: center;\n  @media (max-width: ", ") and (min-width: ", ") {\n    height: auto;\n  }\n"])), function (props) {
  return minusOnePx(themeGet("breakpoints.lg")(props));
}, function (props) {
  return themeGet("breakpoints.md")(props);
});
var OnePassNavItem = styled(Link)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  position: relative;\n  margin-right: 20px;\n  outline: none;\n\n  &:hover,\n  &:focus,\n  &:active {\n    color: ", ";\n    text-decoration: none;\n    outline: none;\n  }\n\n  @media (min-width: ", ") {\n    height: 100%;\n    &:hover,\n    &:active {\n      .hover-marker {\n        visibility: visible;\n        opacity: 1;\n      }\n    }\n  }\n  @media (max-width: ", ") and (min-width: ", ") {\n    height: auto;\n    border: solid 1px;\n    border-color: ", ";\n    border-radius: 16px;\n    padding: 3px 12px;\n    margin: 0px 4px;\n\n    &:active {\n      box-shadow: inset 0px 4px 4px ", ";\n    }\n  }\n"])), function (props) {
  return themeGet("colors.onePass.primary")(props);
}, themeGet("breakpoints.lg"), function (props) {
  return minusOnePx(themeGet("breakpoints.lg")(props));
}, function (props) {
  return themeGet("breakpoints.md")(props);
}, function (props) {
  return themeGet(props.isActive ? "colors.onePass.primary" : "colors.ui.borderColor")(props);
}, function (props) {
  return themeGet("colors.grey")(props);
});
var PrimaryNavItem = styled(Link)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  height: 100%;\n  font-size: ", ";\n  padding: 4px;\n  margin-right: 20px;\n  color: ", ";\n\n  &:hover,\n  &:focus,\n  &:active {\n    color: ", ";\n    text-decoration: none;\n    outline: none;\n  }\n\n  @media (min-width: ", ") {\n    &:hover,\n    &:focus,\n    &:active {\n      .hover-marker {\n        visibility: visible;\n        opacity: 1;\n      }\n    }\n  }\n  @media (max-width: ", ") and (min-width: ", ") {\n    height: auto;\n    border: solid 1px\n      ", ";\n    border-radius: 100px;\n    padding: 8px 16px;\n    margin: 0px 4px;\n\n    &:active {\n      box-shadow: inset 0px 4px 4px ", ";\n    }\n  }\n"])), function (props) {
  return themeGet("fontSizes.sm")(props);
}, function (props) {
  return props.isClearance ? "#FF5061" : themeGet("colors.text")(props);
}, function (props) {
  return props.isClearance ? "#FF5061" : themeGet("colors.text")(props);
}, themeGet("breakpoints.lg"), function (props) {
  return minusOnePx(themeGet("breakpoints.lg")(props));
}, themeGet("breakpoints.md"), function (props) {
  return themeGet(props.isActive ? props.isClearance ? "#FF5061" : "colors.text" : "colors.ui.borderColor")(props);
}, function (props) {
  return themeGet("colors.grey")(props);
});
var HoverMarker = styled(Box)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  position: absolute;\n  height: 2px;\n  width: 100%;\n  bottom: 0px;\n  z-index: 1;\n  left: 0px;\n  transition: all ease 100ms;\n\n  @media (max-width: ", ") and (min-width: ", ") {\n    display: none;\n  }\n"])), function (props) {
  return minusOnePx(themeGet("breakpoints.lg")(props));
}, themeGet("breakpoints.md"));
export var MenuItem = function MenuItem(_ref) {
  var index = _ref.index,
      tabIndex = _ref.tabIndex,
      shop = _ref.shop,
      itemPathShop = _ref.itemPathShop,
      touchedItem = _ref.touchedItem,
      setTouchedItem = _ref.setTouchedItem,
      recordScrollPosition = _ref.recordScrollPosition;
  var theme = useTheme();
  var isDesktop = useMedia(["(min-width: ".concat(theme.breakpoints.lg, ")")], [true]);

  var _useState = useState(false),
      isActive = _useState[0],
      setIsActive = _useState[1];

  var isOnePass = shop.slug === "onepass";
  var isTodaysDeals = shop.slug === "today-s-deals";
  var isClearance = shop.slug === "clearance";
  var hasSubmenu = shop.shopNavigationGroups.length > 0;

  var onMenuClick = function onMenuClick(event) {
    if (isTouchDevice() && isDesktop && hasSubmenu && touchedItem !== shop.id) {
      event.preventDefault();
      setTouchedItem(shop.id);
      return;
    }

    trackMenuClick({
      name: shop.name,
      url: "".concat(location.origin).concat(shop.url, "/"),
      item_path: itemPathShop
    });
    recordScrollPosition();
  };

  useEffect(function () {
    if (true) {
      setIsActive(window.location.pathname.replace(/\/$/, "") === shop.url || isTodaysDeals && window.location.pathname === "/");
    }

    return function () {
      return setIsActive(false);
    };
  }, [isTodaysDeals, shop.url]);
  return ___EmotionJSX(Popover, {
    ml: index == FIRST_MENU_ITEM_INDEX ? "3px" : 0
  }, ___EmotionJSX(React.Fragment, null, isOnePass ? ___EmotionJSX(OnePassNavItem, {
    id: "menu-bar-item-".concat(index),
    tabIndex: tabIndex,
    href: shop.url,
    "aria-current": isActive ? "page" : "false",
    isActive: isActive,
    color: "onePass.primary",
    hoverColor: "onePass.primary",
    role: "menuitem",
    onClick: onMenuClick
  }, ___EmotionJSX(IconOnePass, {
    key: shop.id,
    width: "72px",
    title: "OnePass Shop"
  }), ___EmotionJSX(HoverMarker, {
    className: "hover-marker",
    visibility: isActive ? "visible" : "hidden",
    opacity: isActive ? "1" : "0",
    bg: "onePass.primary"
  })) : ___EmotionJSX(PrimaryNavItem, {
    id: "menu-bar-item-".concat(index),
    tabIndex: tabIndex,
    className: "primary-nav",
    role: "menuitem",
    "aria-haspopup": true,
    "aria-current": isActive ? "page" : false,
    isActive: isActive,
    href: shop.url,
    onClick: onMenuClick,
    isClearance: isClearance
  }, ___EmotionJSX(Heading.h5, {
    key: shop.id,
    color: "inherit",
    m: "0",
    fontWeight: {
      md: "normal",
      lg: "light"
    },
    lineHeight: "1"
  }, shop.name), ___EmotionJSX(HoverMarker, {
    className: "hover-marker",
    visibility: isActive ? "visible" : "hidden",
    opacity: isActive ? "1" : "0",
    bg: isClearance ? "#FF5061" : "textTertiary"
  }))));
};