import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { Flex, Popover, PopoverContent, PopoverControl } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var HeaderTile = function HeaderTile(_ref) {
  var popoverProps = _ref.popoverProps,
      Icon = _ref.icon,
      children = _ref.children,
      onTileShow = _ref.onTileShow;

  var _useState = useState(false),
      tileOpen = _useState[0],
      setTileOpen = _useState[1]; // eslint-disable-next-line react-hooks/exhaustive-deps


  var debouncedSetTileOpen = useCallback(debounce(setTileOpen, 50), []);

  function onMouseLeaveHander() {
    debouncedSetTileOpen.cancel();
    debouncedSetTileOpen(false);
  }

  function onMouseEnterHandler() {
    debouncedSetTileOpen.cancel();
    setTileOpen(true);
  }

  useEffect(function () {
    if (typeof onTileShow == "function") {
      onTileShow(tileOpen);
    }
  }, [tileOpen, onTileShow]);
  return ___EmotionJSX(Popover, _extends({
    isOpen: tileOpen,
    hideArrow: true,
    hideOnClickOutside: true,
    flexGrow: 1,
    zIndex: 2
  }, popoverProps), ___EmotionJSX(PopoverControl, null, function (_ref2) {
    var ref = _ref2.ref;
    return ___EmotionJSX(Flex, {
      height: "100%",
      ref: ref,
      onFocus: onMouseEnterHandler,
      onBlur: onMouseLeaveHander,
      onMouseLeave: onMouseLeaveHander,
      onMouseEnter: onMouseEnterHandler
    }, typeof Icon === "function" ? Icon({
      isOpen: tileOpen
    }) : Icon);
  }), ___EmotionJSX(PopoverContent, null, ___EmotionJSX(Flex, {
    role: "dialog",
    "aria-modal": "true",
    onFocus: onMouseEnterHandler,
    onBlur: onMouseLeaveHander,
    onMouseLeave: onMouseLeaveHander,
    onMouseEnter: onMouseEnterHandler
  }, children)));
};