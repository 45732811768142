import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isLoggedIn", "headerConfiguration"];
import React from "react";
import { Box, Flex, IconCircularArrow, IconMobile, IconShield, Link, Span } from "@catchoftheday/cg-components";
import { Container } from "../../components/Container";
import { jsx as ___EmotionJSX } from "@emotion/react";
var topHeaderLinks = {
  leftSection: [{
    name: "Catch Mobile",
    url: "https://www.catch.com.au/event/catch-connect-mobile-phone-plans-88137",
    icon: "mobile"
  }],
  rightSection: [{
    name: "Careers",
    icon: "",
    url: "https://www.catch.com.au/careers"
  }, {
    name: "Gift Cards",
    icon: "",
    url: "https://www.catch.com.au/gift-card"
  }, {
    name: "Help",
    icon: "",
    url: "https://help.catch.com.au/hc/en-us"
  }]
};

function matchIcon(name) {
  var Component;

  switch (name) {
    case "mobile":
      {
        Component = IconMobile;
        break;
      }

    case "shield":
      {
        Component = IconShield;
        break;
      }

    case "circularArrow":
      {
        Component = IconCircularArrow;
        break;
      }

    default:
      Component = undefined;
      break;
  }

  return Component;
}

export var TopHeader = function TopHeader(_ref) {
  var isLoggedIn = _ref.isLoggedIn,
      headerConfiguration = _ref.headerConfiguration,
      props = _objectWithoutProperties(_ref, _excluded);

  function renderHeaderLink(_ref2, _ref3) {
    var name = _ref2.name,
        url = _ref2.url,
        icon = _ref2.icon,
        iconSize = _ref2.iconSize;
    var _ref3$marginRight = _ref3.marginRight,
        marginRight = _ref3$marginRight === void 0 ? "0" : _ref3$marginRight,
        _ref3$marginLeft = _ref3.marginLeft,
        marginLeft = _ref3$marginLeft === void 0 ? "0" : _ref3$marginLeft;
    var Icon = icon ? matchIcon(icon) : null;
    return ___EmotionJSX(Link, {
      href: url,
      key: name,
      ml: marginLeft,
      mr: marginRight,
      color: "typography.subText"
    }, Icon && ___EmotionJSX(Icon, {
      "data-testid": "icon-".concat(icon),
      mr: ".175rem",
      color: "typography.inactive",
      width: iconSize || 15,
      height: iconSize || 15,
      "aria-hidden": "true"
    }), ___EmotionJSX(Span, {
      verticalAlign: "middle",
      fontWeight: "normal",
      fontSize: "xs"
    }, name));
  }

  function loggedInLinks() {
    return [{
      name: "Track My Order",
      icon: "",
      url: isLoggedIn ? "/my-account/my-orders" : "https://help.catch.com.au/hc/en-us"
    }];
  }

  var leftSection = headerConfiguration ? headerConfiguration.topHeader.leftSection : topHeaderLinks.leftSection;
  var rightSection = headerConfiguration ? headerConfiguration.topHeader.rightSection : topHeaderLinks.rightSection;
  var statefulLinks = headerConfiguration ? [] : loggedInLinks();
  return ___EmotionJSX(Box, {
    as: "nav",
    "aria-label": "Top Header Navigation"
  }, ___EmotionJSX(Container, _extends({
    backgroundColor: "ui.backgroundColor"
  }, props), ___EmotionJSX(Flex, {
    py: "0.175rem"
  }, ___EmotionJSX(Flex, {
    justifyContent: "flex-start",
    alignItems: "baseline",
    flexGrow: 1
  }, leftSection.map(function (section) {
    return renderHeaderLink(section, {
      marginRight: "1.3125rem"
    });
  })), ___EmotionJSX(Flex, {
    justifyContent: "flex-end",
    alignItems: "baseline",
    flexGrow: 1
  }, rightSection.map(function (section) {
    return renderHeaderLink(section, {
      marginLeft: "1.75rem"
    });
  }), statefulLinks.map(function (section) {
    return renderHeaderLink(section, {
      marginLeft: "1.75rem"
    });
  })))));
};