import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
import React from "react";
import { Heading } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopSectionHeading = function ShopSectionHeading(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Heading.h3, _extends({
    fontSize: {
      xs: "sm",
      md: "base",
      lg: "xl"
    },
    margin: 0,
    fontWeight: "semibold"
  }, props), children);
};