import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { useTheme } from '@catchoftheday/theme';
import { BackgroundTile } from "../PrimaryHeader/BackgroundTile";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Container = function Container(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  var theme = useTheme();
  return ___EmotionJSX(BackgroundTile, _extends({
    px: ["0.875rem", null, "30px"]
  }, props), ___EmotionJSX(Box, {
    maxWidth: theme.maxWidths["default"],
    width: "100%",
    m: "auto",
    height: "100%"
  }, children));
};
export default Container;