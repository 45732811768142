import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ContentWrapper = function ContentWrapper(props) {
  var theme = useTheme();
  return ___EmotionJSX(Box, {
    backgroundColor: "white"
  }, ___EmotionJSX(Box, _extends({
    mx: "auto",
    px: 4,
    pt: {
      xs: 10,
      md: 20
    },
    maxWidth: theme.maxWidths.cotdWebsite
  }, props)));
};