import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["isOpen", "cover", "children"];

var _templateObject, _templateObject2, _templateObject3;

import React, { useCallback, useLayoutEffect, useState } from "react";
import { Box } from "@catchoftheday/cg-components";
import { keyframes, styled } from "@catchoftheday/theme";
import { MOBILE_BOTTOM_NAV_HEIGHT } from "@header/consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
var fadeIn = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    0% { opacity: 0; }\n    100% { opacity: 1; }\n"])));
var fadeOut = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    0% { opacity: 1; }\n    100% { opacity: 0; }\n"])));
var MenuContentContainer = styled(Box)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: block;\n  height: 100%;\n  animation: ", " 200ms ease;\n"])), function (props) {
  return props.isOpen ? fadeIn : fadeOut;
});
export var MenuContentPopup = function MenuContentPopup(_ref) {
  var isOpen = _ref.isOpen,
      cover = _ref.cover,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useState = useState(),
      windowYOffset = _useState[0],
      setWindowYOffset = _useState[1];

  var _useState2 = useState("100%"),
      popupHeight = _useState2[0],
      setPopupHeight = _useState2[1];

  var _useState3 = useState(),
      headerHeight = _useState3[0],
      setHeaderHeight = _useState3[1];

  var fetchPopupHeight = useCallback(function () {
    var headerElement = window.document.querySelector("#primary-header");
    var headerHeight = headerElement !== null ? headerElement.offsetHeight : 0;
    setHeaderHeight("".concat(headerHeight, "px"));
    setPopupHeight("".concat(window.innerHeight - (cover ? 0 : headerHeight) - MOBILE_BOTTOM_NAV_HEIGHT, "px"));
  }, [cover]);
  useLayoutEffect(function () {
    fetchPopupHeight();
    window.addEventListener("resize", fetchPopupHeight);

    if (isOpen && true) {
      setWindowYOffset(window.pageYOffset);
      window.document.body.style.position = "fixed";
      window.document.body.style.overflowY = "hidden";
      window.document.body.style.maxWidth = "100%";
    } else {
      window.document.body.style.position = "";
      window.document.body.style.overflowY = "";
      window.document.body.style.maxWidth = "";

      if (windowYOffset) {
        window.scrollTo(0, windowYOffset);
      }
    }

    return function () {
      window.removeEventListener("resize", fetchPopupHeight);
      window.document.body.style.overflowY = "";

      if (windowYOffset) {
        window.scrollTo(0, windowYOffset);
      }
    };
  }, [isOpen, windowYOffset, fetchPopupHeight]);
  return ___EmotionJSX(Box, _extends({
    display: isOpen ? "block" : "none",
    position: "fixed",
    top: cover ? "0px" : headerHeight,
    left: "0px",
    pb: MOBILE_BOTTOM_NAV_HEIGHT,
    height: popupHeight,
    width: "100%",
    bg: "white",
    zIndex: 50
  }, props), ___EmotionJSX(MenuContentContainer, {
    isOpen: isOpen
  }, children));
};