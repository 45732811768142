import _toConsumableArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Sticky from "react-stickynode";
import { Box, HorizontalNav } from "@catchoftheday/cg-components";
import { stripUnit, useMedia, useTheme } from "@catchoftheday/theme";
import { useOnePassScheduleEntryCategoriesQuery } from "../../../generated/graphql";
import { CategoryPill } from "../../pure/CategoryPill";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CategoryFilterBarContainer = function CategoryFilterBarContainer(_ref) {
  var _ref$categoryId = _ref.categoryId,
      categoryId = _ref$categoryId === void 0 ? null : _ref$categoryId,
      onCategoryChange = _ref.onCategoryChange;
  var theme = useTheme();

  var _useState = useState(false),
      isSticky = _useState[0],
      setIsSticky = _useState[1];

  var isDesktop = useMedia(["(min-width: ".concat(theme.breakpoints.md, ")")], [true]);
  var headerHeight = useMemo(function () {
    var _stickyHeaderHeight = theme.constants.stickyHeaderHeight;
    var stickyHeaderHeight = isDesktop ? _stickyHeaderHeight.md : _stickyHeaderHeight.xs;
    return stripUnit(stickyHeaderHeight);
  }, [isDesktop, theme.constants]);
  var pillContainerRef = useRef(null);
  var scrollPosRef = useRef(null);
  var handleCategoryChange = useCallback(function (id) {
    onCategoryChange(id);

    if (true && scrollPosRef.current && pillContainerRef.current) {
      window.scrollTo({
        top: scrollPosRef.current.offsetTop - headerHeight - pillContainerRef.current.clientHeight - 20,
        behavior: "smooth"
      });
    }
  }, [onCategoryChange, headerHeight]);
  var ShopAllPill = useCallback(function () {
    return ___EmotionJSX(CategoryPill, {
      label: "Shop All",
      checked: categoryId === null,
      onChange: function onChange() {
        return handleCategoryChange(null);
      }
    });
  }, [categoryId, handleCategoryChange]);

  var _useState2 = useState([___EmotionJSX(ShopAllPill, {
    key: "category-all"
  })]),
      categoryPills = _useState2[0],
      setCategoryPills = _useState2[1];

  var _useOnePassScheduleEn = useOnePassScheduleEntryCategoriesQuery(),
      data = _useOnePassScheduleEn.data;

  useEffect(function () {
    var _ref2 = data || {},
        onePassScheduleEntryCategories = _ref2.onePassScheduleEntryCategories;

    var _ref3 = onePassScheduleEntryCategories || {},
        categories = _ref3.categories;

    if (categories !== null && categories !== void 0 && categories.length) {
      var categoryComponents = categories.map(function (cat) {
        return ___EmotionJSX(CategoryPill, {
          key: "category-".concat(cat.id),
          label: cat.label,
          checked: categoryId === cat.id,
          onChange: function onChange() {
            return handleCategoryChange(cat.id);
          }
        });
      });
      setCategoryPills([___EmotionJSX(ShopAllPill, {
        key: "category-all"
      })].concat(_toConsumableArray(categoryComponents)));
    }
  }, [ShopAllPill, categoryId, data, handleCategoryChange]);
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Sticky, {
    enabled: !!pillContainerRef.current,
    top: headerHeight,
    bottomBoundary: pillContainerRef.current ? pillContainerRef.current.scrollTop : 0,
    innerZ: 2,
    onStateChange: function onStateChange(sticky) {
      setIsSticky(sticky.status === Sticky.STATUS_FIXED ? true : false);
    }
  }, ___EmotionJSX(Box, {
    backgroundColor: "ui.backgroundColor",
    px: {
      xs: 4,
      md: 6
    },
    py: 1,
    boxShadow: isSticky ? "box" : undefined,
    borderBottom: isSticky ? 1 : undefined,
    borderBottomColor: "grey"
  }, ___EmotionJSX(Box, {
    ref: pillContainerRef,
    position: "relative",
    overflow: "hidden",
    maxWidth: theme.maxWidths.cotdWebsite,
    margin: "0 auto"
  }, ___EmotionJSX(HorizontalNav, {
    label: "Deals category navigation",
    navItems: categoryPills
  })))), ___EmotionJSX("div", {
    ref: scrollPosRef
  }));
};