import React from "react";
import { Box, Flex, Span, Text } from "@catchoftheday/cg-components";
import { formatPrice } from "@catchoftheday/utilities";
import { ONEPASS_DISNEY_PLUS_BUNDLE_MONTHLY_PRICE, ONEPASS_DISNEY_PLUS_BUNDLE_MONTHLY_SAVING, URL_DISNEY_PLUS_BUNDLE_INFO_PAGE } from "../../../consts";
import { SubscriptionCTA } from "../SubscriptionCTA";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var DisneyPlusBundleContent = function DisneyPlusBundleContent(_ref) {
  var _ref$ctaText = _ref.ctaText,
      ctaText = _ref$ctaText === void 0 ? "Upgrade now" : _ref$ctaText;
  return ___EmotionJSX(Box, null, ___EmotionJSX(Text, {
    fontSize: "base",
    fontWeight: "normal",
    mt: 0,
    mb: 2,
    textAlign: {
      xs: "center",
      lg: "start"
    }
  }, "Get Disney+ and free delivery*", ___EmotionJSX("br", null), "from Australia's favourite brands."), ___EmotionJSX(Flex, {
    alignItems: "center"
  }, ___EmotionJSX(SubscriptionCTA, {
    href: URL_DISNEY_PLUS_BUNDLE_INFO_PAGE,
    sizeVariant: "sm",
    variant: "onePassSecondary"
  }, ctaText), ___EmotionJSX(Box, {
    pl: 3
  }, ___EmotionJSX(Span, {
    lineHeight: 1,
    fontSize: {
      xs: "sm",
      md: "base"
    },
    fontWeight: "bold",
    display: "block",
    whiteSpace: "nowrap"
  }, formatPrice(ONEPASS_DISNEY_PLUS_BUNDLE_MONTHLY_PRICE), ___EmotionJSX(Span, {
    fontSize: {
      xs: "sm",
      md: "base"
    },
    fontWeight: "normal"
  }, " ", "/ month")), ___EmotionJSX(Span, {
    fontSize: "xs",
    fontWeight: "normal",
    display: "block",
    whiteSpace: "nowrap"
  }, "(Save ".concat(formatPrice(ONEPASS_DISNEY_PLUS_BUNDLE_MONTHLY_SAVING, false), " a month"), ___EmotionJSX("sup", null, "^"), ")"))));
};