import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { graphql } from "msw";
import { mockCopurchasedProducts } from "./CoPurchasedRecommender.mock";
export var handler = graphql.query("CoPurchasedProducts", function (request, response, context) {
  if (request.variables.includeClubProducts) {
    return response(context.data({
      __typename: "Query",
      coPurchasedProducts: {
        products: [_objectSpread(_objectSpread({}, mockCopurchasedProducts.coPurchasedProducts.products[0]), {}, {
          title: "Some club exclusive product"
        })],
        metaData: {
          hydrationErrorCount: 0,
          preHydrationCount: 1,
          postHydrationCount: 1
        }
      }
    }));
  }

  return response(context.data(_objectSpread({
    __typename: "Query"
  }, mockCopurchasedProducts)));
});