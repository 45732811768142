import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useCallback, useContext, useMemo, useState } from "react";
import { MenuContentPopup } from "../MenuContentPopup";
import { MenuHeader } from "../MenuHeader";
import { MenuTransitionContainer } from "../MenuTransitionContainer";
import { MobileMenuContext } from "../MobileMenuContext";
import { CategoriesMenu } from "./components/CategoriesMenu";
import { LinksMenu } from "./components/LinksMenu";
import { SubCategoriesMenu } from "./components/SubCategoriesMenu";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CategoryMenu = function CategoryMenu(_ref) {
  var _ref$isOpen = _ref.isOpen,
      isOpen = _ref$isOpen === void 0 ? false : _ref$isOpen,
      toggleMenu = _ref.toggleMenu;

  var _useContext = useContext(MobileMenuContext),
      _useContext$shops = _useContext.shops,
      shops = _useContext$shops === void 0 ? [] : _useContext$shops;

  var _useState = useState([]),
      currentIdPath = _useState[0],
      setCurrentIdPath = _useState[1];

  var _useState2 = useState({
    direction: "left",
    active: false
  }),
      transition = _useState2[0],
      setTransition = _useState2[1];

  var currentMenuLevel = useMemo(function () {
    return currentIdPath.length + 1;
  }, [currentIdPath]);
  var activeItemName = useMemo(function () {
    if (currentMenuLevel === 2) {
      var _shops$find;

      return (_shops$find = shops.find(function (_ref2) {
        var id = _ref2.id;
        return id === currentIdPath[0];
      })) === null || _shops$find === void 0 ? void 0 : _shops$find.name;
    }

    if (currentMenuLevel === 3) {
      var _shops$find2, _shops$find2$shopNavi;

      return (_shops$find2 = shops.find(function (_ref3) {
        var id = _ref3.id;
        return id === currentIdPath[0];
      })) === null || _shops$find2 === void 0 ? void 0 : (_shops$find2$shopNavi = _shops$find2.shopNavigationGroups.find(function (_ref4) {
        var id = _ref4.id;
        return id === currentIdPath[1];
      })) === null || _shops$find2$shopNavi === void 0 ? void 0 : _shops$find2$shopNavi.name;
    }
  }, [currentIdPath, currentMenuLevel, shops]);
  var handleMenuClick = useCallback(function (idPath) {
    setTransition({
      direction: "left",
      active: true
    });
    setCurrentIdPath(idPath);
  }, []);
  var handleMenuBackAction = useCallback(function () {
    setTransition({
      direction: "right",
      active: true
    });
    setCurrentIdPath(currentIdPath.slice(0, currentIdPath.length - 1));
  }, [currentIdPath]);
  var handleTransitionEnd = useCallback(function () {
    setTransition(function (prevState) {
      return _objectSpread(_objectSpread({}, prevState), {}, {
        active: false
      });
    });
  }, []);
  return ___EmotionJSX(MenuContentPopup, {
    isOpen: isOpen
  }, ___EmotionJSX(MenuHeader, {
    "aria-label": "Mobile category menu header",
    title: activeItemName || "Categories",
    level: currentMenuLevel,
    color: "secondary",
    closeMenu: toggleMenu,
    onBackButtonClick: handleMenuBackAction
  }), ___EmotionJSX(MenuTransitionContainer, {
    "aria-label": "Mobile category menu",
    active: transition.active,
    direction: transition.direction,
    onAnimationEnd: handleTransitionEnd
  }, currentMenuLevel === 1 && ___EmotionJSX(CategoriesMenu, {
    onItemClick: handleMenuClick
  }), currentMenuLevel === 2 && ___EmotionJSX(SubCategoriesMenu, {
    idPath: currentIdPath,
    onItemClick: handleMenuClick
  }), currentMenuLevel === 3 && ___EmotionJSX(LinksMenu, {
    idPath: currentIdPath
  })));
};