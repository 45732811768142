import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";

var _templateObject;

var _excluded = ["price", "text", "normalView"];
import React from "react";
import { Box, Span } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { formatPrice } from "@catchoftheday/utilities";
import productPriceSplit from "../../../utilities/productPriceSplit.utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPriceParts = function ProductPriceParts(_ref) {
  var price = _ref.price,
      text = _ref.text,
      normalView = _ref.normalView,
      props = _objectWithoutProperties(_ref, _excluded);

  if (!price) {
    return null;
  }

  var _productPriceSplit = productPriceSplit(price, true),
      dollars = _productPriceSplit.dollars,
      cents = _productPriceSplit.cents;

  var CurrencyText = styled(Span)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    display: inline-block;\n    font-size: 0.4375em;\n    line-height: 2;\n    vertical-align: text-top;\n  "])));

  if (normalView) {
    return ___EmotionJSX(Box, _extends({
      "data-testid": "price-parts"
    }, props), text && ___EmotionJSX(Span, {
      fontWeight: "inherit",
      fontSize: "inherit"
    }, text, "\xA0"), ___EmotionJSX(Span, {
      fontWeight: "inherit",
      fontSize: "inherit"
    }, "$", dollars, cents && ".".concat(cents)));
  }

  return ___EmotionJSX(Box, _extends({
    "data-testid": "price-parts",
    "aria-label": "".concat(formatPrice(price, false))
  }, props), text && ___EmotionJSX(Span, {
    fontWeight: "inherit",
    fontSize: "inherit"
  }, text, "\xA0"), ___EmotionJSX(CurrencyText, {
    fontSize: "inherit",
    fontWeight: "inherit"
  }, "$"), ___EmotionJSX(Span, {
    fontWeight: "inherit",
    fontSize: "inherit"
  }, dollars), cents && ___EmotionJSX(CurrencyText, {
    fontSize: "inherit",
    fontWeight: "inherit"
  }, ___EmotionJSX(Span, {
    display: "none"
  }, "."), cents));
};