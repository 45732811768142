import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Flex, IconCross, Input, LoadingSpinner } from "@catchoftheday/cg-components";
import { useIsSSR } from "@catchoftheday/utilities";
import { useTrendingSearchTermsQuery } from "../../generated/graphql";
import { SearchProducts } from "./containers/SearchProducts";
import { SearchTerms } from "./containers/SearchTerms";
import { SearchDropdownZone } from "./layouts/SearchDropdownZone";
import { DropdownOverlay } from "./pure/DropdownOverlay";
import { SearchButton } from "./pure/SearchButton";
import { SearchSuggestions } from "./pure/SearchSuggestions";
import { SearchTicker } from "./pure/SearchTicker";
import { MAX_TRENDING_SEARCH_TERMS } from "./consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EnhancedSearchBar = function EnhancedSearchBar(_ref) {
  var _ref$data = _ref.data,
      data = _ref$data === void 0 ? [] : _ref$data,
      inputProps = _ref.inputProps,
      flexContainerProps = _ref.flexContainerProps,
      isLoading = _ref.isLoading,
      searchTerm = _ref.searchTerm,
      isTickerEnabled = _ref.isTickerEnabled,
      isSearchTermLoading = _ref.isSearchTermLoading,
      defaultValue = _ref.defaultValue,
      onItemSelected = _ref.onItemSelected,
      onSearchButtonClicked = _ref.onSearchButtonClicked,
      onInputFocus = _ref.onInputFocus,
      onInputChange = _ref.onInputChange,
      renderListItem = _ref.renderListItem;

  var _useState = useState(false),
      isOpen = _useState[0],
      setIsOpen = _useState[1];

  var _useState2 = useState(-1),
      focussedLinkIndex = _useState2[0],
      setFocussedLinkIndex = _useState2[1];

  var inputRef = useRef(null);
  var linkRefs = useRef([]);
  var isSSR = useIsSSR();

  var _useTrendingSearchTer = useTrendingSearchTermsQuery({
    variables: {
      limit: MAX_TRENDING_SEARCH_TERMS
    },
    skip: isSSR
  }),
      searchTermsData = _useTrendingSearchTer.data;

  var displayTrendingSearchesTicker = !isOpen && !searchTerm && isTickerEnabled && (searchTermsData === null || searchTermsData === void 0 ? void 0 : searchTermsData.trendingSearchTerms);
  var toggleDropdownVisibility = useCallback(function () {
    setIsOpen(function (prevState) {
      return !prevState;
    });
  }, [setIsOpen]);
  var handleInputFocus = useCallback(function () {
    setFocussedLinkIndex(-1);
    setIsOpen(true);

    if (typeof onInputFocus === "function") {
      onInputFocus();
    }
  }, [onInputFocus]);
  var handleInputChange = useCallback(function (e) {
    if (typeof onInputChange === "function") {
      onInputChange(e.target.value, {
        onChange: true
      });
    }
  }, [onInputChange]);

  function onListItemClick(itemData) {
    if (itemData === null || itemData === void 0 ? void 0 : itemData.unclickable) {
      return;
    }

    if (typeof onItemSelected === "function") {
      onItemSelected(itemData);
    }

    setIsOpen(false);
  }

  function onInputKeyDown(event) {
    var numberOfLinks = linkRefs.current.filter(Boolean).length - 1;

    if (event.key === "ArrowDown") {
      if (focussedLinkIndex < numberOfLinks) {
        setFocussedLinkIndex(function (prevState) {
          return prevState + 1;
        });
      } else {
        setFocussedLinkIndex(0);
      }
    } else if (event.key === "ArrowUp") {
      if (focussedLinkIndex > 0) {
        setFocussedLinkIndex(function (prevState) {
          return prevState - 1;
        });
      } else {
        setFocussedLinkIndex(numberOfLinks);
      }
    } else if (event.key === "Enter") {
      if (focussedLinkIndex > -1 && focussedLinkIndex <= numberOfLinks) {
        var _linkRefs$current$foc;

        (_linkRefs$current$foc = linkRefs.current[focussedLinkIndex]) === null || _linkRefs$current$foc === void 0 ? void 0 : _linkRefs$current$foc.click();
        event.preventDefault();
      }
    }
  }

  var onTypewriterClick = useCallback(function () {
    handleInputFocus();
  }, [handleInputFocus]);
  useEffect(function () {
    function handleEscapeKey(e) {
      if (e.key === "Escape") {
        var _inputRef$current;

        (_inputRef$current = inputRef.current) === null || _inputRef$current === void 0 ? void 0 : _inputRef$current.blur();
        setIsOpen(false);
      }
    }

    document.addEventListener("keydown", handleEscapeKey);
    return function () {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);
  return ___EmotionJSX(Flex, flexContainerProps, isOpen && ___EmotionJSX(DropdownOverlay, {
    onClick: toggleDropdownVisibility
  }), ___EmotionJSX(Flex, {
    flexGrow: 1,
    position: "relative",
    zIndex: isOpen ? 4 : 0
  }, ___EmotionJSX("form", {
    role: "search",
    onSubmit: function onSubmit(e) {
      e.preventDefault();
      onSearchButtonClicked();
    },
    style: {
      width: "100%"
    },
    autoComplete: "off"
  }, ___EmotionJSX(Input, _extends({}, inputProps, {
    height: 47,
    defaultValue: defaultValue,
    onFocus: handleInputFocus,
    onChange: handleInputChange,
    fontWeight: "light",
    borderRadius: isOpen ? "4px 0 0 0" : "4px 0 0 4px",
    borderColor: "#ddd!important",
    placeholder: "Search for products and brands...",
    onKeyDown: onInputKeyDown,
    ref: inputRef
  }))), isLoading && ___EmotionJSX(LoadingSpinner, {
    "data-testid": "search-loading-spinner",
    position: "absolute",
    width: {
      xs: "20px",
      md: "25px"
    },
    height: {
      xs: "20px",
      md: "25px"
    },
    right: {
      xs: "5px",
      md: "10px"
    },
    top: {
      xs: "8px",
      md: "12px"
    },
    ringSize: "3px"
  }), displayTrendingSearchesTicker && !isSSR && ___EmotionJSX(SearchTicker, {
    onClick: onTypewriterClick,
    trendingSearchTerms: searchTermsData.trendingSearchTerms.terms
  }), ___EmotionJSX(SearchDropdownZone, {
    role: "combobox",
    "aria-expanded": isOpen,
    "aria-haspopup": "listbox",
    className: isOpen ? "open" : undefined
  }, ___EmotionJSX(Flex, {
    flexDirection: {
      xs: "column",
      md: "row"
    },
    width: "100%"
  }, ___EmotionJSX(Button, {
    "aria-label": "Close dropdown",
    variant: "plain",
    position: "absolute",
    right: 5,
    top: 5,
    padding: 0,
    minWidth: "0px !important",
    lineHeight: 1,
    onClick: toggleDropdownVisibility,
    zIndex: 1
  }, ___EmotionJSX(IconCross, {
    width: 14,
    height: 14,
    cursor: "pointer"
  })), ___EmotionJSX(Flex, {
    width: {
      xs: "100%",
      md: "35.8%"
    },
    p: 5,
    bg: "ui.backgroundColor",
    borderBottomLeftRadius: "5px"
  }, searchTerm && searchTerm.length ? ___EmotionJSX(SearchSuggestions, {
    data: data,
    renderListItem: renderListItem,
    onListItemClick: onListItemClick,
    focussedLinkIndex: focussedLinkIndex,
    linkRefs: linkRefs,
    isSearchTermLoading: isSearchTermLoading,
    searchTerm: searchTerm
  }) : ___EmotionJSX(Flex, {
    flexDirection: "column",
    width: "100%"
  }, ___EmotionJSX(SearchTerms, {
    trendingSearchTerms: searchTermsData === null || searchTermsData === void 0 ? void 0 : searchTermsData.trendingSearchTerms.terms,
    focussedLinkIndex: focussedLinkIndex,
    linkRefs: linkRefs
  }))), ___EmotionJSX(Flex, {
    p: "20px",
    flex: "1",
    width: {
      xs: "100%",
      md: "64.2%"
    }
  }, ___EmotionJSX(SearchProducts, {
    searchTerm: searchTerm
  }))))), ___EmotionJSX(SearchButton, {
    "aria-label": "Submit search",
    onClick: onSearchButtonClicked,
    borderRadius: isOpen ? "0 4px 0 0" : "0 4px 4px 0",
    zIndex: isOpen ? 4 : 0
  }));
};