import { IconBasketball, IconBook, IconCap, IconCatchLetterC, IconChair, IconDress, IconHomeKitchen, IconLipstick, IconMixer, IconMonitor, IconPet, IconShirt, IconToy } from "@catchoftheday/cg-components";
export var menuIconMap = {
  "cheap-groceries": IconCatchLetterC,
  "grocery-liquor": IconCatchLetterC,
  "womens-fashion": IconDress,
  "mens-fashion": IconShirt,
  beauty: IconLipstick,
  "home-kitchen": IconHomeKitchen,
  "sports-outdoor": IconBasketball,
  electronics: IconMonitor,
  "kids-clothing-toys": IconCap,
  pets: IconPet,
  "toys-games": IconToy,
  furniture: IconChair,
  appliances: IconMixer,
  entertainment: IconBook
};