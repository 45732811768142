import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isOpen", "onClose"];
import React from "react";
import { ContentZone } from "../../layouts/UpsellModalLayout/UpsellModalLayout";
import { OnePassModal } from "../OnePassModal";
import { Content } from "./Content";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var UpsellModal = function UpsellModal(_ref) {
  var _ref$isOpen = _ref.isOpen,
      isOpen = _ref$isOpen === void 0 ? false : _ref$isOpen,
      onClose = _ref.onClose,
      contentProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(OnePassModal, {
    sizeVariant: {
      xs: "x-small",
      md: "medium"
    },
    isOpen: isOpen,
    onRequestClose: onClose,
    width: "90%"
  }, ___EmotionJSX(ContentZone, null, ___EmotionJSX(Content, _extends({
    isResponsiveFontSize: true,
    logoContainerFontSize: {
      xs: "base",
      md: "2xl"
    }
  }, contentProps))));
};