import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";

var _templateObject;

var _excluded = ["children", "show", "transitionDuration"];
import React, { useState } from "react";
import { useWindowSize } from "@react-hookz/web";
import { styled } from "@catchoftheday/theme";
import { useIsomorphicLayoutEffect } from "@catchoftheday/utilities";
import { Box } from "../Box";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Collapsible = function Collapsible(_ref) {
  var children = _ref.children,
      show = _ref.show,
      _ref$transitionDurati = _ref.transitionDuration,
      transitionDuration = _ref$transitionDurati === void 0 ? 200 : _ref$transitionDurati,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  var _useState = useState(null),
      innerContainer = _useState[0],
      setInnerContainer = _useState[1]; // Whether the content of the collapsible should be hidden


  var _useState2 = useState(!show),
      hidden = _useState2[0],
      setHidden = _useState2[1]; // max height of content container for transition purposes


  var _useState3 = useState(show ? undefined : 0),
      maxHeight = _useState3[0],
      setMaxHeight = _useState3[1]; // monitor window size to avoid clipped content


  var windowSize = useWindowSize(); // Layout effect will run when new DOM tree is ready but not yet painted

  useIsomorphicLayoutEffect(function () {
    var _innerContainer$scrol;

    // Updates are not necessary if the innerContainer is not ready yet
    if (!innerContainer) {
      return;
    }

    var containerScrollHeight = Math.round((_innerContainer$scrol = innerContainer === null || innerContainer === void 0 ? void 0 : innerContainer.scrollHeight) !== null && _innerContainer$scrol !== void 0 ? _innerContainer$scrol : 0);
    var newMaxHeight = show ? containerScrollHeight : 0;

    if (newMaxHeight !== maxHeight) {
      setHidden(false);
      setMaxHeight(show ? newMaxHeight : 0);
    }
  }, [show, innerContainer, maxHeight, windowSize]); // If show and hidden are not consistent it is because we are currently in a transition

  var transitioning = !show !== hidden;
  return ___EmotionJSX(Container, _extends({
    onTransitionEnd: function onTransitionEnd() {
      return setHidden(!show);
    },
    transitionDuration: transitionDuration,
    maxHeight: maxHeight !== null && maxHeight !== void 0 ? maxHeight : "".concat(maxHeight, "px"),
    opacity: show ? 1 : 0,
    overflow: "hidden"
  }, boxProps), ___EmotionJSX(Box, {
    visibility: !transitioning && hidden ? "hidden" : "visible" // Using state instead of ref so we can be notified when the container is ready
    ,
    ref: function ref(node) {
      return setInnerContainer(node);
    }
  }, children));
};
Collapsible.displayName = "Collapsible";
export default Collapsible;
var Container = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transition: max-height ", "ms ease-out,\n    opacity ", "ms ease-out;\n"])), function (props) {
  return props.transitionDuration;
}, function (props) {
  return props.transitionDuration;
});