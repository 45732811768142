import React from "react";
import { Box, Flex, IconDisneyPlus, IconOnePass } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var OnePassXDisneyPlusIcon = function OnePassXDisneyPlusIcon() {
  return ___EmotionJSX(Flex, {
    fontSize: "base",
    alignItems: "center",
    flexBasis: "50%",
    pr: 4
  }, ___EmotionJSX(IconOnePass, {
    width: {
      xs: "7.5em",
      lg: "5em"
    },
    height: "auto",
    title: "OnePass"
  }), ___EmotionJSX(Box, {
    mx: 4,
    borderLeft: 1,
    borderColor: "white",
    height: "3em"
  }), ___EmotionJSX(IconDisneyPlus, {
    mb: 3,
    width: {
      xs: "6.5em",
      lg: "5em"
    },
    height: "auto",
    title: "Disney+",
    color: "white"
  }));
};