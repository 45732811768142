import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Icon } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconEye = function IconEye(props) {
  return ___EmotionJSX(Icon, _extends({
    fill: "currentColor",
    viewBox: "0 0 126 88"
  }, props), ___EmotionJSX("path", {
    d: "M124.7,41.4C123.6,39.7,97,0.3,63,0.3C29,0.3,2.4,39.7,1.3,41.4c-1.1,1.6-1.1,3.8,0,5.3 C2.4,48.4,29,87.8,63,87.8c34,0,60.6-39.4,61.7-41.1C125.8,45.1,125.8,43,124.7,41.4z M63,78.7C38,78.7,16.3,52.6,9.9,44 c6.4-8.6,28-34.7,53.1-34.7c25,0,46.7,26.1,53.1,34.7C109.7,52.6,88.1,78.7,63,78.7z"
  }), ___EmotionJSX("path", {
    d: "M63,19c-13.8,0-25,11.2-25,25c0,13.8,11.2,25,25,25s25-11.2,25-25C88,30.2,76.8,19,63,19z M63,59 c-8.3,0-15-6.7-15-15s6.7-15,15-15s15,6.7,15,15S71.3,59,63,59z"
  }));
};