import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Flex } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var DisneyPlusBoxZone = function DisneyPlusBoxZone(props) {
  var theme = useTheme();
  return ___EmotionJSX(Flex, _extends({
    background: "linear-gradient(90deg, ".concat(theme.colors.onePass.primaryDarker, " 0%, ").concat(theme.colors.disneyPlus.primaryDark, " 100%)"),
    flexDirection: {
      xs: "column",
      lg: "row-reverse"
    },
    alignItems: "stretch",
    color: "white",
    fontFamily: "onePass",
    borderRadius: "corner",
    overflow: "hidden",
    mb: 2
  }, props));
};
export var DisneyPlusSideImageZone = function DisneyPlusSideImageZone(props) {
  return ___EmotionJSX(Flex, _extends({
    position: "relative",
    justifyContent: "flex-end",
    flexBasis: "30%"
  }, props));
};
export var DisneyPlusContentZone = function DisneyPlusContentZone(props) {
  return ___EmotionJSX(Flex, _extends({
    flexGrow: 1,
    flexBasis: "70%",
    flexWrap: "wrap",
    mt: {
      xs: -9,
      lg: 0
    },
    px: 6,
    py: {
      xs: 6,
      lg: 4
    },
    flexDirection: {
      xs: "column",
      lg: "row"
    },
    alignItems: "center"
  }, props));
};