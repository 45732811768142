import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["type", "hidePopup"],
    _excluded2 = ["type", "variant", "theme", "timeoutDuration", "showPointer", "showCloseButton", "showAlertIcon", "alignIcon", "icon", "pointerProps", "wrapperProps", "children", "hideAlert"];

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import React, { useCallback, useEffect, useState } from "react";
import { keyframes, styled, theme } from "@catchoftheday/theme";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { IconAlert, IconTick, IconToolTip, IconX } from "../Icon";
import { InlineAlert } from "./InlineAlert";
import { jsx as ___EmotionJSX } from "@emotion/react";
var TRANSITION_DURATION = 300;
var fadeIn = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    0% { opacity: 0; }\n    100% { opacity: 1; }\n"])));
var fadeOut = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    0% { opacity: 1; }\n    100% { opacity: 0; }\n"])));
export var ALERT_VARIANT_MAP = {
  error: {
    color: "error",
    icon: IconAlert
  },
  warning: {
    color: "warning",
    icon: IconAlert
  },
  success: {
    color: "primary",
    icon: IconTick
  },
  "default": {
    color: "text",
    icon: IconToolTip
  }
};
var PopupWrapper = styled(Box)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: relative;\n  animation: ", "\n    ", "ms ease;\n"])), function (props) {
  return props.show ? fadeIn : fadeOut;
}, TRANSITION_DURATION);
var Pointer = styled(Box)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "\n  height: ".concat(props.size, ";\n  width: ").concat(props.size, ";\n  border-left: ").concat(props.size, " solid transparent;\n  border-right: ").concat(props.size, " solid transparent;\n  border-bottom-width: ").concat(props.size, ";\n  border-bottom-style: solid;\n  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));");
});
Pointer.defaultProps = {
  size: "7px",
  borderBottomColor: "white",
  ml: "50%"
};
var CloseIconContainer = styled(Box)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  :focus {\n    outline: 1px solid ", ";\n  }\n"])), theme.colors.secondary);

var CloseIcon = function CloseIcon(_ref) {
  var type = _ref.type,
      hidePopup = _ref.hidePopup,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(CloseIconContainer, _extends({
    role: "button",
    "aria-label": "close button",
    tabIndex: 0,
    position: "absolute",
    top: type == "inline" ? {
      xs: "6px",
      lg: "10px"
    } : "3px",
    right: "3px",
    cursor: "pointer",
    color: "text",
    zIndex: 1,
    onClick: hidePopup
  }, props), ___EmotionJSX(IconX, {
    fill: "currentColor"
  }));
};

var Overlay = function Overlay(_ref2) {
  var variant = _ref2.variant,
      containerProps = _ref2.containerProps,
      children = _ref2.children;
  return ___EmotionJSX(Box, _extends({
    role: "alert",
    position: "relative",
    fontSize: "xs",
    py: "16px",
    pl: "10px",
    pr: "28px",
    borderRadius: "default",
    backgroundColor: "white",
    boxShadow: "dropDown"
  }, ALERT_VARIANT_MAP[variant], containerProps), children);
};

var InlineSmall = function InlineSmall(_ref3) {
  var variant = _ref3.variant,
      containerProps = _ref3.containerProps,
      showAlertIcon = _ref3.showAlertIcon,
      icon = _ref3.icon,
      children = _ref3.children;
  var AlertIcon = ALERT_VARIANT_MAP[variant].icon;
  var alertColor = ALERT_VARIANT_MAP[variant].color;
  return ___EmotionJSX(Flex, {
    role: "alert",
    width: "auto"
  }, showAlertIcon && ___EmotionJSX(Box, {
    color: theme.colors[alertColor]
  }, icon !== null && icon !== void 0 ? icon : ___EmotionJSX(AlertIcon, {
    fill: theme.colors[alertColor],
    height: "14px",
    width: "14px"
  })), ___EmotionJSX(Flex, _extends({
    alignItems: "center",
    fontSize: {
      xs: "2xs",
      sm: "xs"
    },
    mt: "4px",
    ml: "4px"
  }, containerProps), children));
};

export var Alert = function Alert(_ref4) {
  var _ref4$type = _ref4.type,
      type = _ref4$type === void 0 ? "overlay" : _ref4$type,
      _ref4$variant = _ref4.variant,
      variant = _ref4$variant === void 0 ? "default" : _ref4$variant,
      theme = _ref4.theme,
      timeoutDuration = _ref4.timeoutDuration,
      showPointer = _ref4.showPointer,
      showCloseButton = _ref4.showCloseButton,
      _ref4$showAlertIcon = _ref4.showAlertIcon,
      showAlertIcon = _ref4$showAlertIcon === void 0 ? true : _ref4$showAlertIcon,
      alignIcon = _ref4.alignIcon,
      icon = _ref4.icon,
      pointerProps = _ref4.pointerProps,
      wrapperProps = _ref4.wrapperProps,
      children = _ref4.children,
      hideAlert = _ref4.hideAlert,
      containerProps = _objectWithoutProperties(_ref4, _excluded2);

  var _useState = useState(true),
      showPopup = _useState[0],
      setShowPopup = _useState[1];

  var hidePopup = useCallback(function () {
    setShowPopup(false);
    setTimeout(function () {
      hideAlert && hideAlert();
    }, TRANSITION_DURATION);
  }, [hideAlert]);
  useEffect(function () {
    if (timeoutDuration) {
      var timeout = setTimeout(function () {
        hidePopup();
      }, timeoutDuration);
      return function () {
        clearTimeout(timeout);
      };
    }
  }, [hidePopup, timeoutDuration]);
  var ErrorWrapper = type == "small" ? InlineSmall : type == "inline" ? InlineAlert : Overlay;
  return ___EmotionJSX(PopupWrapper, _extends({
    role: "dialog",
    "aria-label": "alert wrapper",
    show: showPopup,
    width: "100%"
  }, wrapperProps), showPointer && ___EmotionJSX(Pointer, _extends({}, pointerProps, {
    "aria-label": "alert pointer"
  })), ___EmotionJSX(ErrorWrapper, {
    variant: variant,
    containerProps: containerProps,
    showAlertIcon: showAlertIcon,
    alignIcon: alignIcon,
    icon: icon,
    theme: theme
  }, children, showCloseButton && ___EmotionJSX(CloseIcon, {
    type: type,
    hidePopup: hidePopup,
    color: theme === "dark" ? "white" : "text"
  })));
};
export default Alert;