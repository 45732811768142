import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext } from "react";
import { AppExperimentContext, emitRecommenderRequest, SOURCE_TYPE_SHOP_PAGE_RECOMMENDER_RECOMMENDED_PRODUCTS } from "@catchoftheday/analytics";
import { NULL_FROM_PRODUCT, SHOP_PAGE_PRODUCT_RECOMMENDER_LIMIT, SHOP_PRODUCTS_RECOMMENDER_MIN_COUNT } from "../../../consts";
import { useOnePassRecommendedProductsQuery } from "../../../generated/graphql";
import { getRecommendedProductsRecommenderTitle } from "../../../utilities";
import { ShopProductsCarouselZone } from "../../layouts/ShopProductsCarouselLayout/ShopProductsCarouselLayout";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopRecommendedProductsContainer = function ShopRecommendedProductsContainer(_ref) {
  var ProductsCarouselComponent = _ref.ProductsCarouselComponent;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["personalised_recommender_title_july_2022"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      experiment = _getExperiments$exper[0],
      titleExperimentLoading = _getExperiments.loading;

  var _useOnePassRecommende = useOnePassRecommendedProductsQuery({
    ssr: false,
    variables: {
      limit: SHOP_PAGE_PRODUCT_RECOMMENDER_LIMIT
    },
    onCompleted: function onCompleted(_ref2) {
      var _ref2$onePassRecommen = _ref2.onePassRecommendedProducts.metaData,
          preHydrationCount = _ref2$onePassRecommen.preHydrationCount,
          postHydrationCount = _ref2$onePassRecommen.postHydrationCount,
          hydrationErrorCount = _ref2$onePassRecommen.hydrationErrorCount;
      emitRecommenderRequest(_objectSpread({
        source_type: SOURCE_TYPE_SHOP_PAGE_RECOMMENDER_RECOMMENDED_PRODUCTS,
        pre_hydration_product_count: preHydrationCount,
        post_hydration_product_count: postHydrationCount,
        hydration_error_count: hydrationErrorCount,
        page_offset: 0,
        page_limit: SHOP_PAGE_PRODUCT_RECOMMENDER_LIMIT
      }, NULL_FROM_PRODUCT));
    }
  }),
      productsData = _useOnePassRecommende.data;

  var _ref3 = productsData || {},
      onePassRecommendedProducts = _ref3.onePassRecommendedProducts;

  var _ref4 = onePassRecommendedProducts || {},
      products = _ref4.products;

  if (!(products !== null && products !== void 0 && products.length) || (products === null || products === void 0 ? void 0 : products.length) < SHOP_PRODUCTS_RECOMMENDER_MIN_COUNT) {
    return null;
  }

  var title = getRecommendedProductsRecommenderTitle({
    experimentBucket: experiment === null || experiment === void 0 ? void 0 : experiment.bucket,
    loading: titleExperimentLoading
  });
  return ___EmotionJSX(ShopProductsCarouselZone, {
    title: title
  }, ___EmotionJSX(ProductsCarouselComponent, {
    products: products,
    sourceType: SOURCE_TYPE_SHOP_PAGE_RECOMMENDER_RECOMMENDED_PRODUCTS
  }));
};