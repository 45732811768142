import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Box, Button } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var Wrapper = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  & {\n    background-color: rgba(255, 255, 255, 0.7);\n    transition: opacity ", ";\n  }\n"])), themeGet("transitions.default"));
export var EventCardCTA = function EventCardCTA(_ref) {
  var visible = _ref.visible,
      isActiveMember = _ref.isActiveMember,
      isSoldOut = _ref.isSoldOut,
      onCTAClick = _ref.onCTAClick;
  return ___EmotionJSX(Wrapper, {
    display: {
      xs: "none",
      md: "block"
    },
    position: "absolute",
    opacity: visible ? 1 : 0,
    left: 0,
    right: 0,
    bottom: 0,
    py: 5,
    px: 7,
    backgroundColor: "rgba(255,255,255,0.7)"
  }, ___EmotionJSX(Button, {
    "data-testid": isSoldOut ? "button-viewItem" : isActiveMember ? "button-addToCart" : "button-joinToBuy",
    block: true,
    outline: isSoldOut ? true : false,
    variant: isSoldOut ? "secondary" : isActiveMember ? "primary" : "onePassPrimary",
    onClick: onCTAClick
  }, isSoldOut ? "View Item" : isActiveMember ? "Add to Cart" : "Join to Buy"));
};