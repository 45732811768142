import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Flex } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ContentZone = function ContentZone(props) {
  return ___EmotionJSX(Flex, _extends({
    "data-testid": "modal-upsellModal",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "onePass",
    color: "onePass.textPrimary",
    pt: {
      xs: 2,
      md: 4
    }
  }, props));
};